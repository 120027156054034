import {deepEqual} from 'fast-equals';

const getArticlesFromShopcart = shopcart => shopcart.rows.filter(row => row.link_friendly_name)
	.map(row => ({
		id: row.art_id,
		linkFriendlyName: row.link_friendly_name
	}));

const fetchMissingArticles = props => {
	const {articleStore, fetchArticle, shopcart} = props;
	const articles = getArticlesFromShopcart(shopcart);
	const uniqueArtIdArticles = Array.from(new Set(articles.map(article => article.id))).map(id => articles.find(article => article.id === id));

	uniqueArtIdArticles.forEach(article => {
		if (!articleStore.articleData[article.id] && !articleStore.pendingRequests.includes(article.linkFriendlyName)) {
			fetchArticle(article.linkFriendlyName, {club_id: props.club_id});
		}
	});
};

const shouldUpdate = ({props}, nextProps) => !deepEqual(props, nextProps);
const beforeMount = ({props}) => !props.disabled && fetchMissingArticles(props);
const afterUpdate = ({props}) => !props.disabled && fetchMissingArticles(props);
const render = () => <noscript/>;

export default {afterUpdate, beforeMount, render, shouldUpdate, name: 'ArticleStore'};
