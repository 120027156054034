const colorListPresentation = (node, type) => node.props.colorListDisplayType === type || node.props.colorListDisplayTypeTablet === type || node.props.colorListDisplayTypeMobile === type;

/**
 * Customer unique change
 * Comment: Add option for showLipscoreRatingWidget
 */
export default node => ({
	type: 'ARTICLES',
	name: 'Articles',
	allowedSections: ['main', 'header'],
	disallowedChildren: ['*', '!BLURB_*', '!MAIN_NAVIGATION_*'],
	category: 'Articles',
	props: [{
		name: 'source',
		inputType: 'select',
		label: 'Source to load articles from',
		default: 'currentCategory',
		values: [{
			label: 'Current category',
			value: 'currentCategory'
		}, {
			label: 'Selected category',
			value: 'selectedCategory'
		}, {
			label: 'Search result',
			value: 'searchResult'
		}, {
			label: 'Favorites',
			value: 'favorites'
		}, {
			label: 'Hello Retail Search',
			value: 'helloRetailSearch'
		}]
	}, node && node.props.source === 'helloRetailSearch' && {
		name: 'helloRetailSearchKey',
		inputType: 'text',
		label: 'Hello Retail Search Key',
		default: ''
	}, node && node.props.source === 'selectedCategory' && {
		name: 'selectedCategoryId',
		inputType: 'link',
		label: 'Category to fetch articles from',
		default: '',
		requestKey: 'categoryId'
	}, node && node.props.source === 'favorites' && {
		name: 'noFavoritesSavedText',
		inputType: 'text',
		label: 'No favorites saved text',
		default: ''
	}, node && (node.props.source !== 'favorites' && node.props.source !== 'currentCategory') && {
		name: 'splitByAttribute1',
		inputType: 'checkbox',
		label: 'Split articles by color',
		default: true
	}, {
		name: 'quickbuy',
		inputType: 'checkbox',
		label: 'Quick buy',
		default: false
	}, node && node.props.quickbuy && {
		name: 'quickbuyButtonText',
		inputType: 'text',
		label: 'Quickbuy button text (Leave empty for default text)',
		default: ''
	}, node && node.props.quickbuy && {
		name: 'quickbuyInPopup',
		inputType: 'select',
		label: 'Show quickbuy popup',
		default: 'multipleVariants',
		values: [{
			label: 'Never',
			value: 'never'
		}, {
			label: 'Always',
			value: 'always'
		}, {
			label: 'If there are more than 1 variant',
			value: 'multipleVariants'
		}]
	}, {
		name: 'showPriceForCurrentSku',
		inputType: 'checkbox',
		label: 'Show price for current sku',
		description: 'When disabled it will display lowest price for variant',
		default: false
	}, node && !node.props.showPriceForCurrentSku && {
		name: 'showFromLabel',
		inputType: 'checkbox',
		label: 'Show from label in price (if possible)',
		description: 'This setting requires showPriceForCurrentSku to be false.'
	}, {
		name: 'favorite',
		inputType: 'checkbox',
		label: 'Toggle favorite',
		default: false
	}, {
		name: 'showLipscoreRatingWidget',
		inputType: 'checkbox',
		label: 'Show Lipscore small rating widget',
		default: true
	}, {
		name: 'noBlurbs',
		inputType: 'checkbox',
		label: 'No blurbs grid',
		default: false
	}, {
		name: 'gutter',
		inputType: 'checkbox',
		label: 'Grid column gap',
		default: false
	}, node && !node.props.showColorList && {
		name: 'showColor',
		inputType: 'checkbox',
		label: 'Show Color',
		description: 'This setting is not compatible with Colorlist.',
		default: false
	}, {
		name: 'showDiscountPercent',
		inputType: 'checkbox',
		label: 'Show price discount percent',
		default: false
	}, {
		name: 'showRecPrice',
		inputType: 'checkbox',
		label: 'Show recommended price',
		default: false
	}, node && node.props.showRecPrice && {
		name: 'recPricePrefixText',
		inputType: 'text',
		label: 'Recommended price label',
		default: 'Rec price'
	}, {
		name: 'showArticleDescription',
		inputType: 'checkbox',
		label: 'Show Article description',
		default: false
	}, {
		name: 'showArticleNumber',
		inputType: 'checkbox',
		label: 'Show Article number',
		default: false
	}, {
		name: 'showPlunoNumber',
		inputType: 'checkbox',
		label: 'Show pluno number',
		description: 'If articlelist is unsplitted, it shows the first variant',
		default: false
	}, {
		name: 'showBrandName',
		inputType: 'checkbox',
		label: 'Show brand name',
		default: false
	}, {
		name: 'showNumberOfVariants',
		inputType: 'checkbox',
		label: 'Show variants quantity',
		description: 'Displays number of variants for each article',
		default: false
	}, node && node.props.showArticleDescription && {
		name: 'stripArticleDescriptionHtml',
		inputType: 'checkbox',
		label: 'Remove HTML from article description',
		default: true
	}, {
		name: 'showArticleProperties',
		inputType: 'checkbox',
		label: 'Show selected article properties',
		default: false
	}, node && node.props.showArticleProperties && {
		name: 'articleProperties',
		inputType: 'text',
		label: 'Article properties to show',
		description: 'Define internal name of properties to show, split each property with ,',
		default: ''
	}, {
		name: 'image',
		inputType: 'select',
		label: 'Image',
		default: '1010',
		values: [{
			label: 'Front',
			value: '1010'
		}, {
			label: 'Back',
			value: '1011'
		}, {
			label: 'List',
			value: '1020'
		}]
	}, {
		name: 'imageOnHover',
		inputType: 'select',
		label: 'Image on hover',
		default: '-1',
		values: [{
			label: 'Front',
			value: '1010'
		}, {
			label: 'Back',
			value: '1011'
		}, {
			label: 'List',
			value: '1020'
		}, {
			label: 'None',
			value: '-1'
		}]
	}].filter(Boolean),
	hiddenProps: [{
		label: 'Filter settings',
		props: [{
			name: 'reducedPriceFilter',
			inputType: 'select',
			label: 'Price filter',
			default: 'all',
			values: [{
				label: 'All',
				value: 'all'
			}, {
				label: 'On Sale',
				value: 'onSale'
			}, {
				label: 'Not on sale',
				value: 'notOnSale'
			}]
		}]
	}, {
		label: 'List settings',
		props: [{
			name: 'size',
			inputType: 'slider',
			label: 'Number of articles to load',
			default: 20,
			min: 1,
			max: 50
		}, {
			name: 'paginationType',
			inputType: 'select',
			label: 'Pagination type',
			default: 'infiniteScroll',
			values: [{
				label: 'Infinite scroll',
				value: 'infiniteScroll'
			}, {
				label: 'Pagination',
				value: 'pagination'
			}, {
				label: 'Load more and load previous button',
				value: 'loadMoreAndPreviousButton'
			}, {
				label: 'None',
				value: 'none'
			}]
		}, {
			name: 'showProgressBar',
			inputType: 'checkbox',
			label: 'Show progress bar (for amount of articles viewed)',
			default: false
		}, node && node.props.paginationType === 'loadMoreAndPreviousButton' && {
			name: 'loadMoreButtonText',
			inputType: 'text',
			label: 'Load more button text',
			default: 'Ladda in fler'
		}, node && node.props.paginationType === 'loadMoreAndPreviousButton' && {
			name: 'loadPreviousButtonText',
			inputType: 'text',
			label: 'Load Previous button text',
			default: 'Ladda in tidigare'
		}].filter(Boolean)
	}, {
		label: 'Columns settings',
		props: [{
			name: 'disableColumnSizePickerChoice',
			inputType: 'checkbox',
			label: 'Don\'t use the column size selected in the component "Column Size"',
			description: 'If enabled this article listing will always use the column size settings below',
			default: false
		}, {
			name: 'xsm',
			inputType: 'slider',
			label: 'Columns mobile (small)',
			default: '2'
		}, {
			name: 'sm',
			inputType: 'slider',
			label: 'Columns mobile',
			default: '2'
		}, {
			name: 'md',
			inputType: 'slider',
			label: 'Columns tablet',
			default: '3'
		}, {
			name: 'lg',
			inputType: 'slider',
			label: 'Columns desktop',
			default: '4'
		}, {
			name: 'xlg',
			inputType: 'slider',
			label: 'Columns desktop (large)',
			default: '5'
		}]
	}, {
		label: 'Badge settings',
		props: [{
			name: 'showSaleOrNew',
			inputType: 'checkbox',
			label: 'Show sale or new icon',
			default: false
		}, node && node.props.showSaleOrNew === true && {
			name: 'onSaleImage',
			inputType: 'checkbox',
			label: 'Show image to indicate on sale item',
			default: false
		}, {
			name: 'showDiscountPercentBadge',
			inputType: 'checkbox',
			label: 'Show badge with discount percent',
			defalt: false
		}, {
			name: 'showPropertyIcon',
			inputType: 'checkbox',
			label: 'Show article icons from property keys',
			default: false
		}, node && node.props.showPropertyIcon === true && {
			name: 'propertyIconName',
			inputType: 'text',
			label: 'Property Key Names',
			description: 'Use the internal property key names. Separate with commas.',
			default: 'article_icon'
		}, {
			name: 'showCampaigns',
			inputType: 'checkbox',
			label: 'Show campaign icon',
			default: true
		}, {
			name: 'campaignPopup',
			inputType: 'checkbox',
			label: 'Popup on campaign click',
			default: false
		}, {
			name: 'campaignHeader',
			inputType: 'text',
			label: 'Campaign popup header',
			default: ''
		}].filter(Boolean)
	}, {
		label: 'Favorite button',
		props: [{
			name: 'favoriteType',
			inputType: 'select',
			label: 'Choose favorite button type',
			default: 'icon',
			values: [{
				label: 'Icon',
				value: 'icon'
			}, {
				label: 'Image',
				value: 'image'
			}]
		}, node && node.props.favoriteType === 'icon' && {
			name: 'favoriteIcon',
			inputType: 'icon',
			label: 'Choose favorite icon',
			default: '{"prefix":"fal","x":{"name":"heart"}}'
		}, node && node.props.favoriteType === 'icon' && {
			name: 'favoriteIconSelected',
			inputType: 'icon',
			label: 'Choose favorite icon for selected',
			default: '{"prefix":"fas","x":{"name":"heart"}}'
		}, node && node.props.favoriteType === 'icon' && {
			name: 'favoriteIconSize',
			inputType: 'text',
			label: 'Set size on favorite icon',
			default: '1em'
		}, node && node.props.favoriteType === 'icon' && {
			name: 'favoriteIconColor',
			inputType: 'color',
			label: 'Set color on favorite icon',
			default: '#000'
		}, node && node.props.favoriteType === 'image' && {
			name: 'favoriteImage',
			inputType: 'media-v2',
			label: 'Choose favorite image',
			default: ''
		}, node && node.props.favoriteType === 'image' && {
			name: 'favoriteImageSelected',
			inputType: 'media-v2',
			label: 'Choose favorite image when selected',
			default: ''
		}].filter(Boolean)
	}, {
		label: 'Search',
		props: [{
			name: 'noSearchResultTitle',
			inputType: 'text',
			label: 'No search result title',
			default: ''
		}, {
			name: 'noSearchResultText',
			inputType: 'text',
			label: 'No search result text',
			default: ''
		}, {
			name: 'showSearchResultHeading',
			inputType: 'checkbox',
			label: 'Show search result heading',
			default: ''
		}, node && node.props.showSearchResultHeading && {
			name: 'searchResultHeadingPrefix',
			inputType: 'text',
			label: 'Search result heading prefix',
			default: 'Results for: '
		}, node && node.props.showSearchResultHeading && {
			name: 'searchResultHeadingSuffix',
			inputType: 'text',
			label: 'Search result heading suffix',
			default: ''
		}, node && node.props.showSearchResultHeading && {
			name: 'searchResultHeadingElement',
			inputType: 'select',
			label: 'Search result heading element type',
			default: 'h1',
			values: ['h1', 'h2', 'h3', 'h4', 'h5']
		}].filter(Boolean)
	}, {
		label: 'Colorlist',
		props: [{
			name: 'showColorList',
			inputType: 'checkbox',
			label: 'Show Colorlist (attribute1)',
			description: 'Show all colors available in either a slider or select. Not compatible with Favorites and showPriceForCurrentSku setting. Requires you to refresh page.',
			default: false
		}, {
			name: 'colorListShowFromPrice',
			inputType: 'checkbox',
			label: 'Show lowest available price regardless of chosen colour',
			description: 'Will show a "from" label before the price if there is different prices per SKU.',
			default: false
		}, {
			name: 'colorListOnlyShowInStock',
			inputType: 'checkbox',
			label: 'Only show colors that are in stock',
			default: true
		}, {
			name: 'colorListChangeColorOnHover',
			inputType: 'checkbox',
			label: 'Change color on hover',
			default: false
		}, {
			name: 'colorListDisplayType',
			inputType: 'select',
			label: 'Display Colorlist as (Desktop)',
			default: '',
			values: [{
				label: 'Images Slider',
				value: 'imagesSlider'
			}, {
				label: 'Color Slider',
				value: 'colorSlider'
			}, {
				label: 'Image and Color Slider',
				value: 'imageAndColorSlider'
			}, {
				label: 'Select',
				value: 'select'
			}, {
				label: 'Hidden',
				value: 'hidden'
			}]
		}, {
			name: 'colorListDisplayTypeTablet',
			inputType: 'select',
			label: 'Display Colorlist as (Tablet)',
			default: '',
			values: [{
				label: 'Images Slider',
				value: 'imagesSlider'
			}, {
				label: 'Color Slider',
				value: 'colorSlider'
			}, {
				label: 'Image and Color Slider',
				value: 'imageAndColorSlider'
			}, {
				label: 'Select',
				value: 'select'
			}, {
				label: 'Hidden',
				value: 'hidden'
			}]
		}, {
			name: 'colorListDisplayTypeMobile',
			inputType: 'select',
			label: 'Display Colorlist as (Mobile)',
			default: '',
			values: [{
				label: 'Images Slider',
				value: 'imagesSlider'
			}, {
				label: 'Color Slider',
				value: 'colorSlider'
			}, {
				label: 'Image and Color Slider',
				value: 'imageAndColorSlider'
			}, {
				label: 'Select',
				value: 'select'
			}, {
				label: 'Hidden',
				value: 'hidden'
			}]
		}, node && colorListPresentation(node, 'select') && {
			name: 'colorListSelectPlaceholder',
			inputType: 'text',
			label: 'Select placeholder',
			default: 'Color'
		}, node && (colorListPresentation(node, 'colorSlider') || colorListPresentation(node, 'imagesSlider') || colorListPresentation(node, 'imageAndColorSlider')) && {
			name: 'colorListSliderSpeed',
			inputType: 'text',
			label: 'Slider Animation speed',
			default: '500'
		}, node && (colorListPresentation(node, 'colorSlider') || colorListPresentation(node, 'imagesSlider') || colorListPresentation(node, 'imageAndColorSlider')) && {
			name: 'colorListDuration',
			inputType: 'text',
			label: 'Slider Animation duration',
			default: '3000'
		}, node && (colorListPresentation(node, 'colorSlider') || colorListPresentation(node, 'imagesSlider') || colorListPresentation(node, 'imageAndColorSlider')) && {
			'name': 'colorListSlidesPerView',
			'inputType': 'select',
			'label': 'Slides per view (Desktop)',
			'default': '5',
			'values': ['1', '2', '3', '4', '5', '6', '7', '8']
		}, node && (colorListPresentation(node, 'colorSlider') || colorListPresentation(node, 'imagesSlider') || colorListPresentation(node, 'imageAndColorSlider')) && {
			'name': 'colorListSlidesPerViewTablet',
			'inputType': 'select',
			'label': 'Slides per view (Tablet)',
			'default': '1',
			'values': ['1', '2', '3', '4']
		}, node && (colorListPresentation(node, 'colorSlider') || colorListPresentation(node, 'imagesSlider') || colorListPresentation(node, 'imageAndColorSlider')) && {
			'name': 'colorListSlidesPerViewMobile',
			'inputType': 'select',
			'label': 'Slides per view (Mobile)',
			'default': '1',
			'values': ['1', '2', '3', '4']
		}, node && (colorListPresentation(node, 'colorSlider') || colorListPresentation(node, 'imagesSlider') || colorListPresentation(node, 'imageAndColorSlider')) && {
			'name': 'colorListSpaceBetween',
			'inputType': 'select',
			'label': 'Space between',
			'default': '0',
			'values': ['0', '10', '20', '30', '40']
		}, node && (colorListPresentation(node, 'colorSlider') || colorListPresentation(node, 'imagesSlider') || colorListPresentation(node, 'imageAndColorSlider')) && {
			'name': 'colorListCenterSlides',
			'inputType': 'checkbox',
			'label': 'Center slides',
			'default': false
		}, node && (colorListPresentation(node, 'colorSlider') || colorListPresentation(node, 'imagesSlider') || colorListPresentation(node, 'imageAndColorSlider')) && {
			name: 'colorListSliderNextIcon',
			inputType: 'icon',
			label: 'Choose icon for next arrow',
			default: '{"prefix":"fal","x":{"name":"chevron-right"}}'
		}, node && (colorListPresentation(node, 'colorSlider') || colorListPresentation(node, 'imagesSlider') || colorListPresentation(node, 'imageAndColorSlider')) && {
			name: 'colorListSliderNextIconSize',
			inputType: 'text',
			label: 'Set size on next arrow icon',
			default: '1em'
		}, node && (colorListPresentation(node, 'colorSlider') || colorListPresentation(node, 'imagesSlider') || colorListPresentation(node, 'imageAndColorSlider')) && {
			name: 'colorListSliderNextIconColor',
			inputType: 'color',
			label: 'Set color on next arrow icon',
			default: '#000'
		}, node && (colorListPresentation(node, 'colorSlider') || colorListPresentation(node, 'imagesSlider') || colorListPresentation(node, 'imageAndColorSlider')) && {
			name: 'colorListSliderPrevIcon',
			inputType: 'icon',
			label: 'Choose icon for previous arrow',
			default: '{"prefix":"fal","x":{"name":"chevron-left"}}'
		}, node && (colorListPresentation(node, 'colorSlider') || colorListPresentation(node, 'imagesSlider') || colorListPresentation(node, 'imageAndColorSlider')) && {
			name: 'colorListSliderPrevIconSize',
			inputType: 'text',
			label: 'Set size on previous arrow icon',
			default: '1em'
		}, node && (colorListPresentation(node, 'colorSlider') || colorListPresentation(node, 'imagesSlider') || colorListPresentation(node, 'imageAndColorSlider')) && {
			name: 'colorListSliderPrevIconColor',
			inputType: 'color',
			label: 'Set color on previous arrow icon',
			default: '#000'
		}].filter(Boolean)
	}, {
		label: 'Sizelist',
		props: [{
			name: 'showSizeList',
			inputType: 'checkbox',
			label: 'Show Sizelist (attribute2)',
			description: 'Show all sizes available under an article. Not compatible with Favorites. Requires you to refresh page.',
			default: false
		}, {
			name: 'sizeListMaxNumberOfSizes',
			inputType: 'number',
			label: 'How many sizes to display (if available)',
			default: 5
		}, {
			name: 'sizeListOnlyShowInStock',
			inputType: 'checkbox',
			label: 'Only show sizes that are in stock',
			default: true
		}, {
			name: 'showSizeRange',
			inputType: 'checkbox',
			label: 'Show size range',
			default: false
		}, {
			name: 'regexToRemoveFromSize',
			inputType: 'text',
			label: 'Regex pattern which will be removed from size',
			default: ''
		}]
	}, {
		label: 'Big articles settings',
		props: [{
			name: 'renderBigArticlesLeftToRight',
			inputType: 'checkbox',
			description: 'First row big article to the left, second to the right, third to the left and so on. Disabling this will enable custom settings below. (Only applies for desktop and tablet).',
			label: 'Render big articles left to right',
			default: true
		}, {
			name: 'showBigArticles',
			inputType: 'checkbox',
			label: 'Enable big articles layout for desktop',
			description: 'Overrides other column settings. No Blurbs grid setting has to be disabled. Wont work well with Blurbs.',
			default: false
		}, node && node.props.showBigArticles && {
			name: 'scaleBigArticleImage',
			inputType: 'select',
			label: 'Big article image scale for desktop',
			description: 'Select the smallest scale possible to increase page speed',
			default: 'normal',
			values: [{
				label: 'Original',
				value: 'original'
			}, {
				label: 'Large',
				value: 'large'
			}, {
				label: 'Normal',
				value: 'normal'
			}, {
				label: 'List',
				value: 'list'
			}, {
				label: 'Thumbnail',
				value: 'thumbnail'
			}]
		}, node && node.props.showBigArticles && {
			name: 'columnLayoutBigArticles',
			inputType: 'select',
			description: 'If big articles is enabled it will override any other column settings',
			label: 'Columns with big article for desktop',
			default: '3',
			values: [{
				label: '3',
				value: '3'
			}, {
				label: '4',
				value: '4'
			}, {
				label: '5',
				value: '5'
			}]
		}, node && !node.props.renderBigArticlesLeftToRight && node.props.showBigArticles && {
			name: 'indexBigArticle',
			inputType: 'number',
			description: 'If 3 columns, 0-2. If 4 columns, 0-3. If 5 columns, 0-4.',
			label: 'Index (starts at 0) of article that will be big for desktop',
			default: '0'
		}, node && !node.props.renderBigArticlesLeftToRight && node.props.showBigArticles && {
			name: 'indexFillerBigArticle',
			inputType: 'number',
			description: 'If 3 columns, 2-4. If 4 columns, 3-5. If 5 columns, 4-6.',
			label: 'Index (starts at 0) of filler for desktop',
			default: '3'
		}, {
			name: 'showBigArticlesTablet',
			inputType: 'checkbox',
			label: 'Enable big articles layout for tablet',
			description: 'Overrides other column settings. No Blurbs grid setting has to be disabled. Wont work well with Blurbs',
			default: false
		}, node && node.props.showBigArticlesTablet && {
			name: 'scaleBigArticleImageTablet',
			inputType: 'select',
			label: 'Big article image scale for tablet',
			description: 'Select the smallest scale possible to increase page speed',
			default: 'normal',
			values: [{
				label: 'Original',
				value: 'original'
			}, {
				label: 'Large',
				value: 'large'
			}, {
				label: 'Normal',
				value: 'normal'
			}, {
				label: 'List',
				value: 'list'
			}, {
				label: 'Thumbnail',
				value: 'thumbnail'
			}]
		}, node && node.props.showBigArticlesTablet && {
			name: 'columnLayoutBigArticlesTablet',
			inputType: 'select',
			description: 'If big articles is enabled it will override any other column settings',
			label: 'Columns with big article for tablet',
			default: '3',
			values: [{
				label: '3',
				value: '3'
			}, {
				label: '4',
				value: '4'
			}, {
				label: '5',
				value: '5'
			}]
		}, node && !node.props.renderBigArticlesLeftToRight && node.props.showBigArticlesTablet && {
			name: 'indexBigArticleTablet',
			inputType: 'number',
			description: 'If 3 columns, 2-4. If 4 columns, 3-5. If 5 columns, 4-6.',
			label: 'Index (starts at 0) of article that will be big for tablet',
			default: '0'
		}, node && !node.props.renderBigArticlesLeftToRight && node.props.showBigArticlesTablet && {
			name: 'indexFillerBigArticleTablet',
			inputType: 'number',
			description: 'If 3 columns, 2-4. If 4 columns, 3-5. If 5 columns, 4-6.',
			label: 'Index (starts at 0) of filler for tablet',
			default: '3'
		}, {
			name: 'showBigArticlesMobile',
			inputType: 'checkbox',
			label: 'Enable big articles layout for mobile. ',
			description: 'Only works with 2 columns. No Blurbs grid setting has to be disabled.',
			default: false
		}, node && node.props.showBigArticlesMobile && {
			name: 'scaleBigArticleImageMobile',
			inputType: 'select',
			label: 'Big article image scale for mobile',
			description: 'Select the smallest scale possible to increase page speed',
			default: 'normal',
			values: [{
				label: 'Original',
				value: 'original'
			}, {
				label: 'Large',
				value: 'large'
			}, {
				label: 'Normal',
				value: 'normal'
			}, {
				label: 'List',
				value: 'list'
			}, {
				label: 'Thumbnail',
				value: 'thumbnail'
			}]
		}, node && node.props.showBigArticlesMobile && {
			name: 'rowIndexBigArticleMobile',
			inputType: 'select',
			label: 'Select row which will have big article in mobile',
			default: '3',
			values: [{
				label: 'Every second row',
				value: '2'
			}, {
				label: 'Every third row',
				value: '3'
			}, {
				label: 'Every fourth row',
				value: '4'
			}]
		}].filter(Boolean)
	}]
});
