export default {
	type: 'ARTICLE_QUANTITY',
	name: 'Quantity',
	allowedSections: ['article'],
	disallowedChildren: ['*'],
	category: 'Article',
	props: [{
		name: 'label',
		inputType: 'text',
		label: 'Label',
		default: 'Quantity'
	}, {
		name: 'layout',
		inputType: 'select',
		label: 'Select layout',
		default: 'input',
		values: [{
			label: 'Input field',
			value: 'input'
		}, {
			label: 'Dropdown',
			value: 'dropdown'
		}, {
			label: 'Plus/Minus buttons',
			value: 'plusMinusButtons'
		}]
	}],
	hiddenProps: [{
		label: 'Advanced settings',
		props: [{
			name: 'class',
			inputType: 'text',
			label: 'CSS-class',
			default: ''
		}]
	}]
};
