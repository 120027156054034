import {deepEqual} from 'fast-equals';
import {restoreScroll, saveScrollMemoryData} from '../actions';

const beforeMount = () => {
	let timeout = false;

	window.addEventListener('scroll', () => {
		if (timeout) {
			clearTimeout(timeout);
		}

		timeout = setTimeout(() => saveScrollMemoryData('scrollPosition', window.scrollY || window.pageYOffset), 150);
	}, false);
};

const shouldUpdate = ({props}, nextProps) => !deepEqual(props, nextProps);
const afterUpdate = ({props}, prevProps) => {
	if (props.router.pathname === prevProps.router.pathname && (props.router.hash !== prevProps.router.hash || props.router.querystring !== prevProps.router.querystring)) {
		return;
	}

	restoreScroll();
};
const render = () => <noscript/>;

export default {afterUpdate, beforeMount, render, shouldUpdate};
