import {
	FINDSTORE_STORES_FETCH_FULFILLED,
	FINDSTORE_SET_LOCATION,
	FINDSTORE_SET_STORES
} from '../../action-types';

const initialState = {
	activeCountry: '',
	activeStore: {},
	closestStores: [],
	location: {
		latitude: 62.812159,
		longitude: 17.773483
	},
	stores: [],
	zoomCountry: 0
};

export default (state = initialState, action) => {
	switch (action.type) {
		case FINDSTORE_STORES_FETCH_FULFILLED: {
			return {
				...state,
				activeCountry: action.payload.activeCountry,
				location: Object.keys(action.payload.activeStore).length !== 0 ? action.payload.activeStore.address : initialState.location,
				stores: action.payload.stores,
				zoomCountry: 4
			};
		}
		case FINDSTORE_SET_LOCATION: {
			return {
				...state,
				location: action.payload.location,
				zoomCountry: 0
			};
		}
		case FINDSTORE_SET_STORES: {
			return {
				...state,
				activeStore: action.payload.activeStore,
				closestStores: action.payload.closestStores
			};
		}
		default: {
			return state;
		}
	}
};
