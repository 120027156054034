export default {
	type: 'ARTICLE_CAMPAIGNS',
	name: 'Campaigns',
	allowedSections: ['article'],
	disallowedChildren: ['*'],
	category: 'Article',
	icon: 'fal fa-award',
	props: [{
		name: 'heading',
		inputType: 'text',
		label: 'Heading',
		default: ''
	}, {
		name: 'campNameHeader',
		inputType: 'checkbox',
		label: 'Show campaign name',
		default: false
	}],
	hiddenProps: [{
		label: 'Advanced settings',
		props: [{
			name: 'class',
			inputType: 'text',
			label: 'CSS-class',
			default: ''
		}]
	}]
};
