import {render, renderString, tree} from '@viskan/deku';
import {storePlugin} from '@viskan/deku-redux';
import {applyMiddleware} from 'redux';
import {createInjectStore, injectReducer} from 'redux-injector';
import reduxPromiseMiddleware from 'redux-promise-middleware';
import reduxThunk from 'redux-thunk';
import {saveState} from '~/utils';
import store from 'store2';
import debounce from 'debounce';
import actions from './actions';
import analytics, {setOrigin} from './analytics';
import App from './app';
import {composeEnhancers, dekuErrorHandler, serverErrorLogger} from './log';
import components from './sources/components';
import customer from './sources/customer';
import reducer from './reducer';
import router from './router';
import sessionCheck from './session-check';
import shopcart from './sources/shopcart';
import sources from './sources';
// eslint-disable-next-line import/no-unassigned-import
import './polyfills';

setOrigin();
sessionCheck();

const defaultDebugOptions = {
	diff: false,
	diffDepth: 5,
	log: true,
	disableChromeCmsWarning: false
};

window._debug = options => {
	if (!options) {
		return store('vs-debug', false);
	}

	store('vs-debug', options === true ? defaultDebugOptions : {...defaultDebugOptions, ...options});
};

const preloadedState = window.__PRELOADED_STREAMLINE_STATE__;
const debugSettings = store('vs-debug');
const isDebugging = Boolean(debugSettings);

if (!isDebugging) {
	delete window.__PRELOADED_STREAMLINE_STATE__;
}

const middlewares = [reduxThunk, reduxPromiseMiddleware()].filter(Boolean);
const reduxStore = createInjectStore(reducer, preloadedState, composeEnhancers(applyMiddleware(...middlewares)));

reduxStore.subscribe(debounce(() => {
	const store = reduxStore.getState();

	saveState({
		articleHistory: store.article.history,
		artNosAndAttr1Ids: store.articles.favorites.artNosAndAttr1Ids,
		numberOfSavedFavorites: store.articles.favorites.numberOfSavedFavorites,
		saved: store.articles.favorites.saved
	});
}, 1000));

if (window._streamline.siteContext.cmsTokenSet) {
	window._injectReducer = injectReducer;
	window._reduxStore = reduxStore;
}

if (!isDebugging && process.env.NODE_ENV === 'production') {
	window.onerror = () => true;
	window.addEventListener('error', serverErrorLogger);
}

const app = tree(<App/>)
	.use(storePlugin(reduxStore))
	.use(actions)
	.use(customer)
	.use(sources)
	.use(shopcart)
	.use(analytics)
	.use(router)
	.use(components);

const instance = render(app, document.querySelector('#Streamline'), {
	errorHandler: isDebugging ? undefined : dekuErrorHandler
});

window._renderHtmlString = () => renderString(instance);
