import mergeOptions from 'merge-options';
import {getQueryString, persistedState} from '~/utils';
import {
	ARTICLE_ACTIVE_SUBSCRIPTION,
	ARTICLE_ADDON_ADD,
	ARTICLE_ADDON_REMOVE,
	ARTICLE_ADDON_SELECT_REMOVE,
	ARTICLE_FETCH_FULFILLED,
	ARTICLE_FETCH_PENDING,
	ARTICLE_FETCH_REJECTED,
	ARTICLE_QUANTITY_SET,
	ARTICLE_RESET,
	ARTICLE_SKU_SET
} from '../action-types';
import {trackArticle} from '../analytics';

const initialState = {
	active: {
		articlePrice: {},
		inStock: false
	},
	activeSubscription: {},
	activeSubscriptionRelatedArticle: null,
	addons: [],
	article: {
		art_desc: '',
		art_desc2: '',
		articleAttributes: [],
		articleCampaigns: [],
		articleCategories: [],
		prentypes: [],
		propertyKeys: [],
		relatedArticles: []
	},
	isLoaded: false,
	isSinglePurchase: true,
	history: persistedState.articleHistory || [],
	notFound: false,
	prentypeId: 0,
	quantity: 1
};

const handleHistory = (currentHistory, {art_no}, {attr1_id, attr2_id, attr3_id}) => {
	const ret = [{art_no, attr1_id, attr2_id, attr3_id}, ...currentHistory.filter(x => x.art_no !== art_no)].filter((x, i) => i <= 49);

	return ret;
};

const getActive = (article, sku) => {
	if (sku) {
		const active = v12.article.sku(article, sku, {getFirstInStock: true});

		if (active) {
			return active;
		}
	}

	return article.articleAttributes.find(attribute => attribute.inStock) || article.articleAttributes[0];
};

export default (state = initialState, action) => {
	if (!state.hydrated) {
		try {
			const query = getQueryString(window.location.search);
			const active = {
				...initialState.active,
				...getActive(state.article, query)
			};

			const article = {
				...initialState.article,
				...state.article
			};

			if (article.art_id) {
				trackArticle({body: article, sku: active});
			}

			state = mergeOptions({}, initialState, {
				active,
				article,
				hydrated: true,
				isLoaded: true
			});
		} catch (error) {
			state.hydrated = true;
			v12.util.error(error);
		}
	}

	switch (action.type) {
		case ARTICLE_ACTIVE_SUBSCRIPTION: {
			return {
				...state,
				activeSubscription: action.payload.subscription,
				activeSubscriptionRelatedArticle: action.payload.activeArticle || null,
				isSinglePurchase: action.payload.isSinglePurchase
			};
		}
		case ARTICLE_ADDON_ADD: {
			const newAddons = state.addons.slice();
			const index = newAddons.findIndex(addon =>
				addon.art_id === action.payload.art_id &&
				addon.attr1_id === action.payload.attr1_id &&
				addon.attr2_id === action.payload.attr2_id &&
				addon.attr3_id === action.payload.attr3_id
			);

			if (index !== -1) {
				newAddons.splice(index, 1);
			}

			newAddons.push(action.payload);

			return {
				...state,
				addons: newAddons
			};
		}
		case ARTICLE_ADDON_REMOVE: {
			const newAddons = state.addons.slice();
			const index = newAddons.findIndex(addon =>
				addon.art_id === action.payload.art_id &&
				addon.attr1_id === action.payload.attr1_id &&
				addon.attr2_id === action.payload.attr2_id &&
				addon.attr3_id === action.payload.attr3_id
			);

			if (index !== -1) {
				newAddons.splice(index, 1);
			}

			return {
				...state,
				addons: newAddons
			};
		}
		case ARTICLE_ADDON_SELECT_REMOVE: {
			return {
				...state,
				addons: state.addons.filter(addon => !addon.isAddonSelect)
			};
		}
		case ARTICLE_FETCH_PENDING: {
			return {
				...state,
				loading: true
			};
		}
		case ARTICLE_FETCH_FULFILLED: {
			const article = action.payload;
			const active = getActive(article, action.meta.sku);

			trackArticle({body: article, sku: active});

			return {
				...initialState,
				active,
				article: action.payload,
				hydrated: state.hydrated,
				isLoaded: Boolean(action.payload.art_id),
				notFound: false,
				history: handleHistory(state.history, article, active)
			};
		}
		case ARTICLE_FETCH_REJECTED: {
			return {
				...state,
				isLoaded: false,
				notFound: true
			};
		}
		case ARTICLE_QUANTITY_SET: {
			return {
				...state,
				quantity: action.payload
			};
		}
		case ARTICLE_RESET: {
			return {
				...initialState,
				hydrated: state.hydrated,
				history: state.history
			};
		}
		case ARTICLE_SKU_SET: {
			trackArticle({body: state.article, sku: action.payload});

			return {
				...state,
				active: action.payload,
				history: handleHistory(state.history, state.article, action.payload)
			};
		}
		default: {
			return state;
		}
	}
};
