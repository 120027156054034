import queryString from 'query-string';
import store from 'store2';
import {isPerson} from '../actions';

export const omit = (o, ...keys) => keys.reduce((memo, key) => {
	const {[key]: ignored, ...rest} = memo;
	return rest;
}, o);

export const valueFilter = filter => object => Object.keys(object).reduce((passed, key) => !filter(object[key]) ? passed : {
	...passed,
	[key]: object[key]
}, {});

export const isDefined = x => x !== undefined;
export const mergeDefined = (...objects) => Object.assign({}, ...objects.map(valueFilter(isDefined)));
export const isEmpty = obj => Object.entries(obj).length === 0 && obj.constructor === Object;
export const isVideo = src => (src && src.length > 0) && src.includes('.mp4');
export const isMediaTypeVideo = (mediaType = '') => mediaType === 'VIDEO/VIMEO' || mediaType === 'VIDEO/YOUTUBE';

export const getMediaTypeVideoUrl = (src, mediaType) => {
	const mediaTypeMap = {
		'VIDEO/VIMEO': 'https://player.vimeo.com/video/',
		'VIDEO/YOUTUBE': 'https://www.youtube.com/watch?v='
	};

	if (mediaTypeMap[mediaType]) {
		return `${mediaTypeMap[mediaType]}${src}`;
	}

	return src;
};

export const sanitizeAttr1IdKeyValue = article => {
	if ('attr1_id' in article && article.attr1_id !== -1) {
		return article.attr1_id;
	}

	if ('_attr1_id' in article && article._attr1_id !== -1) {
		return article._attr1_id;
	}

	if ('attribute1Id' in article && article.attribute1Id !== -1) {
		return article.attribute1Id;
	}

	if ('attr1_id_display' in article && article.attr1_id_display !== -1) {
		return article.attr1_id_display;
	}

	return null;
};

export const getSkuBasedOnAttribute1Id = article => {
	let result;

	if ('attr1_id' in article && article.attr1_id !== -1) {
		result = article.articleAttributes.find(sku => sku.attr1_id === article.attr1_id);
	}

	if (!result && '_attr1_id' in article && article._attr1_id !== -1) {
		result = article.articleAttributes.find(sku => sku.attr1_id === article._attr1_id);
	}

	if (!result && 'attribute1Id' in article && article.attribute1Id !== -1) {
		result = article.articleAttributes.find(sku => sku.attr1_id === article.attribute1Id);
	}

	if (!result && 'attr1_id_display' in article && article.attr1_id_display !== -1) {
		result = article.articleAttributes.find(sku => sku.attr1_id === article.attr1_id_display);
	}

	if (!result) {
		return article.articleAttributes[0];
	}

	return result;
};

const persistedStateIdentifier = window._streamline && window._streamline.siteContext.companyName.length > 0 ? `${window._streamline.siteContext.companyName.replace(/\s/g, '-').toLowerCase()}-state` : 'vs-state';

export const saveState = state => {
	try {
		const serializedState = JSON.stringify(state);
		localStorage.setItem(persistedStateIdentifier, serializedState);
	} catch (error) {
		v12.util.error(error);
		console.error(`Could not persist state`, error);
	}
};

export const logoutIfLoggedIn = customer => {
	if (customer.logged_in_level > 0) {
		return v12.customer.logout({copyCart: true});
	}

	return new Promise(resolve => resolve());
};

const getPersistedState = () => store(persistedStateIdentifier) || [];

export const persistedState = getPersistedState();
export const getQueryString = query => queryString.parseUrl(query).query;
export const parseNumber = (num, defaultValue) => Number(num) ? parseInt(num, 0) : (num === 0 || num === '0' ? 0 : defaultValue);

export const getPriceDiscountInPercent = prices => {
	const person = isPerson();
	const salesPrice = person ? prices.sales : prices.salesExvat;
	const orgPrice = person ? prices.org : prices.orgExvat;

	return parseInt(Math.round(100 * (orgPrice - salesPrice) / orgPrice), 10);
};
