import {connect} from '@viskan/deku-redux';
import * as customer from '../customer/actions';
import * as shopcart from '../shopcart/actions';
import {recieve as recieveSite} from '../site/actions';
import App from './components';

export default connect(
	({app, breakpoint, customer, cms, cmsComponents, router, shopcart, site, streamlineCms, popup}) => ({
		articleStoreEnabled: site.settings.articleStoreEnabled,
		slideoutsAndPopups: {...cmsComponents.slideout, ...cmsComponents.popup, ...popup},
		breakpointOverride: breakpoint.breakpointOverride,
		cssNamespace: app.cssNamespace,
		customer,
		isLandingPage: router.pathname === site.localePickerPath,
		isRedirectPage: router.pathname && router.pathname.includes('language-redirect'),
		isEditing: streamlineCms && streamlineCms.loaded && cms && cms.section.active && !cms.base.hidden,
		activeCmsSection: streamlineCms && streamlineCms.loaded && cms && cms.section.active,
		mainSection: app.category.main,
		router,
		shopcart,
		site
	}),
	dispatch => ({
		customerPatch: data => dispatch(customer.patch(data)),
		fetchShopcart: () => dispatch(shopcart.fetch(true)),
		recieveSite: data => dispatch(recieveSite(data))
	})
)(App);
