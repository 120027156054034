import Button from '@viskan/deku-button';
import debounce from 'debounce';
import page from 'page';
import ArticleData from '../../article';
import {type} from '@viskan/streamline-utilities';
import {QUICKBUY_ALWAYS, QUICKBUY_MULTIPLEVARIANTS} from '~/utils/constants';

const name = 'QuickBuy';

const addOrPromptSku = ({props}) => {
	const {addToCart, data, isArticle, openPopup, pathPrefix, url, quickbuyInPopup} = props;

	if (isArticle && data.articleAttributes.length > 1) {
		page(pathPrefix + url);
		return;
	}

	// (type.isBoolean(quickbuyInPopup) && quickbuyInPopup) is for backwards-compability because quickbuyInPopup was a boolean
	if (!isArticle && ((type.isBoolean(quickbuyInPopup) && quickbuyInPopup) || quickbuyInPopup === QUICKBUY_ALWAYS || (quickbuyInPopup === QUICKBUY_MULTIPLEVARIANTS && data.articleAttributes.length > 1))) {
		openPopup(<ArticleData popup query={data.link_friendly_name} sku={{attr1_id: data.attr1_id}}/>);
		return;
	}

	const sku = props.sku ? props.sku : data.articleAttributes[0];
	debounce(() => addToCart(data, sku), 1000, true)();
};

const render = ({props}) => {
	const {data, quickbuyButtonText, styleClasses = {}} = props;
	const onClick = () => addOrPromptSku({props});

	return <Button class={[name, styleClasses.QuickBuy]} disabled={!data.inStock} onClick={onClick} name={name}>{quickbuyButtonText}</Button>;
};

export default {name, render};
