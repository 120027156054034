export default {
	type: 'MAIN_NAVIGATION_01',
	name: 'Main',
	allowedSections: ['header', 'main'],
	disallowedChildren: [],
	category: 'Nav',
	requiredProps: [{
		name: 'categoryLinkId',
		inputType: 'link',
		label: 'Select page to drop in',
		default: '',
		requestKey: 'categoryId'
	}],
	props: [{
		name: 'categoryId',
		inputType: 'link',
		label: 'Select a category',
		default: 'articles/',
		requestKey: 'linkFriendlyName'
	}, {
		name: 'dropdown',
		inputType: 'checkbox',
		label: 'Show dropdown',
		default: true
	}, {
		name: 'center',
		inputType: 'checkbox',
		label: 'Center the menu',
		default: true
	}, {
		name: 'depth',
		inputType: 'slider',
		label: 'Number of category children',
		default: 3
	}, {
		name: 'showParentCategory',
		inputType: 'checkbox',
		label: 'Show parent category',
		default: false
	}, {
		name: 'mainSectionWidth',
		inputType: 'select',
		label: 'Main Section column width (Only applicable when dropped child)',
		default: '6of12',
		values: [{
			label: '1of12',
			value: '1of12'
		}, {
			label: '2of12',
			value: '2of12'
		}, {
			label: '3of12',
			value: '3of13'
		}, {
			label: '4of12',
			value: '4of12'
		}, {
			label: '5of12',
			value: '5of12'
		}, {
			label: '6of12',
			value: '6of12'
		}, {
			label: '7of12',
			value: '7of12'
		}, {
			label: '8of12',
			value: '8of12'
		}, {
			label: '9of12',
			value: '9of12'
		}, {
			label: '10of12',
			value: '10of12'
		}, {
			label: '11of12',
			value: '11of12'
		}, {
			label: 'full',
			value: 'full'
		}]
	}, {
		name: 'dynamicSectionWidth',
		inputType: 'select',
		label: 'Dynamic Section column width (Only applicable when dropped child)',
		default: '6of12',
		values: [{
			label: '1of12',
			value: '1of12'
		}, {
			label: '2of12',
			value: '2of12'
		}, {
			label: '3of12',
			value: '3of13'
		}, {
			label: '4of12',
			value: '4of12'
		}, {
			label: '5of12',
			value: '5of12'
		}, {
			label: '6of12',
			value: '6of12'
		}, {
			label: '7of12',
			value: '7of12'
		}, {
			label: '8of12',
			value: '8of12'
		}, {
			label: '9of12',
			value: '9of12'
		}, {
			label: '10of12',
			value: '10of12'
		}, {
			label: '11of12',
			value: '11of12'
		}, {
			label: 'full',
			value: 'full'
		}]
	}],
	hiddenProps: [{
		label: 'Top nodes styling',
		props: [{
			name: 'topNodeFontSize',
			inputType: 'text',
			label: 'Font size',
			default: '14px'
		}, {
			name: 'topNodeFontColor',
			inputType: 'color',
			label: 'Text color',
			default: '#000000'
		}, {
			name: 'topNodeFontStyle',
			inputType: 'select',
			label: 'Font style',
			default: 'normal',
			values: ['normal', 'italic', 'oblique']
		}, {
			name: 'topNodeFontWeight',
			inputType: 'select',
			label: 'Font weight',
			default: 'normal',
			values: ['normal', 'bold', 'bolder', 'light', 'lighter']
		}, {
			name: 'topNodeTextTransform',
			inputType: 'select',
			label: 'Text transform',
			default: 'none',
			values: ['none', 'uppercase', 'lowercase', 'capitalize']
		}, {
			name: 'topNodeMarginHorizontal',
			inputType: 'select',
			label: 'Horizontal margin',
			default: '5px',
			values: ['0px', '5px', '10px', '15px', '20px', '25px']
		}, {
			name: 'topNodeMarginVertical',
			inputType: 'select',
			label: 'Vertical margin',
			default: '0px',
			values: ['0px', '5px', '10px', '15px', '20px', '25px']
		}]
	}, {
		label: 'Dropdown nodes styling',
		props: [{
			name: 'dropDownFontSize',
			inputType: 'text',
			label: 'Font size',
			default: '12px'
		}, {
			name: 'dropDownFontColor',
			inputType: 'color',
			label: 'Text color',
			default: '#000000'
		}, {
			name: 'dropDownFontStyle',
			inputType: 'select',
			label: 'Font style',
			default: 'normal',
			values: ['normal', 'italic', 'oblique']
		}, {
			name: 'dropDownFontWeight',
			inputType: 'select',
			label: 'Font weight',
			default: 'normal',
			values: ['normal', 'bold', 'bolder', 'light', 'lighter']
		}, {
			name: 'dropDownTextTransform',
			inputType: 'select',
			label: 'Text transform',
			default: 'none',
			values: ['none', 'uppercase', 'lowercase', 'capitalize']
		}, {
			name: 'dropDownMarginRight',
			inputType: 'select',
			label: 'Right margin',
			default: '0px',
			values: ['0px', '5px', '10px', '15px', '20px', '25px', '30px', '35px', '40px', '45px', '50px']
		}, {
			name: 'dropDownMarginVertical',
			inputType: 'select',
			label: 'Vertical margin',
			default: '0px',
			values: ['0px', '5px', '10px', '15px', '20px', '25px']
		}]
	}]
};
