/* CUSTOMER OVERRIDE removeProductName */

export default `
	<Tabs>
		<Tab heading="General">
			<Grid>
				<Cell size="full" description="Layout">
					<Field name="layout"/>
				</Cell>
				<Cell size="full">
					<Field name="singlePurchase"/>
				</Cell>
				<Cell size="full">
					<Field name="singlePurchaseRelatedArticles"/>
				</Cell>
				<Cell size="full">
					<Field name="useDropdown"/>
					<Field name="dropdownPlaceholderSubscription"/>
					<Field name="dropdownPlaceholderSinglePurchase"/>
					<Field name="dropdownIconOpen"/>
					<Field name="dropdownIconOpenColor"/>
					<Field name="dropdownIconOpenSize"/>
					<Field name="dropdownIconClosed"/>
					<Field name="dropdownIconClosedColor"/>
					<Field name="dropdownIconClosedSize"/>
				</Cell>
				<Cell size="full" description="Toggle alternatives">
					<Field name="useToggle"/>
					<Field name="toggleIconType"/>
					<Field name="toggleImageOpen"/>
					<Field name="toggleImageClosed"/>
					<Field name="toggleIconOpen"/>
					<Field name="toggleIconOpenColor"/>
					<Field name="toggleIconOpenSize"/>
					<Field name="toggleIconClosed"/>
					<Field name="toggleIconClosedColor"/>
					<Field name="toggleIconClosedSize"/>
				</Cell>
				<Cell size="full">
					<Field name="useRadioButtons"/>
				</Cell>
				<Cell size="full" description="Checkout">
					<Field name="goToCheckout"/>
				</Cell>
				<Cell size="full" description="Buy button">
					<Field name="buttonText"/>
					<Field name="disableBuyButton"/>
				</Cell>
			</Grid>
		</Tab>
		<Tab heading="Price">
			<Grid>
				<Cell size="full" description="Price">
					<Field name="showPrice"/>
					<Field name="showPriceDifference"/>
					<Field name="pricePrefix"/>
					<Field name="priceSuffix"/>
					<Field name="showSavedAmount"/>
					<Field name="savedAmountPrefix"/>
					<Field name="savedAmountSuffix"/>
				</Cell>
			</Grid>
		</Tab>
		<Tab heading="Content">
			<Grid>
				<Cell size="full" description="Headings">
					<Field name="subscriptionHeading"/>
					<Field name="singlePurchaseHeading"/>
				</Cell>
				<Cell size="full" description="Additional text fields">
					<Field name="subscriptionText"/>
					<Field name="singlePurchaseText"/>
				</Cell>
				<Cell size="full" description="Descriptions">
					<Field name="showDescription1"/>
					<Field name="showDescription2"/>
					<Field name="showDescription3"/>
					<Field name="singlePurchaseDesc1"/>
					<Field name="singlePurchaseDesc2"/>
					<Field name="removeProductName"/>
				</Cell>
			</Grid>
		</Tab>
		<Tab heading="Advanced">
			<Grid>
				<Cell size="full" description="Advanced HTML">
					<Field name="advancedHtmlSubscription"/>
					<Field name="advancedHtmlSinglePurchase"/>
				</Cell>
			</Grid>
		</Tab>
	</Tabs>
`;
