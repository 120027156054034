import {connect} from '@viskan/deku-redux';
import {getTranslator} from '~/sources/translator';
import Component from './components';

export default connect(
	state => ({
		breakpoint: state.breakpoint.breakpointOverride || state.breakpoint.breakpoint,
		cmsActive: state.streamlineCms.loaded && !state.cms.base.hidden,
		getTranslation: getTranslator(state),
		site: state.site,
		isPerson: state.customer.person_flg
	})
)(Component);
