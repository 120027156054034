export default {
	type: 'ARTICLE_ADDONS',
	name: 'Addons',
	allowedSections: ['article'],
	disallowedChildren: ['*'],
	category: 'Article',
	props: [],
	hiddenProps: [{
		label: 'Relation No 4 settings',
		props: [{
			name: 'relation4Placeholder',
			inputType: 'text',
			label: 'Placeholder',
			default: ''
		}]
	}, {
		label: 'Advanced settings',
		props: [{
			name: 'class',
			inputType: 'text',
			label: 'CSS-class',
			default: ''
		}]
	}]
};
