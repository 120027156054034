import editorLayout from './editor-layout';

export default {
	type: 'SUBSCRIPTION_INFORMATION',
	name: 'Subscription Information',
	allowedSections: ['main'],
	disallowedChildren: ['*'],
	icon: 'fal fa-info-square',
	category: 'Checkout',
	editorLayout,
	styleClasses: {
		Wrapper: 'Preset-Primary-Text Preset-Primary-Font-Family Preset-Primary-Color'
	},
	props: []
};
