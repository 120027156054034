import {compose} from 'redux';
import traverse from 'traverse';

const nonComplexConstructors = ['Object', 'Array'];
const isComplexObject = x => typeof x === 'object' && x && x.constructor && !nonComplexConstructors.includes(x.constructor.name);

const safeSerializer = (options = {}) => state => {
	const {depth = 50} = options;

	return traverse(state).map(function () {
		if (depth && this.level > depth) {
			return this.update(`[LEVEL ABOVE ${depth}]`, true);
		}

		if (isComplexObject(this.node)) {
			return this.update('[COMPLEX OBJECT]', true);
		}

		if (this.circular) {
			return this.update('[CIRCULAR]', true);
		}

		return this.node;
	});
};

export const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
	serialize: true,
	stateSanitizer: safeSerializer(),
	trace: true
}) : compose;

export const serverErrorLogger = error => {
	try {
		v12.util.error(typeof error === 'object' && error.error ? error.error : error);
	} catch (error) {
		console.error('Error while posting error to server:', error);
	}
};

export const dekuErrorHandler = (error, errorInformation) => {
	if (typeof errorInformation === 'object') {
		console.warn(`Catched "${error}"`, errorInformation);
	} else {
		console.warn(`Catched deku ${errorInformation} ${error}`);
	}

	serverErrorLogger(error);
};
