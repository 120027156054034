import {connect} from '@viskan/deku-redux';
import {remove, add} from './actions';
import config from './config';
import Component from './components';

export const component = connect(
	(state, props) => ({
		articleIsLoaded: props.articleIsLoaded || state.article.isLoaded,
		artNo: props.artNo || state.article.article.art_no,
		favorites: state.articles.favorites.articles,
		favoritesArtNosAndAttr1Ids: state.articles.favorites.artNosAndAttr1Ids,
		activeArticleAttr1Id: state.article.active ? state.article.active.attr1_id : 0
	}),
	dispatch => ({
		remove: (artNo, attr1Id) => dispatch(remove(artNo, attr1Id)),
		add: (artNo, attr1Id) => dispatch(add(artNo, attr1Id))
	})
)(Component);

export default {component, config};
