import {connectWrap as connect} from '@viskan/deku-redux';
import {categoryFetch} from '../category/actions';
import Component from './components';

export default connect(
	state => ({
		languageId: state.site.lang_id
	}),
	() => ({
		categoryFetch
	})
)(Component);
