import {createAction} from 'redux-actions';
import {CMS_IS_LOADED} from '../action-types';

export const ensureCmsIsLoaded = createAction(CMS_IS_LOADED, () => {
	return new Promise(resolve => {
		const cmsInterval = setInterval(() => {
			if (window._streamlineCms) {
				clearInterval(cmsInterval);
				resolve();
			}
		}, 200);
	});
});
