import mergeOptions from 'merge-options';

const defaultTranslations = {
	da: require('../../locale/da.json'),
	de: require('../../locale/de.json'),
	en: require('../../locale/en.json'),
	fi: require('../../locale/fi.json'),
	no: require('../../locale/no.json'),
	sv: require('../../locale/sv.json'),
	nl: require('../../locale/nl.json'), // Customer unique
	it: require('../../locale/it.json'), // Customer unique
	es: require('../../locale/es.json'), // Customer unique
	fr: require('../../locale/fr.json'), // Customer unique
	pl: require('../../locale/pl.json') // Customer unique
};

const extendedTranslations = {
	da: require('../../locale/extends/da.json'),
	de: require('../../locale/extends/de.json'),
	en: require('../../locale/extends/en.json'),
	fi: require('../../locale/extends/fi.json'),
	no: require('../../locale/extends/no.json'),
	sv: require('../../locale/extends/sv.json'),
	nl: require('../../locale/extends/nl.json'), // Customer unique
	it: require('../../locale/extends/it.json'), // Customer unique
	es: require('../../locale/extends/es.json'), // Customer unique
	fr: require('../../locale/extends/fr.json'), // Customer unique
	pl: require('../../locale/extends/pl.json') // Customer unique
};

const convertObjectToDotNotation = (obj, key, result) => {
	if (typeof obj !== 'object') {
		result[key] = obj;
		return result;
	}
	const keys = Object.keys(obj);

	for (let i = 0; i < keys.length; i++) {
		const newKey = key ? (key + '.' + keys[i]) : keys[i];
		convertObjectToDotNotation(obj[keys[i]], newKey, result);
	}

	return result;
};

const editableTranslations = () => {
	try {
		const translations = convertObjectToDotNotation(mergeOptions(extendedTranslations[window._streamline.siteContext.lang_code_web], defaultTranslations[window._streamline.siteContext.lang_code_web]), '', {});

		const settings = Object.entries(translations).map(translation => ({
			description: `Default translation: ${translation[1]}`,
			label: translation[0].replace(/^([^.]+\.){1}/, ''),
			settingKey: translation[0],
			type: 'text',
			defaultValue: translation[1],
			languageSpecific: true
		}));

		return Array.from(new Set([...Object.keys(translations).map(key => key.split('.')[0])])).map(label => {
			return {
				label: label.split(new RegExp(/[\W_]+/g)).join(' '),
				settings: [...settings.filter(setting => setting.settingKey.split('.')[0] === label)]
			};
		}).sort((a, b) => a.label.localeCompare(b.label));
	} catch (error) {
		v12.util.error(error);
	}
};

export default window._streamline.siteContext.cmsTokenSet ? editableTranslations() : {};
