import Icon from '@viskan/deku-icon-wrapper';
import Popup from '@viskan/deku-popup';

const toggleBodyPopupClass = open => open ? document.querySelector('body').classList.add('Popup-is-open') : document.querySelector('body').classList.remove('Popup-is-open');
const render = ({props}) => {
	const {close, content, open, options} = props;
	toggleBodyPopupClass(open);

	if (!open) {
		return <noscript/>;
	}

	return (
		<Popup {...options} onClickOutside={close} open>
			<div class='Popup-close' onClick={close}>
				<Icon icon='times'/>
			</div>
			<div class='Popup-content'>
				{content}
			</div>
		</Popup>
	);
};

export default {render};
