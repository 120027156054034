import {deepEqual} from 'fast-equals';
import BadgeList from '~/badge/list';
import {component as FavoriteButton} from '~/cms-components/article/favorite';
import {ColorList, SizeList, SizeRange} from '~/attribute-list';
import Link from '~/link';
import {Image} from '~/media';
import Price from '~/price';
import QuickBuy from '~/quick-buy';
import {isEmpty, mergeDefined, sanitizeAttr1IdKeyValue} from '~/utils';
import {type, stripHtml} from '@viskan/streamline-utilities';

const propTypes = {
	article: {
		type: 'object'
	},
	back: {
		type: 'string'
	},
	class: {
		type: 'string'
	},
	front: {
		type: 'string'
	},
	media: {
		type: 'object'
	},
	trackProductClick: {
		source: 'trackProductClick'
	}
};

const defaultProps = {
	back: '-1',
	badgeOptions: {
		showCampaigns: false,
		showSaleOrNew: false
	},
	front: '1010',
	media: {},
	scale: 'list',
	styleClasses: {},
	styling: {}
};

const getMedia = (props, attr1) => {
	const attr1_id = type.isUndefined(attr1) ? props.attr1_id : attr1;
	return v12.article.media(props.article, {
		size: '',
		include: [parseInt(props.front, 10), parseInt(props.back, 10)],
		sku: {
			attr1_id
		},
		useFilename: true
	});
};

const initialState = props => {
	const medias = getMedia(props);

	return {
		attr1_id: props.attr1_id,
		image: medias.front,
		imageSizes: medias.sizes && medias.sizes.front ? medias.sizes.front : {}
	};
};

const onMouseOver = (medias, setState) => () => medias.back && setState({image: medias.back});
const onMouseOut = (medias, setState) => () => setState({image: medias.front});
const getLink = ({article, site, meta}, attr1_id) => `/${site.articleUrlPrefix}/${article.link_friendly_name}${attr1_id > 0 ? `?attr1_id=${attr1_id}` : ''}${(meta && meta.trackingLink) || ''}`;

const afterUpdate = ({props, state}, prevProps, prevState, setState) => {
	const {article, front} = props;
	const {attr1_id, image} = state;
	const attr1IdFound = props.article.articleAttributes.some(attribute => attribute.attr1_id === attr1_id);

	if ((attr1_id !== props.attr1_id) && !attr1IdFound) {
		setState({attr1_id: props.attr1_id});
		return;
	}

	const medias = getMedia(props, attr1_id);

	if (front !== prevProps.front || !deepEqual(article.articleAttributeMedias, prevProps.article.articleAttributeMedias)) {
		setState({
			image: medias.front,
			imageSizes: medias.sizes && medias.sizes.front ? medias.sizes.front : {}
		});
	}

	if (medias.front !== image && medias.back !== image) {
		setState({
			image: medias.front,
			imageSizes: medias.sizes && medias.sizes.front ? medias.sizes.front : {}
		});
	}
};

const shouldUpdate = ({props, state}, nextProps, nextState) => {
	if (!deepEqual(state, nextState)) {
		return true;
	}

	return !deepEqual(props, nextProps);
};

const sanitizeMediaFilename = filename => {
	// V12 returns media prefixed with /
	if (filename && filename.startsWith('/')) {
		return filename.slice(1);
	}

	return filename;
};

const getArticleProperties = (propertyNames, articleProperties, styleClasses) => {
	const foundProperties = propertyNames.split(',').map(name => articleProperties.filter(property => property.propertykey_name_internal.toLowerCase() === name.toLowerCase().trim()));

	return foundProperties.map(properties => {
		return properties.map(property => {
			return (
				<div key={property.propertykey_name_internal} class={['CMS-ListArticleProperty', styleClasses.Property]} data-style-attr='Property'>
					<div class={['CMS-ListArticleProperty-key', styleClasses.Property_Key]} data-style-attr='Property_Key'>
						{property.propertykey_name}
					</div>
					<div class={['CMS-ListArticleProperty-value', styleClasses.Property_Value]} data-style-attr='Property_Value'>
						{property.propertyValues.map(value => <div key={value.propertyvalue} innerHTML={value.propertyvalue}/>)}
					</div>
				</div>
			);
		});
	});
};

const handleSetListColor = setState => attr1_id => setState({attr1_id});

const render = ({props, state}, setState) => {
	/**
	 * Customer unique code
	 * Comment: Get showLipscoreRatingWidget from props, and render widget if showLipscoreRatingWidget is true
	 */
	const {cmsActive, quickbuy, quickbuyInPopup, showColorList, showSizeList, showArticleDescription, showArticleNumber, showColor, showBrandName, showArticleProperties, showPlunoNumber, showNumberOfVariants, showLipscoreRatingWidget, stripArticleDescriptionHtml, styleClasses, styling, trackProductClick, favorite, favoriteOptions, isArticle, isBig, bigArticleImageScale, isPerson, scale, showSizeRange, regexToRemoveFromSize} = props;
	const {attr1_id, image, imageSizes} = state;
	const article = {...props.article, attr1_id};
	const {art_desc, art_no, artname} = article;
	const sku = article.articleAttributes.find(sku => sku.attr1_id === attr1_id) || article.articleAttributes[0];
	const color = showColor && article.articleAttributes.reduce((acc, attr) => {
		return !acc.includes(attr.attribute1.attr1_code) ? [...acc, attr.attribute1.attr1_code] : acc;
	}, []).toString();

	const properties = props.showArticleProperties && getArticleProperties(props.articleProperties, article.propertyKeys, styleClasses);
	const imageElement = <Image source={sanitizeMediaFilename(image)} scale={isBig ? bigArticleImageScale : scale} alt={artname} title={artname} width={imageSizes.width} height={imageSizes.height} style='cursor: pointer;'/>;
	const medias = getMedia(props, attr1_id);
	const url = getLink(props, attr1_id);
	const {articleMargins, articleNameFontColor, articleNameFontSize, articleNameFontStyle, articleNameFontWeight, articleTextPaddings} = styling;
	const badgeOptions = mergeDefined(defaultProps.badgeOptions, props.badgeOptions);
	const quickbuyButtonText = quickbuy && (props.quickbuyButtonText || props.getTranslation('articles.buy'));
	const showPriceForCurrentSku = props.colorListOptions && props.colorListOptions.colorListShowFromPrice && props.showColorList ? false : props.showPriceForCurrentSku || props.showColorList;
	const articleStyles = {
		margin: articleMargins
	};

	const articleNameStyles = {
		'color': articleNameFontColor,
		'font-size': articleNameFontSize,
		'font-style': articleNameFontStyle,
		'font-weight': articleNameFontWeight
	};

	const articleTextStyles = {
		'padding': articleTextPaddings
	};

	return (
		<article class={['ListArticle', props.class, styleClasses.Article]} data-style-attr='Article' style={!styleClasses.Article && articleStyles}>
			<div class='ListArticle-img-wrapper'>
				<figure class={[!isBig ? ['ListArticle-img', styleClasses.Image] : ['ListArticleBig-img', styleClasses.BigArticleImage]]} data-style-attr={!isBig ? 'Image' : 'BigArticleImage'} onMouseOver={onMouseOver(medias, setState)} onMouseOut={onMouseOut(medias, setState)}>
					<BadgeList article={article} sku={sku} {...badgeOptions} isPerson={isPerson}/>
					{!cmsActive ?
						<Link href={url} onClick={trackProductClick(article)}>
							{imageElement}
						</Link> : imageElement
					}
				</figure>
				{showSizeList && <SizeList article={article} sizeListOptions={props.sizeListOptions} styleClasses={styleClasses}/>}
				{showSizeRange && <SizeRange article={article} regexToRemoveFromSize={regexToRemoveFromSize} styleClasses={styleClasses} getTranslation={props.getTranslation}/>}
				{showColorList && <ColorList article={article} breakpoint={props.breakpoint} colorListOptions={props.colorListOptions} handleSetListColor={handleSetListColor(setState)} styleClasses={styleClasses} sanitizeMediaFilename={sanitizeMediaFilename}/>}
			</div>
			<div class={['ListArticle-body', styleClasses.Article_Body]} style={!styleClasses.Article_Body && articleTextStyles} data-style-attr='Article_Body'>
				<Link href={!cmsActive && url} onClick={!cmsActive && trackProductClick(article)}>
					<h2 class={['ListArticle-title', styleClasses.Title]} style={!styleClasses.Title && articleNameStyles} data-style-attr='Title'>
						{artname}
					</h2>
					{showBrandName && !isEmpty(article.brand) &&
						<span class={['ListArticle-brand', styleClasses.Brand]} data-style-attr='Brand'>{article.brand.name}</span>
					}
					{showArticleDescription && art_desc && art_desc.length > 0 &&
						<span class={['ListArticle-artdesc', styleClasses.ArtDesc]} data-style-attr='ArtDesc' innerHTML={stripArticleDescriptionHtml ? stripHtml(art_desc) : art_desc}/>
					}
					{showArticleNumber && art_no &&
						<span class={['ListArticle-artno', styleClasses.ArtNo]} data-style-attr='ArtNo'>
							{art_no}
						</span>
					}
					{showPlunoNumber && sku.pluno.length !== 0 &&
						<span class={['ListArticle-pluno', styleClasses.Pluno]} data-style-attr='Pluno'>
							{sku.pluno}
						</span>
					}
					{(!showColorList && showColor && color) &&
						<span class={['ListArticle-color', styleClasses.Color]} data-style-attr='Color' data-color={color}>
							{color}
						</span>
					}
					{(showNumberOfVariants && article.articleAttributes.length > 1) &&
						<span class={['ListArticle-variants', styleClasses.Variants_Qty]} data-style-attr='Variants_Qty'>
							{props.getTranslation('articles.variants', {qty: article.articleAttributes.length})}
						</span>
					}
					{(showArticleProperties && properties) &&
						<span class={['ListArticle-properties', styleClasses.Properties]} data-style-attr='Properties'>
							{properties}
						</span>
					}
					<Price class='ListArticle-prices' styleClasses={styleClasses} data={article} sku={sku} showDiscountPercent={props.showDiscountPercent} showPriceForCurrentSku={showPriceForCurrentSku} showFromLabel={props.showFromLabel} showRecPrice={props.showRecPrice} recPriceLabel={props.recPricePrefixText}/>
				</Link>
				{favorite && <FavoriteButton articleIsLoaded artNo={article.art_no} attr1Id={sanitizeAttr1IdKeyValue(sku)} favoriteOptions={favoriteOptions}/>}
				{isArticle && quickbuy ? <Link href={url} class={['Button Button Button--primary ListArticle-button-buy']} onClick={trackProductClick(article)}>{quickbuyButtonText}</Link> : quickbuy ? <QuickBuy data={article} sku={sku} url={url} styleClasses={styleClasses} quickbuyInPopup={quickbuyInPopup} quickbuyButtonText={quickbuyButtonText}/> : <noscript/> }
				{showLipscoreRatingWidget && <span class='lipscore-rating-small' ls-product-name={artname} ls-brand={article.brandName} ls-product-id={article.art_no} ls-product-url={`${window.location.origin}/${props.locale}${url}`} ls-product-description={article.art_desc}/>}
			</div>
		</article>
	);
};

export default {afterUpdate, defaultProps, initialState, propTypes, render, shouldUpdate};
