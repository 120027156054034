import {
	INSTAGRAM_FEED_FETCH_FULFILLED,
	INSTAGRAM_SET_ACTIVE_INDEX,
	INSTAGRAM_SET_CURRENT_INDEX,
	INSTAGRAM_SET_NEXT_INDEX,
	INSTAGRAM_SET_PREV_INDEX,
	INSTAGRAM_SET_MARKERS,
	INSTAGRAM_SHOW_MARKER_TEXT,
	INSTAGRAM_PAGINATION_NEXT,
	INSTAGRAM_POPUP_OPEN,
	PATH_CHANGE
} from '../../action-types';

export const initialState = {
	activeIndex: 0,
	artId: 0,
	data: {},
	from: 0,
	grams: [],
	index: 0,
	loaded: false,
	showText: -1,
	total: 0,
	visibleMarkers: [],
	visibleText: []
};

export default (state = initialState, action) => {
	switch (action.type) {
		case INSTAGRAM_FEED_FETCH_FULFILLED: {
			return {
				...state,
				grams: action.payload.from === 0 ? action.payload.grams : state.grams.concat(action.payload.grams),
				total: action.payload.total,
				from: action.payload.from === 0 ? action.payload.from : state.from,
				activeIndex: action.payload.from === 0 ? 0 : state.activeIndex
			};
		}
		case INSTAGRAM_SET_ACTIVE_INDEX: {
			return {
				...state,
				activeIndex: action.payload
			};
		}
		case INSTAGRAM_SET_CURRENT_INDEX: {
			return {
				...state,
				index: action.payload.index
			};
		}
		case INSTAGRAM_SET_NEXT_INDEX: {
			if (action.payload.index + 1 <= action.payload.posts.length - 1) {
				return {
					...state,
					data: action.payload.posts[action.payload.index + 1],
					index: action.payload.index + 1
				};
			}

			return {
				...state,
				data: action.payload.posts[0],
				index: 0
			};
		}
		case INSTAGRAM_SET_PREV_INDEX: {
			if (action.payload.index - 1 >= 0) {
				return {
					...state,
					data: action.payload.posts[action.payload.index - 1],
					index: action.payload.index - 1
				};
			}

			return {
				...state,
				data: action.payload.posts[action.payload.posts.length - 1],
				index: action.payload.posts.length - 1
			};
		}
		case INSTAGRAM_SET_MARKERS: {
			return {
				...state,
				visibleMarkers: action.payload.visibleMarkers,
				visibleText: action.payload.visibleText
			};
		}
		case INSTAGRAM_SHOW_MARKER_TEXT: {
			return {
				...state,
				showText: action.payload.showText
			};
		}
		case INSTAGRAM_PAGINATION_NEXT: {
			if (state.grams.length > state.total) {
				return state;
			}

			return {
				...state,
				from: state.grams.length
			};
		}
		case INSTAGRAM_POPUP_OPEN: {
			return {
				...state,
				data: action.payload.data,
				index: action.payload.index,
				loaded: action.payload.loaded
			};
		}
		case PATH_CHANGE: {
			return initialState;
		}
		default: {
			return state;
		}
	}
};
