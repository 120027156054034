import {PATH_CHANGE} from '../action-types';

const initialState = {
	canonicalPath: '',
	canonicalPathname: '',
	hash: '',
	changes: 0,
	isSearch: false,
	pathPrefix: '',
	localePathPrefixInUse: '',
	params: {},
	path: '',
	pathname: '',
	query: {},
	querystring: '',
	state: {},
	title: ''
};

export default (state = initialState, action) => {
	switch (action.type) {
		case PATH_CHANGE: {
			return {
				...action.payload,
				changes: state.changes + 1
			};
		}
		default: {
			return state;
		}
	}
};
