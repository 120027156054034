import loadable from '@viskan/deku-loadable';
const cmsComponentArticleMatrix = loadable(() => import('~/../../node_modules/@viskan/cms-component-article-matrix'));
import cmsComponentArticleMatrixConfig from '~/../../node_modules/@viskan/cms-component-article-matrix/dist/config';
const cmsComponentArticlePackage = loadable(() => import('~/../../node_modules/@viskan/cms-component-article-package'));
import cmsComponentArticlePackageConfig from '~/../../node_modules/@viskan/cms-component-article-package/dist/config';
const cmsComponentArticlesColumnSize = loadable(() => import('~/../../node_modules/@viskan/cms-component-articles-column-size'));
import cmsComponentArticlesColumnSizeConfig from '~/../../node_modules/@viskan/cms-component-articles-column-size/dist/config';
const cmsComponentArticlesView = loadable(() => import('~/../../node_modules/@viskan/cms-component-articles-view'));
import cmsComponentArticlesViewConfig from '~/../../node_modules/@viskan/cms-component-articles-view/dist/config';
const cmsComponentBlurb1 = loadable(() => import('~/../../node_modules/@viskan/cms-component-blurb-1'));
import cmsComponentBlurb1Config from '~/../../node_modules/@viskan/cms-component-blurb-1/dist/config';
const cmsComponentBlurb2 = loadable(() => import('~/../../node_modules/@viskan/cms-component-blurb-2'));
import cmsComponentBlurb2Config from '~/../../node_modules/@viskan/cms-component-blurb-2/dist/config';
const cmsComponentBlurb3 = loadable(() => import('~/../../node_modules/@viskan/cms-component-blurb-3'));
import cmsComponentBlurb3Config from '~/../../node_modules/@viskan/cms-component-blurb-3/dist/config';
const cmsComponentBlurb4 = loadable(() => import('~/../../node_modules/@viskan/cms-component-blurb-4'));
import cmsComponentBlurb4Config from '~/../../node_modules/@viskan/cms-component-blurb-4/dist/config';
const cmsComponentBlurb5 = loadable(() => import('~/../../node_modules/@viskan/cms-component-blurb-5'));
import cmsComponentBlurb5Config from '~/../../node_modules/@viskan/cms-component-blurb-5/dist/config';
const cmsComponentBlurb6 = loadable(() => import('~/../../node_modules/@viskan/cms-component-blurb-6'));
import cmsComponentBlurb6Config from '~/../../node_modules/@viskan/cms-component-blurb-6/dist/config';
import cmsComponentBlurbCustomContent from '~/../../node_modules/@viskan/cms-component-blurb-custom-content';
import cmsComponentBlurbCustomContentConfig from '~/../../node_modules/@viskan/cms-component-blurb-custom-content/dist/config';
const cmsComponentBreadcrumbs = loadable(() => import('~/../../node_modules/@viskan/cms-component-breadcrumbs'));
import cmsComponentBreadcrumbsConfig from '~/../../node_modules/@viskan/cms-component-breadcrumbs/dist/config';
const cmsComponentButton = loadable(() => import('~/../../node_modules/@viskan/cms-component-button'));
import cmsComponentButtonConfig from '~/../../node_modules/@viskan/cms-component-button/dist/config';
const cmsComponentLocale = loadable(() => import('~/../../node_modules/@viskan/cms-component-locale'));
import cmsComponentLocaleConfig from '~/../../node_modules/@viskan/cms-component-locale/dist/config';
const cmsComponentCategoryImport = loadable(() => import('~/../../node_modules/@viskan/cms-component-category-import'));
import cmsComponentCategoryImportConfig from '~/../../node_modules/@viskan/cms-component-category-import/dist/config';
const cmsComponentB2BB2C = loadable(() => import('~/../../node_modules/@viskan/cms-component-b2b-b2c'));
import cmsComponentB2BB2CConfig from '~/../../node_modules/@viskan/cms-component-b2b-b2c/dist/config';
const cmsComponentArticlesSort = loadable(() => import(/* webpackChunkName: "cms-component-filter" */ '~/../../node_modules/@viskan/cms-component-articles-sort'));
import cmsComponentArticlesSortConfig from '~/../../node_modules/@viskan/cms-component-articles-sort/dist/config';
import cmsComponentCell from '~/../../node_modules/@viskan/cms-component-cell';
import cmsComponentCellConfig from '~/../../node_modules/@viskan/cms-component-cell/dist/config';
import cmsComponentContainer from '~/../../node_modules/@viskan/cms-component-container';
import cmsComponentContainerConfig from '~/../../node_modules/@viskan/cms-component-container/dist/config';
const cmsComponentCookiebar = loadable(() => import('~/../../node_modules/@viskan/cms-component-cookiebar'));
import cmsComponentCookiebarConfig from '~/../../node_modules/@viskan/cms-component-cookiebar/dist/config';
import cmsComponentDropdown from '~/../../node_modules/@viskan/cms-component-dropdown';
import cmsComponentDropdownConfig from '~/../../node_modules/@viskan/cms-component-dropdown/dist/config';
const cmsComponentDynamicCell = loadable(() => import(/* webpackChunkName: "cms-component-dynamic-grid" */ '~/../../node_modules/@viskan/cms-component-dynamic-cell'));
import cmsComponentDynamicCellConfig from '~/../../node_modules/@viskan/cms-component-dynamic-cell/dist/config';
const cmsComponentDynamicGrid = loadable(() => import(/* webpackChunkName: "cms-component-dynamic-grid" */ '~/../../node_modules/@viskan/cms-component-dynamic-grid'));
import cmsComponentDynamicGridConfig from '~/../../node_modules/@viskan/cms-component-dynamic-grid/dist/config';
const cmsComponentGoogleMaps = loadable(() => import('~/../../node_modules/@viskan/cms-component-google-maps'));
import cmsComponentGoogleMapsConfig from '~/../../node_modules/@viskan/cms-component-google-maps/dist/config';
import cmsComponentGrid from '~/../../node_modules/@viskan/cms-component-grid';
import cmsComponentGridConfig from '~/../../node_modules/@viskan/cms-component-grid/dist/config';
const cmsComponentHtmlAdvanced = loadable(() => import('~/../../node_modules/@viskan/cms-component-html-advanced'));
import cmsComponentHtmlAdvancedConfig from '~/../../node_modules/@viskan/cms-component-html-advanced/dist/config';
const cmsComponentIcon = loadable(() => import('~/../../node_modules/@viskan/cms-component-icon'));
import cmsComponentIconConfig from '~/../../node_modules/@viskan/cms-component-icon/dist/config';
const cmsComponentList = loadable(() => import('~/../../node_modules/@viskan/cms-component-list'));
import cmsComponentListConfig from '~/../../node_modules/@viskan/cms-component-list/dist/config';
import cmsComponentMainNavigation from '~/../../node_modules/@viskan/cms-component-main-navigation';
import cmsComponentMainNavigationConfig from '~/../../node_modules/@viskan/cms-component-main-navigation/dist/config';
import cmsComponentMedia from '~/../../node_modules/@viskan/cms-component-media';
import cmsComponentMediaConfig from '~/../../node_modules/@viskan/cms-component-media/dist/config';
const cmsComponentMessageForm = loadable(() => import(/* webpackChunkName: "cms-component-message-form" */ '~/../../node_modules/@viskan/cms-component-message-form'));
import cmsComponentMessageFormConfig from '~/../../node_modules/@viskan/cms-component-message-form/dist/config';
const cmsComponentMessageFormField = loadable(() => import(/* webpackChunkName: "cms-component-message-form" */ '~/../../node_modules/@viskan/cms-component-message-form-field'));
import cmsComponentMessageFormFieldConfig from '~/../../node_modules/@viskan/cms-component-message-form-field/dist/config';
const cmsComponentMyPagesAuthenticate = loadable(() => import(/* webpackChunkName: "cms-component-my-pages" */ '~/../../node_modules/@viskan/cms-component-my-pages-authenticate'));
import cmsComponentMyPagesAuthenticateConfig from '~/../../node_modules/@viskan/cms-component-my-pages-authenticate/dist/config';
const cmsComponentMyPagesLogout = loadable(() => import(/* webpackChunkName: "cms-component-my-pages" */ '~/../../node_modules/@viskan/cms-component-my-pages-logout'));
import cmsComponentMyPagesLogoutConfig from '~/../../node_modules/@viskan/cms-component-my-pages-logout/dist/config';
const cmsComponentMyPagesOffers = loadable(() => import(/* webpackChunkName: "cms-component-my-pages" */ '~/../../node_modules/@viskan/cms-component-my-pages-offers'));
import cmsComponentMyPagesOffersConfig from '~/../../node_modules/@viskan/cms-component-my-pages-offers/dist/config';
const cmsComponentMyPagesOrders = loadable(() => import(/* webpackChunkName: "cms-component-my-pages" */ '~/../../node_modules/@viskan/cms-component-my-pages-orders'));
import cmsComponentMyPagesOrdersConfig from '~/../../node_modules/@viskan/cms-component-my-pages-orders/dist/config';
const cmsComponentMyPagesProfile = loadable(() => import(/* webpackChunkName: "cms-component-my-pages" */ '~/../../node_modules/@viskan/cms-component-my-pages-profile'));
import cmsComponentMyPagesProfileConfig from '~/../../node_modules/@viskan/cms-component-my-pages-profile/dist/config';
const cmsComponentMyPagesResetPassword = loadable(() => import(/* webpackChunkName: "cms-component-my-pages" */ '~/../../node_modules/@viskan/cms-component-my-pages-reset-password'));
import cmsComponentMyPagesResetPasswordConfig from '~/../../node_modules/@viskan/cms-component-my-pages-reset-password/dist/config';
const cmsComponentMyPagesSubscriptions = loadable(() => import(/* webpackChunkName: "cms-component-my-pages" */ '~/../../node_modules/@viskan/cms-component-my-pages-subscriptions'));
import cmsComponentMyPagesSubscriptionsConfig from '~/../../node_modules/@viskan/cms-component-my-pages-subscriptions/dist/config';
const cmsComponentNewsletterForm = loadable(() => import('~/../../node_modules/@viskan/cms-component-newsletter-form'));
import cmsComponentNewsletterFormConfig from '~/../../node_modules/@viskan/cms-component-newsletter-form/dist/config';
const cmsComponentNotice = loadable(() => import('~/../../node_modules/@viskan/cms-component-notice'));
import cmsComponentNoticeConfig from '~/../../node_modules/@viskan/cms-component-notice/dist/config';
const cmsComponentPopup = loadable(() => import('~/../../node_modules/@viskan/cms-component-popup'));
import cmsComponentPopupConfig from '~/../../node_modules/@viskan/cms-component-popup/dist/config';
const cmsComponentScript = loadable(() => import('~/../../node_modules/@viskan/cms-component-script'));
import cmsComponentScriptConfig from '~/../../node_modules/@viskan/cms-component-script/dist/config';
const cmsComponentScrollToTop = loadable(() => import('~/../../node_modules/@viskan/cms-component-scroll-to-top'));
import cmsComponentScrollToTopConfig from '~/../../node_modules/@viskan/cms-component-scroll-to-top/dist/config';
const cmsComponentShareButtons = loadable(() => import('~/../../node_modules/@viskan/cms-component-share-buttons'));
import cmsComponentShareButtonsConfig from '~/../../node_modules/@viskan/cms-component-share-buttons/dist/config';
const cmsComponentShopTheLook = loadable(() => import('~/../../node_modules/@viskan/cms-component-shop-the-look'));
import cmsComponentShopTheLookConfig from '~/../../node_modules/@viskan/cms-component-shop-the-look/dist/config';
const cmsComponentSkuTable = loadable(() => import('~/../../node_modules/@viskan/cms-component-sku-table'));
import cmsComponentSkuTableConfig from '~/../../node_modules/@viskan/cms-component-sku-table/dist/config';
import cmsComponentSlideout from '~/../../node_modules/@viskan/cms-component-slideout';
import cmsComponentSlideoutConfig from '~/../../node_modules/@viskan/cms-component-slideout/dist/config';
const cmsComponentSlider = loadable(() => import('~/../../node_modules/@viskan/cms-component-slider'));
import cmsComponentSliderConfig from '~/../../node_modules/@viskan/cms-component-slider/dist/config';
import cmsComponentStickyHeader from '~/../../node_modules/@viskan/cms-component-sticky-header';
import cmsComponentStickyHeaderConfig from '~/../../node_modules/@viskan/cms-component-sticky-header/dist/config';
const cmsComponentSubMenu = loadable(() => import(/* webpackChunkName: "cms-component-sub-menu" */ '~/../../node_modules/@viskan/cms-component-sub-menu'));
import cmsComponentSubMenuConfig from '~/../../node_modules/@viskan/cms-component-sub-menu/dist/config';
const cmsComponentSubMenuDropdown = loadable(() => import(/* webpackChunkName: "cms-component-sub-menu" */ '~/../../node_modules/@viskan/cms-component-sub-menu-dropdown'));
import cmsComponentSubMenuDropdownConfig from '~/../../node_modules/@viskan/cms-component-sub-menu-dropdown/dist/config';
const cmsComponentSubMenuItem = loadable(() => import(/* webpackChunkName: "cms-component-sub-menu" */ '~/../../node_modules/@viskan/cms-component-sub-menu-item'));
import cmsComponentSubMenuItemConfig from '~/../../node_modules/@viskan/cms-component-sub-menu-item/dist/config';
const cmsComponentTab = loadable(() => import(/* webpackChunkName: "cms-component-tabs" */ '~/../../node_modules/@viskan/cms-component-tab'));
import cmsComponentTabConfig from '~/../../node_modules/@viskan/cms-component-tab/dist/config';
const cmsComponentTabsContainer = loadable(() => import(/* webpackChunkName: "cms-component-tabs" */ '~/../../node_modules/@viskan/cms-component-tabs-container'));
import cmsComponentTabsContainerConfig from '~/../../node_modules/@viskan/cms-component-tabs-container/dist/config';
import cmsComponentText from '~/../../node_modules/@viskan/cms-component-text';
import cmsComponentTextConfig from '~/../../node_modules/@viskan/cms-component-text/dist/config';
const cmsComponentToggler = loadable(() => import('~/../../node_modules/@viskan/cms-component-toggler'));
import cmsComponentTogglerConfig from '~/../../node_modules/@viskan/cms-component-toggler/dist/config';
const cmsComponentReadToggler = loadable(() => import('~/../../node_modules/@viskan/cms-component-read-toggler'));
import cmsComponentReadTogglerConfig from '~/../../node_modules/@viskan/cms-component-read-toggler/dist/config';
const cmsComponentTrustpilot = loadable(() => import('~/../../node_modules/@viskan/cms-component-trustpilot'));
import cmsComponentTrustpilotConfig from '~/../../node_modules/@viskan/cms-component-trustpilot/dist/config';
const cmsComponentVideo = loadable(() => import('~/../../node_modules/@viskan/cms-component-video'));
import cmsComponentVideoConfig from '~/../../node_modules/@viskan/cms-component-video/dist/config';
const cmsComponentCheckout = loadable(() => import(/* webpackChunkName: "cms-component-checkout" */ '~/../../node_modules/@viskan/cms-component-checkout'));
import cmsComponentCheckoutConfig from '~/../../node_modules/@viskan/cms-component-checkout/dist/config';
const cmsComponentCheckoutWithAuthentication = loadable(() => import(/* webpackChunkName: "cms-component-checkout" */ '~/../../node_modules/@viskan/cms-component-checkout-with-authentication'));
import cmsComponentCheckoutWithAuthenticationConfig from '~/../../node_modules/@viskan/cms-component-checkout-with-authentication/dist/config';
const cmsComponentCheckoutEmbedded = loadable(() => import(/* webpackChunkName: "cms-component-checkout" */ '~/../../node_modules/@viskan/cms-component-checkout-embedded'));
import cmsComponentCheckoutEmbeddedConfig from '~/../../node_modules/@viskan/cms-component-checkout-embedded/dist/config';
const cmsComponentCheckoutShipping = loadable(() => import(/* webpackChunkName: "cms-component-checkout" */ '~/../../node_modules/@viskan/cms-component-checkout-shipping'));
import cmsComponentCheckoutShippingConfig from '~/../../node_modules/@viskan/cms-component-checkout-shipping/dist/config';
const cmsComponentCheckoutPayment = loadable(() => import(/* webpackChunkName: "cms-component-checkout" */ '~/../../node_modules/@viskan/cms-component-checkout-payment'));
import cmsComponentCheckoutPaymentConfig from '~/../../node_modules/@viskan/cms-component-checkout-payment/dist/config';
const cmsComponentCheckoutCustomerForm = loadable(() => import(/* webpackChunkName: "cms-component-checkout" */ '~/../../node_modules/@viskan/cms-component-checkout-customer-form'));
import cmsComponentCheckoutCustomerFormConfig from '~/../../node_modules/@viskan/cms-component-checkout-customer-form/dist/config';
const cmsComponentCheckoutCustomerLookup = loadable(() => import(/* webpackChunkName: "cms-component-checkout" */ '~/../../node_modules/@viskan/cms-component-checkout-customer-lookup'));
import cmsComponentCheckoutCustomerLookupConfig from '~/../../node_modules/@viskan/cms-component-checkout-customer-lookup/dist/config';
const cmsComponentCheckoutCustomerSubmit = loadable(() => import(/* webpackChunkName: "cms-component-checkout" */ '~/../../node_modules/@viskan/cms-component-checkout-customer-submit'));
import cmsComponentCheckoutCustomerSubmitConfig from '~/../../node_modules/@viskan/cms-component-checkout-customer-submit/dist/config';
const cmsComponentCheckoutDiscountCode = loadable(() => import(/* webpackChunkName: "cms-component-checkout" */ '~/../../node_modules/@viskan/cms-component-checkout-discount-code'));
import cmsComponentCheckoutDiscountCodeConfig from '~/../../node_modules/@viskan/cms-component-checkout-discount-code/dist/config';
const cmsComponentCheckoutFreeShippingText = loadable(() => import(/* webpackChunkName: "cms-component-checkout" */ '~/../../node_modules/@viskan/cms-component-checkout-free-shipping-text'));
import cmsComponentCheckoutFreeShippingTextConfig from '~/../../node_modules/@viskan/cms-component-checkout-free-shipping-text/dist/config';
const cmsComponentCheckoutIntegrities = loadable(() => import(/* webpackChunkName: "cms-component-checkout" */ '~/../../node_modules/@viskan/cms-component-checkout-integrities'));
import cmsComponentCheckoutIntegritiesConfig from '~/../../node_modules/@viskan/cms-component-checkout-integrities/dist/config';
const cmsComponentCheckoutSelectCountry = loadable(() => import(/* webpackChunkName: "cms-component-checkout" */ '~/../../node_modules/@viskan/cms-component-checkout-select-country'));
import cmsComponentCheckoutSelectCountryConfig from '~/../../node_modules/@viskan/cms-component-checkout-select-country/dist/config';
const cmsComponentCheckoutOrderAdditionalText = loadable(() => import(/* webpackChunkName: "cms-component-checkout" */ '~/../../node_modules/@viskan/cms-component-checkout-order-additional-text'));
import cmsComponentCheckoutOrderAdditionalTextConfig from '~/../../node_modules/@viskan/cms-component-checkout-order-additional-text/dist/config';
const cmsComponentQuickCart = loadable(() => import('~/../../node_modules/@viskan/cms-component-quick-cart'));
import cmsComponentQuickCartConfig from '~/../../node_modules/@viskan/cms-component-quick-cart/dist/config';
const cmsComponentShopcartSaveForm = loadable(() => import(/* webpackChunkName: "cms-component-shopcart" */ '~/../../node_modules/@viskan/cms-component-shopcart-save-form'));
import cmsComponentShopcartSaveFormConfig from '~/../../node_modules/@viskan/cms-component-shopcart-save-form/dist/config';
const cmsComponentValueFromState = loadable(() => import('~/../../node_modules/@viskan/cms-component-value-from-state'));
import cmsComponentValueFromStateConfig from '~/../../node_modules/@viskan/cms-component-value-from-state/dist/config';
const cmsComponentShowMore = loadable(() => import('~/../../node_modules/@viskan/cms-component-show-more'));
import cmsComponentShowMoreConfig from '~/../../node_modules/@viskan/cms-component-show-more/dist/config';
const cmsComponentCountdown = loadable(() => import('~/../../node_modules/@viskan/cms-component-countdown'));
import cmsComponentCountdownConfig from '~/../../node_modules/@viskan/cms-component-countdown/dist/config';

export default {
	ARTICLE_MATRIX: {
		component: cmsComponentArticleMatrix,
		config: cmsComponentArticleMatrixConfig
	},
	ARTICLE_PACKAGE: {
		component: cmsComponentArticlePackage,
		config: cmsComponentArticlePackageConfig
	},
	ARTICLES_COLUMN_SIZE: {
		component: cmsComponentArticlesColumnSize,
		config: cmsComponentArticlesColumnSizeConfig
	},
	ARTICLES_VIEW: {
		component: cmsComponentArticlesView,
		config: cmsComponentArticlesViewConfig
	},
	BLURB_01: {
		component: cmsComponentBlurb1,
		config: cmsComponentBlurb1Config
	},
	BLURB_02: {
		component: cmsComponentBlurb2,
		config: cmsComponentBlurb2Config
	},
	BLURB_03: {
		component: cmsComponentBlurb3,
		config: cmsComponentBlurb3Config
	},
	BLURB_04: {
		component: cmsComponentBlurb4,
		config: cmsComponentBlurb4Config
	},
	BLURB_05: {
		component: cmsComponentBlurb5,
		config: cmsComponentBlurb5Config
	},
	BLURB_06: {
		component: cmsComponentBlurb6,
		config: cmsComponentBlurb6Config
	},
	BLURB_CUSTOM_CONTENT: {
		component: cmsComponentBlurbCustomContent,
		config: cmsComponentBlurbCustomContentConfig
	},
	BREADCRUMBS: {
		component: cmsComponentBreadcrumbs,
		config: cmsComponentBreadcrumbsConfig
	},
	BUTTON_01: {
		component: cmsComponentButton,
		config: cmsComponentButtonConfig
	},
	LOCALE: {
		component: cmsComponentLocale,
		config: cmsComponentLocaleConfig
	},
	CATEGORY_IMPORT: {
		component: cmsComponentCategoryImport,
		config: cmsComponentCategoryImportConfig
	},
	B2B_B2C: {
		component: cmsComponentB2BB2C,
		config: cmsComponentB2BB2CConfig
	},
	ARTICLES_SORT: {
		component: cmsComponentArticlesSort,
		config: cmsComponentArticlesSortConfig
	},
	CELL_01: {
		component: cmsComponentCell,
		config: cmsComponentCellConfig
	},
	CONTAINER_01: {
		component: cmsComponentContainer,
		config: cmsComponentContainerConfig
	},
	COOKIEBAR_01: {
		component: cmsComponentCookiebar,
		config: cmsComponentCookiebarConfig
	},
	DROPDOWN: {
		component: cmsComponentDropdown,
		config: cmsComponentDropdownConfig
	},
	DYNAMIC_CELL: {
		component: cmsComponentDynamicCell,
		config: cmsComponentDynamicCellConfig
	},
	DYNAMIC_GRID: {
		component: cmsComponentDynamicGrid,
		config: cmsComponentDynamicGridConfig
	},
	GOOGLE_MAPS: {
		component: cmsComponentGoogleMaps,
		config: cmsComponentGoogleMapsConfig
	},
	GRID_01: {
		component: cmsComponentGrid,
		config: cmsComponentGridConfig
	},
	HTML_ADVANCED: {
		component: cmsComponentHtmlAdvanced,
		config: cmsComponentHtmlAdvancedConfig
	},
	ICON: {
		component: cmsComponentIcon,
		config: cmsComponentIconConfig
	},
	LIST: {
		component: cmsComponentList,
		config: cmsComponentListConfig
	},
	MAIN_NAVIGATION: {
		component: cmsComponentMainNavigation,
		config: cmsComponentMainNavigationConfig
	},
	MEDIA_01: {
		component: cmsComponentMedia,
		config: cmsComponentMediaConfig
	},
	MESSAGE_FORM: {
		component: cmsComponentMessageForm,
		config: cmsComponentMessageFormConfig
	},
	MESSAGE_FORM_FIELD: {
		component: cmsComponentMessageFormField,
		config: cmsComponentMessageFormFieldConfig
	},
	MY_PAGES_AUTHENTICATE: {
		component: cmsComponentMyPagesAuthenticate,
		config: cmsComponentMyPagesAuthenticateConfig
	},
	MY_PAGES_LOGOUT: {
		component: cmsComponentMyPagesLogout,
		config: cmsComponentMyPagesLogoutConfig
	},
	MY_PAGES_OFFERS: {
		component: cmsComponentMyPagesOffers,
		config: cmsComponentMyPagesOffersConfig
	},
	MY_PAGES_ORDERS: {
		component: cmsComponentMyPagesOrders,
		config: cmsComponentMyPagesOrdersConfig
	},
	MY_PAGES_PROFILE: {
		component: cmsComponentMyPagesProfile,
		config: cmsComponentMyPagesProfileConfig
	},
	MY_PAGES_RESET_PASSWORD: {
		component: cmsComponentMyPagesResetPassword,
		config: cmsComponentMyPagesResetPasswordConfig
	},
	MY_PAGES_SUBSCRIPTIONS: {
		component: cmsComponentMyPagesSubscriptions,
		config: cmsComponentMyPagesSubscriptionsConfig
	},
	NEWSLETTER_FORM: {
		component: cmsComponentNewsletterForm,
		config: cmsComponentNewsletterFormConfig
	},
	NOTICE: {
		component: cmsComponentNotice,
		config: cmsComponentNoticeConfig
	},
	POPUP: {
		component: cmsComponentPopup,
		config: cmsComponentPopupConfig
	},
	SCRIPT_01: {
		component: cmsComponentScript,
		config: cmsComponentScriptConfig
	},
	SCROLL_TO_TOP: {
		component: cmsComponentScrollToTop,
		config: cmsComponentScrollToTopConfig
	},
	SHARE_BUTTONS: {
		component: cmsComponentShareButtons,
		config: cmsComponentShareButtonsConfig
	},
	SHOP_THE_LOOK: {
		component: cmsComponentShopTheLook,
		config: cmsComponentShopTheLookConfig
	},
	SKU_TABLE: {
		component: cmsComponentSkuTable,
		config: cmsComponentSkuTableConfig
	},
	SLIDEOUT_01: {
		component: cmsComponentSlideout,
		config: cmsComponentSlideoutConfig
	},
	SLIDER_01: {
		component: cmsComponentSlider,
		config: cmsComponentSliderConfig
	},
	STICKY_HEADER: {
		component: cmsComponentStickyHeader,
		config: cmsComponentStickyHeaderConfig
	},
	SUB_MENU: {
		component: cmsComponentSubMenu,
		config: cmsComponentSubMenuConfig
	},
	SUB_MENU_DROPDOWN: {
		component: cmsComponentSubMenuDropdown,
		config: cmsComponentSubMenuDropdownConfig
	},
	SUB_MENU_ITEM: {
		component: cmsComponentSubMenuItem,
		config: cmsComponentSubMenuItemConfig
	},
	TAB: {
		component: cmsComponentTab,
		config: cmsComponentTabConfig
	},
	TABS_CONTAINER: {
		component: cmsComponentTabsContainer,
		config: cmsComponentTabsContainerConfig
	},
	TEXT: {
		component: cmsComponentText,
		config: cmsComponentTextConfig
	},
	TOGGLER: {
		component: cmsComponentToggler,
		config: cmsComponentTogglerConfig
	},
	READ_TOGGLER: {
		component: cmsComponentReadToggler,
		config: cmsComponentReadTogglerConfig
	},
	TRUSTPILOT: {
		component: cmsComponentTrustpilot,
		config: cmsComponentTrustpilotConfig
	},
	VIDEO_01: {
		component: cmsComponentVideo,
		config: cmsComponentVideoConfig
	},
	CHECKOUT: {
		component: cmsComponentCheckout,
		config: cmsComponentCheckoutConfig
	},
	CHECKOUT_WITH_AUTHENTICATION: {
		component: cmsComponentCheckoutWithAuthentication,
		config: cmsComponentCheckoutWithAuthenticationConfig
	},
	CHECKOUT_EMBEDDED: {
		component: cmsComponentCheckoutEmbedded,
		config: cmsComponentCheckoutEmbeddedConfig
	},
	CHECKOUT_SHIPPING: {
		component: cmsComponentCheckoutShipping,
		config: cmsComponentCheckoutShippingConfig
	},
	CHECKOUT_PAYMENT_METHODS: {
		component: cmsComponentCheckoutPayment,
		config: cmsComponentCheckoutPaymentConfig
	},
	CUSTOMER_FORM: {
		component: cmsComponentCheckoutCustomerForm,
		config: cmsComponentCheckoutCustomerFormConfig
	},
	CUSTOMER_LOOKUP: {
		component: cmsComponentCheckoutCustomerLookup,
		config: cmsComponentCheckoutCustomerLookupConfig
	},
	CUSTOMER_SUBMIT: {
		component: cmsComponentCheckoutCustomerSubmit,
		config: cmsComponentCheckoutCustomerSubmitConfig
	},
	DISCOUNT_CODE: {
		component: cmsComponentCheckoutDiscountCode,
		config: cmsComponentCheckoutDiscountCodeConfig
	},
	CHECKOUT_FREE_SHIPPING_TEXT: {
		component: cmsComponentCheckoutFreeShippingText,
		config: cmsComponentCheckoutFreeShippingTextConfig
	},
	INTEGRITIES: {
		component: cmsComponentCheckoutIntegrities,
		config: cmsComponentCheckoutIntegritiesConfig
	},
	CHANGE_COUNTRY: {
		component: cmsComponentCheckoutSelectCountry,
		config: cmsComponentCheckoutSelectCountryConfig
	},
	CHECKOUT_ADDITIONAL_ORDER_TEXT: {
		component: cmsComponentCheckoutOrderAdditionalText,
		config: cmsComponentCheckoutOrderAdditionalTextConfig
	},
	QUICK_CART: {
		component: cmsComponentQuickCart,
		config: cmsComponentQuickCartConfig
	},
	SHOPCART_SAVE_FORM: {
		component: cmsComponentShopcartSaveForm,
		config: cmsComponentShopcartSaveFormConfig
	},
	VALUE_FROM_STATE: {
		component: cmsComponentValueFromState,
		config: cmsComponentValueFromStateConfig
	},
	SHOW_MORE: {
		component: cmsComponentShowMore,
		config: cmsComponentShowMoreConfig
	},
	COUNTDOWN: {
		component: cmsComponentCountdown,
		config: cmsComponentCountdownConfig
	}
};