import page from 'page';

export const getErrorCode = body => (typeof body === 'object' && body.errors && body.errors.length > 0 && body.errors[0].errorCode) || 'unknown';
export const handleError = (error, setState) => setState({errorCode: getErrorCode(error && error.response && error.response.body)});

export const handleCancel = (purchaseNumber, pathPrefix, checkoutUrl) => () => {
	if (purchaseNumber) {
		page(`${pathPrefix}purchase/${purchaseNumber}/cancel`);
		return;
	}

	page(`${pathPrefix}${checkoutUrl}`);
};
