import pathToRegexp from 'path-to-regexp';
import loadable from '@viskan/deku-loadable';
import Category from '~/category';
import {PurchaseCancel, PurchaseExternal} from '~/purchase';

const Article = loadable(() => import('~/article'));
const Logout = loadable(() => import('~/logout'));
const Receipt = loadable(() => import('~/receipt'));
const Empty = loadable(() => import('~/empty'));

const getRouteMatch = (test, route, callback) => {
	const regex = pathToRegexp(route);
	const match = regex.exec(test);

	if (match) {
		return typeof callback === 'function' ? callback(match.slice(1)) : callback;
	}
};

const renderByPath = props => ([
	getRouteMatch(props.router.canonicalPathname, '/language-redirect(.*)', () => <Empty/>),
	getRouteMatch(props.router.pathname, '/receipt/:purchaseNumber', ([purchaseNumber]) => <Receipt purchaseNumber={purchaseNumber}/>),
	getRouteMatch(props.router.pathname, `/${props.site.articleUrlPrefix}/:slug(.*)`, ([slug]) => <Article query={slug} sku={props.router.query}/>),
	getRouteMatch(props.router.pathname, '/purchase/:purchaseNumber/cancel', ([purchaseNumber]) => <PurchaseCancel purchaseNumber={purchaseNumber}/>),
	getRouteMatch(props.router.pathname, '/purchase/external/:paymentMethodId/:shopcartId', ([paymentMethodId, shopcartId]) => <PurchaseExternal {...props.router.query} paymentMethodId={paymentMethodId} shopcartId={shopcartId}/>),
	getRouteMatch(props.router.pathname, /^\/logout(.*)$/, ([redirect]) => <Logout pathPrefix={props.router.pathPrefix} redirect={redirect}/>),
	getRouteMatch(props.router.pathname, '/:slug(.*)', ([slug]) => <Category query={slug || '/'}/>)
]).find(Boolean);

const render = ({props}) => (
	<div class={['RouterView', props.class]}>
		{renderByPath(props)}
	</div>
);

export default {render};
