import {createAction} from 'redux-actions';
import {
	ARTICLE_ACTIVE_SUBSCRIPTION,
	ARTICLE_ADDON_ADD,
	ARTICLE_ADDON_REMOVE,
	ARTICLE_ADDON_SELECT_REMOVE,
	ARTICLE_FETCH,
	ARTICLE_QUANTITY_SET,
	ARTICLE_RESET,
	ARTICLE_SKU_SET,
	ARTICLE_ATTRIBUTE_TRIGGER_SLIDEOUT
} from '../action-types';

export const addAddon = createAction(ARTICLE_ADDON_ADD);
export const fetchArticle = createAction(ARTICLE_FETCH, (query, sku, opts) => v12.article.get(query, opts), (query, sku) => ({sku}));
export const removeAddon = createAction(ARTICLE_ADDON_REMOVE);
export const removeSelectAddon = createAction(ARTICLE_ADDON_SELECT_REMOVE);
export const resetArticle = createAction(ARTICLE_RESET);
export const setActiveSubscription = createAction(ARTICLE_ACTIVE_SUBSCRIPTION, (subscription, isSinglePurchase, activeArticle) => ({subscription, isSinglePurchase, activeArticle}));
export const setQuantity = createAction(ARTICLE_QUANTITY_SET, quantity => parseInt(quantity, 10));
export const setSku = createAction(ARTICLE_SKU_SET, (article, sku, options) => v12.article.sku(article, sku, options));
export const triggerInStoreSlideout = createAction(ARTICLE_ATTRIBUTE_TRIGGER_SLIDEOUT);
