import {deepEqual} from 'fast-equals';

const shouldUpdate = ({props}, nextProps) => {
	return props.article.attr1_id !== nextProps.article.attr1_id ||
		!deepEqual(props.sizeListOptions, nextProps.sizeListOptions) ||
		!deepEqual(props.styleClasses, nextProps.styleClasses);
};

const renderSizes = props => {
	const skusBasedOnActiveAttr1Id = props.article.articleAttributes.filter(sku => props.sizeListOptions.sizeListOnlyShowInStock ? sku.inStock && sku.attribute1.attr1_id === props.article.attr1_id : sku.attribute1.attr1_id === props.article.attr1_id);
	const nonDuplicateAttr2Ids = Array.from(new Set(skusBasedOnActiveAttr1Id.map(sku => sku.attr2_id))).map(id => skusBasedOnActiveAttr1Id.find(sku => sku.attr2_id === id)).filter(article => article.attr2_id !== 0);
	const sizeListMaxNumberOfSizes = parseInt(props.sizeListOptions.sizeListMaxNumberOfSizes, 10);
	const amountOfHiddenAttr2Ids = nonDuplicateAttr2Ids.length - sizeListMaxNumberOfSizes;
	if (sizeListMaxNumberOfSizes === 0 || nonDuplicateAttr2Ids.length === 0) {
		return <noscript/>;
	}

	const items = nonDuplicateAttr2Ids.slice(0, sizeListMaxNumberOfSizes).map(attribute => {
		return (
			<li key={attribute.attribute2.attr2_id} class={['SizeList-item', props.styleClasses.SizeListItem]} data-style-attr='SizeListItem'>{attribute.attribute2.attr2_code}</li>
		);
	});

	const rest = <li key={`rest-${amountOfHiddenAttr2Ids}`} class={['SizeList-rest', props.styleClasses.SizeListRest]} data-style-attr='SizeListRest'>+{amountOfHiddenAttr2Ids}</li>;

	return amountOfHiddenAttr2Ids > 0 ? [...items, rest] : items;
};

const render = ({props}) => {
	return (
		<ul class={['SizeList', props.styleClasses.SizeList]} data-style-attr='SizeList'>
			{renderSizes(props)}
		</ul>
	);
};

export default {render, shouldUpdate};
