export const PAGINATION_TYPE_INFINITE_SCROLL = 'infiniteScroll';
export const PAGINATION_TYPE_LOAD_MORE_AND_PREVIOUS_BUTTON = 'loadMoreAndPreviousButton';
export const PAGINATION_TYPE_PAGINATION = 'pagination';
export const STOCK_IN_STOCK = 'inStock';
export const STOCK_OUT_OF_STOCK = 'outOfStock';
export const STOCK_INCOMING_IN_STOCK = 'incomingInStock';

export const QUICKBUY_NEVER = 'never';
export const QUICKBUY_ALWAYS = 'always';
export const QUICKBUY_MULTIPLEVARIANTS = 'multipleVariants';
