import {Section} from '../../streamline-cms';

const beforeMount = ({props}) => props.categoryFetch('404/', 'main', {dontRedirect: true});

const render = () => (
	<div class='Container NotFound'>
		<section>
			<Section sectionId='main'/>
		</section>
	</div>
);

export default {beforeMount, render};
