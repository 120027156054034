import loadable from '@viskan/deku-loadable';
import LIPSCORE_WIDGET_CONFIG from './customer-unique/lipscore-widget/config';
import SUBSCRIPTION_INFORMATION_CONFIG from './customer-unique/subscription-information/config';

const LIPSCORE_WIDGET = loadable(() => import('./customer-unique/lipscore-widget'));
const SUBSCRIPTION_INFORMATION = loadable(() => import(/* webpackChunkName: "cms-component-checkout" */ './customer-unique/subscription-information'));

export default {
	LIPSCORE_WIDGET: {
		component: LIPSCORE_WIDGET,
		config: LIPSCORE_WIDGET_CONFIG
	},
	SUBSCRIPTION_INFORMATION: {
		component: SUBSCRIPTION_INFORMATION,
		config: SUBSCRIPTION_INFORMATION_CONFIG
	}
};
