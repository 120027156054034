import {CUSTOMER_FETCH_FULFILLED, CUSTOMER_PATCH, CUSTOMER_PATCH_FULFILLED, SET_CLIENT_ID} from '../action-types';
import {isPerson} from '../actions';

const initialState = {
	...window._streamline.customerOnLoadStatus,
	person_flg: isPerson()
};

export default (state = initialState, action) => {
	switch (action.type) {
		case CUSTOMER_PATCH:
		case CUSTOMER_PATCH_FULFILLED: {
			return {
				...state,
				...action.payload
			};
		}
		case CUSTOMER_FETCH_FULFILLED: {
			if (!action.payload.logged_in) {
				return {
					...action.payload
				};
			}

			return {
				...state,
				...action.payload
			};
		}
		case SET_CLIENT_ID: {
			return {
				...state,
				analyticsClientId: action.payload
			};
		}
		default: {
			return state;
		}
	}
};
