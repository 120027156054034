import {deepEqual} from 'fast-equals';
import {getPriceDiscountInPercent} from '~/utils';

const propTypes = {
	class: {
		type: 'string'
	},
	data: {
		type: 'object'
	},
	getPrice: {
		source: 'getPrice'
	},
	getTranslation: {
		source: 'getTranslation'
	},
	sku: {
		type: 'object'
	}
};

const shouldUpdate = ({props}, nextProps) => !deepEqual(props, nextProps);

const render = ({props}) => {
	const {data, getPrice, getTranslation, showPriceForCurrentSku, showFromLabel, sku} = props;
	const styleClasses = props.styleClasses || {};
	const price = showPriceForCurrentSku ? v12.article.price(data, sku) : v12.article.price(data, null, {...(sku && sku.attr1_id && {attr1_id: sku.attr1_id})});
	const isSale = price.price_sales < price.price_org;
	const showRecPrice = props.showRecPrice && price.price_rec !== 0;
	const discountPercent = isSale && getPriceDiscountInPercent({
		sales: price.price_sales,
		salesExvat: price.price_sales_exvat,
		org: price.price_org,
		orgExvat: price.price_org_exvat
	});

	const priceAttributes = {
		class: {
			Price: true,
			'Price--sale': isSale,
			[styleClasses.Price]: !isSale && styleClasses.Price,
			[styleClasses.Price_Sale]: isSale && styleClasses.Price_Sale
		},
		'data-style-attr': isSale ? 'Price_Sale' : 'Price'
	};

	return (
		<div class={['Prices', props.class, styleClasses.Prices]} data-style-attr='Prices'>
			{showFromLabel && !showPriceForCurrentSku && price.more && <span class={['Price-fromLabel', styleClasses.Price_From_Label]} data-style-attr='Price_From_Label'>{getTranslation('article.price_from_label')}</span>}
			<span {...priceAttributes}>{getPrice(price.price_sales, price.price_sales_exvat)}</span>
			{isSale && <span class={['Price Price--org', styleClasses.Price_Original]} data-style-attr='Price_Original'>{getPrice(price.price_org, price.price_org_exvat)}</span>}
			{showRecPrice && (
				<span class={['Price--recWrapper', styleClasses.Price_Rec_Wrapper]} data-style-attr='Price_Rec_Wrapper'>
					<span class={['CMS-ArticlePrice-price--recLabel', props.styleClasses.Price_Rec_Label]} data-style-attr='Price_Rec_Label'>{props.recPriceLabel}</span>
					<span class={['Price--rec', styleClasses.Price_Rec]} data-style-attr='Price_Rec'> {getPrice(price.price_rec, price.price_rec_exvat)}</span>
				</span>
			)}
			{isSale && props.showDiscountPercent && <span class={['Price Price--discountPercent', styleClasses.Price_Discount_Percent]} data-style-attr='Price_Discount_Percent'>{discountPercent}%</span>}
		</div>
	);
};

export default {propTypes, render, shouldUpdate};
