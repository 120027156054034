import bus from '@viskan/bus';
import store from 'store2';
import cookie from 'component-cookie';
import page from 'page';
import {patch as customerPatch} from '../customer/actions';

export const openPopup = (data, opts) => bus.emit('popup:open', data, opts);
export const closePopup = () => bus.emit('popup:close');
export const isPerson = () => store.session('vs-person_flg') !== null ? store.session('vs-person_flg') : true;

export const validationError = (str, getTranslation) => (validity, el, rule) => {
	const errors = [];
	const rules = [
		'matches',
		'differs',
		'exactLength',
		'greaterThan',
		'greaterThanEqual',
		'lowerThan',
		'lowerThanEqual'
	];

	for (const x in el.validity) {
		if (el.validity[x] && x !== 'valid') {
			if (x === 'valueMissing') {
				errors.unshift(getTranslation(`validation.${x}`, {field: str}));
				break;
			}
			if (rule && rules.includes(rule.rule)) {
				errors.push(getTranslation(`validation.${rule.rule}`, {field: str, msg: rule.msg}));
				break;
			}

			if (rule) {
				errors.push(getTranslation(`validation.${rule.rule}`, {field: str}));
				break;
			}

			errors.push(getTranslation(`validation.${x}`, {field: str}));
		}
	}

	return errors[0];
};

export const languageRedirect = {
	handleBadResponse: () => {
		store.remove('vs-languageRedirect');

		if (window.location.pathname !== '/') {
			page('/');
		}
	},
	handleRedirect: async ({pathname, querystring, site, pathPrefix = undefined}) => {
		/**
		 * PathPrefix is only passed from locale component,
		 * because we don't set locale cookie before after we enter the site
		 */
		const locale = cookie('locale') || pathPrefix;

		if (!locale) {
			store('vs-languageRedirect', {pathname, querystring});
			page('/');

			return;
		}

		try {
			const response = await v12.util.http(`/api/navigation/path-info/${pathname}`);

			const {body} = response;

			if (!body) {
				languageRedirect.handleBadResponse();
			}

			if (body && body.languageAlternatives) {
				const {targetType} = body;
				const redirectTarget = body.languageAlternatives.find(alternative => alternative.languageCode === locale.substring(0, 2));

				store.remove('vs-languageRedirect');

				if (targetType === 'CATEGORY') {
					page(`/${locale}${redirectTarget.path}`);
				}

				if (targetType === 'ARTICLE') {
					if (querystring !== '') {
						page(`/${locale}/${site.articleUrlPrefix}${redirectTarget.path}?${querystring}`);
					} else {
						page(`/${locale}/${site.articleUrlPrefix}${redirectTarget.path}`);
					}
				}
			}
		} catch (error) {
			languageRedirect.handleBadResponse();
		}
	}
};

export default app => {
	app.set('isPerson', isPerson);
	app.set('validationError', validationError);
	app.set('openPopup', openPopup);
	app.set('closePopup', closePopup);
	app.set('customerPatch', customerPatch);
	app.set('languageRedirect', languageRedirect);
};
