import counterpart from 'counterpart';
import {createSelector} from 'reselect';

const defaultTranslation = 'en';
const translations = {
	da: require('../../locale/da.json'),
	de: require('../../locale/de.json'),
	en: require('../../locale/en.json'),
	fi: require('../../locale/fi.json'),
	no: require('../../locale/no.json'),
	sv: require('../../locale/sv.json'),
	nl: require('../../locale/nl.json'), // Customer unique
	it: require('../../locale/it.json'), // Customer unique
	es: require('../../locale/es.json'), // Customer unique
	fr: require('../../locale/fr.json'), // Customer unique
	pl: require('../../locale/pl.json') // Customer unique
};

const extendedTranslations = {
	da: require('../../locale/extends/da.json'),
	de: require('../../locale/extends/de.json'),
	en: require('../../locale/extends/en.json'),
	fi: require('../../locale/extends/fi.json'),
	no: require('../../locale/extends/no.json'),
	sv: require('../../locale/extends/sv.json'),
	nl: require('../../locale/extends/nl.json'), // Customer unique
	it: require('../../locale/extends/it.json'), // Customer unique
	es: require('../../locale/extends/es.json'), // Customer unique
	fr: require('../../locale/extends/fr.json'), // Customer unique
	pl: require('../../locale/extends/pl.json') // Customer unique
};

const instances = {};
const pluralize = (entry, count) => entry[count === 0 && 'zero' in entry ? 'zero' : count === 1 ? 'one' : 'other'];

const convertDotNotationToObject = obj => {
	const result = {};

	for (const objectPath in obj) {
		if (objectPath) {
			const parts = objectPath.split('.');

			let target = result;
			while (parts.length > 1) {
				const part = parts.shift();
				/* eslint no-multi-assign: */
				target = target[part] = target[part] || {};
			}

			target[parts[0]] = obj[objectPath];
		}
	}

	return result;
};

const translator = site => {
	const {lang_code_web} = site;
	const storeTranslations = convertDotNotationToObject(window._streamline.translations);

	if (!instances[lang_code_web]) {
		const instance = new counterpart.Instance();

		instance.registerTranslations(lang_code_web, {counterpart: {pluralize}});
		instance.registerTranslations(lang_code_web, translations[lang_code_web]);
		instance.registerTranslations(lang_code_web, extendedTranslations[lang_code_web]);
		instance.registerTranslations(lang_code_web, storeTranslations);
		instance.setLocale(lang_code_web);
		instance.onTranslationNotFound((locale, key) => console.warn(`missing translation: ${locale}.${key}`));

		if (lang_code_web !== 'en') {
			instance.registerTranslations(defaultTranslation, translations[defaultTranslation]);
			instance.registerTranslations(defaultTranslation, extendedTranslations[defaultTranslation]);
			instance.setFallbackLocale(defaultTranslation);
		}

		instances[lang_code_web] = (key, options) => instance.translate(key, options);
	}

	return instances[lang_code_web];
};

export const getTranslator = createSelector(
	[state => state.site],
	site => translator(site)
);

export default translator;
