// Customer specific file to get hideWhenNoLowerPrice and hideWhenLowerPrice. Remove this file when bumping.

export default `
	<Tabs>
		<Tab heading="General">
			<Grid>
				<Cell size="full">
					<Field name="field"/>
				</Cell>
				<Cell size="full">
					<Field name="scale"/>
				</Cell>
				<Cell size="full">
					<Field name="elementType"/>
				</Cell>
				<Cell size="full">
					<Field name="missingBalanceSkusText"/>
				</Cell>
				<Cell size="full">
					<Field name="prefixText"/>
				</Cell>
				<Cell size="full">
					<Field name="suffixText"/>
				</Cell>
				<Cell size="full">
					<Field name="linkWrap"/>
				</Cell>
				<Cell size="full">
					<Field name="usePromotionalOffers"/>
				</Cell>
				<Cell size="full">
					<Field name="hideAttrValueZero"/>
				</Cell>
				<Cell size="full">
					<Field name="hideWhenNoValue"/>
				</Cell>
				<Cell size="full">
					<Field name="hideWhenNoDiscount"/>
				</Cell>
				<Cell size="full">
					<Field name="hideWhenNoLowerPrice"/>
				</Cell>
				<Cell size="full">
					<Field name="hideWhenLowerPrice"/>
				</Cell>
				<Cell size="1of2">
					<Field name="showAddonPrice"/>
				</Cell>
				<Cell size="1of2">
					<Field name="hideAddonPriceWhenZero"/>
				</Cell>
				<Cell size="1of2">
					<Field name="showAddonAttr1"/>
				</Cell>
				<Cell size="1of2">
					<Field name="attribute1Prefix"/>
				</Cell>
				<Cell size="1of2">
					<Field name="showAddonAttr2"/>
				</Cell>
				<Cell size="1of2">
					<Field name="attribute2Prefix"/>
				</Cell>
				<Cell size="1of2">
					<Field name="showAddonAttr3"/>
				</Cell>
				<Cell size="1of2">
					<Field name="attribute3Prefix"/>
				</Cell>
				<Cell size="1of2">
					<Field name="showAddonPersonalizationText"/>
				</Cell>
				<Cell size="1of2">
					<Field name="personalizationTextPrefix"/>
				</Cell>
				<Cell size="1of2">
					<Field name="showMissingBalanceSkusAddons"/>
				</Cell>
				<Cell size="1of2">
					<Field name="missingBalanceSkusAddonsText"/>
				</Cell>
			</Grid>
		</Tab>
		<Tab heading="Advanced">
			<Grid>
				<Cell size="full">
					<Field name="class"/>
				</Cell>
			</Grid>
		</Tab>
	</Tabs>
`;
