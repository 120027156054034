const removeMetaTags = tagSelector => {
	const tags = document.head.querySelectorAll(tagSelector);
	tags.forEach(tag => {
		tag.remove();
	});
};

export const setCanonical = () => {
	const canonical = document.querySelector('link[rel="canonical"]');
	const element = document.querySelector('meta[property="og:url"]');

	if (canonical) {
		canonical.setAttribute('href', window.location.href.replace(window.location.search, ''));
	}

	if (element) {
		element.setAttribute('content', window.location.href.replace(window.location.search, ''));
	}
};

export const setDescription = (string = '') => {
	const element = document.querySelector('meta[name="description"]');
	const ogDescElement = document.querySelector('meta[property="og:description"]');

	if (element) {
		element.setAttribute('content', string);
	}

	if (ogDescElement) {
		ogDescElement.setAttribute('content', string);
	}
};

export const setContentImages = content => {
	removeMetaTags('[property="og:image"]');

	if (content.length === 0) {
		return;
	}

	const regex = /"(?:path|imgSrc)":"([^"]+)"/g;
	const images = content.match(regex);

	if (images) {
		images.forEach(image => {
			const img = JSON.parse(`{${image}}`);
			const mediaUrl = img.path ? `${window._streamline.siteContext.mediaConfig.mediaServiceBaseURL}/original/${img.path}` : `${window._streamline.siteContext.mediaConfig.mediaServiceBaseURL}/original/${img.imgSrc}`;
			const element = document.createElement('meta');
			element.setAttribute('property', 'og:image');
			element.setAttribute('content', mediaUrl);
			document.getElementsByTagName('head')[0].appendChild(element);
		});
	}
};

export const setProductImages = images => {
	removeMetaTags('[property="og:image"]');

	if (images.length === 0) {
		return;
	}

	images.forEach(image => {
		const mediaUrl = `${window._streamline.siteContext.mediaConfig.mediaServiceBaseURL}/original/${image.media.filename}`;
		const element = document.createElement('meta');
		element.setAttribute('property', 'og:image');
		element.setAttribute('content', mediaUrl);
		document.getElementsByTagName('head')[0].appendChild(element);
	});
};

export const setTitle = (str, addDash = true, addCompanyName = true) => {
	const element = document.querySelector('meta[property="og:title"]');

	if (!str) {
		return;
	}

	if (element && !addDash && addCompanyName) {
		element.setAttribute('content', `${str} ${window._streamline.siteContext.companyName}`);
		document.title = `${str} ${window._streamline.siteContext.companyName}`;

		return;
	}

	if (element && (!addCompanyName || !window._streamline.siteContext.companyName)) {
		element.setAttribute('content', str);
		document.title = str;

		return;
	}

	if (element) {
		element.setAttribute('content', `${str} - ${window._streamline.siteContext.companyName}`);
	}

	document.title = `${str} - ${window._streamline.siteContext.companyName}`;
};

export const setType = ({pageType}) => {
	const element = document.querySelector('meta[property="og:type"]');

	if (pageType) {
		element.setAttribute('content', pageType);
		return;
	}

	element.setAttribute('content', 'website');
};
