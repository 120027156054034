import store from 'store2';
import {BREAKPOINT_SET, BREAKPOINT_OVERRIDE_SET} from '../action-types';
import {BREAKPOINT_ELEMENT_SELECTOR, getStylePropertyValue} from './components/handler';

const getInitialBreakpoint = () => {
	const breakpointValueEl = document.querySelector(BREAKPOINT_ELEMENT_SELECTOR);
	return getStylePropertyValue(breakpointValueEl) || 'sm';
};

const initialState = {
	breakpoint: getInitialBreakpoint(),
	breakpointOverride: (window._streamline.siteContext.cmsTokenSet && store.session('vs-cms-breakpointOverride')) || null
};

export default (state = initialState, action) => {
	switch (action.type) {
		case BREAKPOINT_SET: {
			return {
				...state,
				breakpoint: action.payload
			};
		}
		case BREAKPOINT_OVERRIDE_SET: {
			store.session('vs-cms-breakpointOverride', action.payload);

			return {
				...state,
				breakpointOverride: action.payload
			};
		}
		default: {
			return state;
		}
	}
};
