import {connect} from '@viskan/deku-redux';
import {url} from '@viskan/streamline-utilities';
import bus from '@viskan/bus';
import page from 'page';
import {getQueryString} from '~/utils';
import {languageRedirect} from '~/actions';
import {trackPageView} from '../analytics';
import {pathChange} from './actions';
import ViewComponent from './components/view';

export const View = connect(
	({router, site}) => ({
		router,
		site
	})
)(ViewComponent);

window.addEventListener('error', error => (error.error instanceof ReferenceError || error.error instanceof TypeError) && page.stop());

const isLocaleChange = (state, context) => {
	const localePath = url.resolveLocalePath(context.path, state.site.localeMode);

	return state.router.changes > 0 && state.router.localePathPrefixInUse !== localePath.prefix;
};

export default app => {
	page('*', context => {
		const state = app.sources.store.getState();

		context.query = getQueryString(context.canonicalPath);

		trackPageView(context.path);
		bus.emit('slideouts:close');

		const isLanguageRedirect = context.pathname.includes('/language-redirect/');
		if (isLanguageRedirect && app.sources.site.localeMode !== 'DOMAIN_ONLY') {
			const pathName = context.pathname.match(/\/language-redirect\/(.*)/)[1];
			const queryString = context.querystring;

			languageRedirect.handleRedirect({pathname: pathName, site: app.sources.site, querystring: queryString});
			return;
		}

		if (state.router.canonicalPath !== context.canonicalPath) {
			if (isLocaleChange(state, context)) {
				return window.location.replace(context.canonicalPath);
			}

			app.sources.store.dispatch(pathChange({
				context,
				site: state.site
			}));
		}
	});
	page();
};
