import {
	ARTICLES_FAVORITES_ADD_FULFILLED,
	ARTICLES_FAVORITES_ADD_PENDING,
	ARTICLES_FAVORITES_ADD_REJECTED,
	ARTICLES_FAVORITES_FETCH_FULFILLED,
	ARTICLES_FAVORITES_FETCH_PENDING,
	ARTICLES_FAVORITES_FETCH_REJECTED,
	ARTICLES_FAVORITES_REMOVE_FULFILLED,
	ARTICLES_FAVORITES_REMOVE_PENDING,
	ARTICLES_FAVORITES_REMOVE_REJECTED,
	ARTICLES_FETCH_FULFILLED,
	ARTICLES_FETCH_PENDING,
	ARTICLES_FETCH_REJECTED,
	ARTICLES_FILTERS_RESET,
	ARTICLES_GO_TO_PAGE_FULFILLED,
	ARTICLES_PAGINATION_NEXT,
	ARTICLES_PAGINATION_PREVIOUS,
	ARTICLES_SET_ACTIVE_FILTERS,
	ARTICLES_SET_COLUMN_SIZE,
	ARTICLES_SET_VIEW,
	ARTICLES_SORT,
	PATH_CHANGE
} from '~/action-types';
import {sanitizeAttr1IdKeyValue, omit, persistedState} from '~/utils';
import {articleIsFavorite} from '../article/favorite/actions';

export const initialState = {
	activeFilters: {
		apiInput: {},
		curr: {}
	},
	articles: [],
	category: {
		categoryBlurbs: []
	},
	currentFilters: {},
	favorites: {
		saved: persistedState.saved || false,
		articles: [],
		artNosAndAttr1Ids: persistedState.artNosAndAttr1Ids || [],
		numberOfSavedFavorites: persistedState.numberOfSavedFavorites || 0
	},
	fetchedCategoryId: -1,
	fetchedSearchQuery: '',
	filters: {},
	from: 0,
	initialPreviousPage: null,
	loadPrevious: false,
	nextPageQuery: null,
	isLoading: true,
	key: 'link_friendly_name',
	selectedColumnSize: undefined,
	selectedFilters: [],
	sort: [],
	total: 0,
	viewActive: false,
	viewActiveImage: '1010',
	viewActiveImageOnHover: '-1'
};

const sortFavorites = (favorites, artNosAndAttr1Ids) => {
	const arrSortBy = artNosAndAttr1Ids && artNosAndAttr1Ids.map(favorite => favorite.artNo + String(favorite.attr1Id));

	return [...favorites.hits].sort((a, b) => {
		return arrSortBy.indexOf(a.art_no + String(sanitizeAttr1IdKeyValue(a))) - arrSortBy.indexOf(b.art_no + String(sanitizeAttr1IdKeyValue(b)));
	});
};

const getNumberOfFavorites = (favoritesArtNosAndAttr1Ids, articles) => {
	return articles.filter(article => {
		return articleIsFavorite(favoritesArtNosAndAttr1Ids, article.art_no, article.attr1_id);
	}).length;
};

export default (state = initialState, action) => {
	const {type, meta = {}, payload, sort} = action;

	switch (type) {
		case ARTICLES_FAVORITES_ADD_PENDING:
		case ARTICLES_FAVORITES_FETCH_PENDING:
		case ARTICLES_FAVORITES_REMOVE_PENDING:
		case ARTICLES_FETCH_PENDING: {
			return {
				...state,
				isLoading: true
			};
		}
		case ARTICLES_FETCH_FULFILLED: {
			if (state.fetchedSearchQuery !== payload.searchQuery || !payload.hasFetchedCategoryId) {
				return {
					...initialState,
					articles: payload.articles,
					currentFilters: payload.aggregations,
					favorites: {
						...state.favorites
					},
					fetchedCategoryId: payload.categoryId,
					fetchedSearchQuery: payload.searchQuery,
					filters: payload.aggregations,
					isLoading: false,
					initialPreviousPage: !state.initialPreviousPage ? payload.pageQuery : state.initialPreviousPage,
					total: payload.total,
					meta: payload.meta,
					selectedColumnSize: state.selectedColumnSize,
					viewActive: state.viewActive,
					viewActiveImage: state.viewActiveImage,
					viewActiveImageOnHover: state.viewActiveImageOnHover
				};
			}

			return {
				...state,
				articles: state.loadPrevious ? payload.articles.concat(state.articles) : payload.from === 0 || payload.paginationType === 'pagination' ? payload.articles : state.articles.concat(payload.articles),
				currentFilters: payload.from === 0 ? payload.aggregations : state.currentFilters,
				initialPreviousPage: !state.initialPreviousPage ? payload.pageQuery : state.initialPreviousPage,
				fetchedCategoryId: payload.categoryId,
				fetchedSearchQuery: payload.searchQuery,
				isLoading: false,
				total: payload.total,
				meta: payload.meta
			};
		}
		case ARTICLES_FAVORITES_ADD_REJECTED:
		case ARTICLES_FAVORITES_FETCH_REJECTED:
		case ARTICLES_FAVORITES_REMOVE_REJECTED:
		case ARTICLES_FETCH_REJECTED: {
			return {
				...state,
				isLoading: false
			};
		}
		case ARTICLES_FAVORITES_REMOVE_FULFILLED: {
			const newStateFavorites = {
				articles: state.favorites.articles.filter(favorite => favorite.art_no + String(sanitizeAttr1IdKeyValue(favorite)) !== payload.artNo + String(payload.attr1Id)),
				artNosAndAttr1Ids: state.favorites.artNosAndAttr1Ids.filter(favorite => favorite.artNo + String(favorite.attr1Id) !== payload.artNo + String(payload.attr1Id))
			};

			return {
				...state,
				isLoading: false,
				favorites: {
					...newStateFavorites,
					saved: newStateFavorites.articles.length > 0 && newStateFavorites.artNosAndAttr1Ids.length > 0,
					numberOfSavedFavorites: getNumberOfFavorites(newStateFavorites.artNosAndAttr1Ids, newStateFavorites.articles)
				}
			};
		}
		case ARTICLES_FAVORITES_ADD_FULFILLED: {
			return {
				...state,
				isLoading: false,
				favorites: {
					articles: state.favorites.articles,
					artNosAndAttr1Ids: state.favorites.artNosAndAttr1Ids.some(favorite => favorite.artNo === payload.artNo && favorite.attr1Id === payload.attr1Id) ? state.favorites.artNosAndAttr1Ids : [{...payload}, ...state.favorites.artNosAndAttr1Ids],
					saved: state.favorites.saved
				}
			};
		}
		case ARTICLES_FAVORITES_FETCH_FULFILLED: {
			const newStateFavorites = {
				articles: payload.articles.hits && payload.articles.hits.length > 1 ? sortFavorites(payload.articles, state.favorites.artNosAndAttr1Ids) : payload.articles.hits,
				artNosAndAttr1Ids: state.favorites.artNosAndAttr1Ids
			};

			return {
				...state,
				isLoading: false,
				favorites: {
					...newStateFavorites,
					saved: newStateFavorites.articles.length > 0 && newStateFavorites.artNosAndAttr1Ids.length > 0,
					numberOfSavedFavorites: getNumberOfFavorites(newStateFavorites.artNosAndAttr1Ids, newStateFavorites.articles)
				}
			};
		}
		case ARTICLES_FILTERS_RESET: {
			return {
				...state,
				activeFilters: initialState.activeFilters,
				loadPrevious: false,
				selectedFilters: initialState.selectedFilters
			};
		}
		case ARTICLES_SET_ACTIVE_FILTERS: {
			const isPriceSlider = meta.key === 'lowest_price_sales';
			const isBoxFilter = !isPriceSlider;

			const getActiveFilters = () => {
				if (isPriceSlider) {
					return {
						apiInput: {
							...state.activeFilters.apiInput,
							lowest_price_sales: payload.apiInput.lowest_price_sales
						},
						curr: {
							...state.activeFilters.curr,
							lowest_price_sales: payload.curr.lowest_price_sales
						}
					};
				}

				if (isBoxFilter) {
					return {
						apiInput: payload.apiInput,
						curr: payload.curr
					};
				}
			};

			const getSelectedFilters = () => {
				if (isPriceSlider) {
					return state.selectedFilters;
				}

				if (isBoxFilter) {
					const boxFilters = {
						apiInput: omit(payload.apiInput, ['lowest_price_sales']),
						curr: omit(payload.curr, ['lowest_price_sales'])
					};

					const boxFilterKeys = Object.keys(boxFilters.curr);

					return boxFilterKeys.reduce((acc, key) => {
						const filter = boxFilters.curr[key];
						acc = acc.concat(filter);
						return acc;
					}, []);
				}
			};

			return {
				...state,
				activeFilters: getActiveFilters(),
				from: initialState.from,
				loadPrevious: initialState.loadPrevious,
				nextPageQuery: initialState.nextPageQuery,
				initialPreviousPage: initialState.initialPreviousPage,
				selectedFilters: getSelectedFilters()
			};
		}
		case ARTICLES_PAGINATION_PREVIOUS: {
			if (state.isLoading || state.articles.length === state.total || state.initialPreviousPage <= 1) {
				return state;
			}

			return {
				...state,
				isLoading: true,
				loadPrevious: true,
				initialPreviousPage: state.initialPreviousPage - 1
			};
		}
		case ARTICLES_PAGINATION_NEXT: {
			if (state.isLoading || state.articles.length === state.total) {
				return state;
			}

			return {
				...state,
				isLoading: true,
				from: state.from + payload.size,
				loadPrevious: false
			};
		}
		case ARTICLES_GO_TO_PAGE_FULFILLED: {
			if (state.isLoading) {
				return state;
			}

			return {
				...state,
				isLoading: true,
				loadPrevious: false,
				nextPageQuery: payload.page
			};
		}
		case ARTICLES_SET_COLUMN_SIZE: {
			return {
				...state,
				selectedColumnSize: payload
			};
		}
		case ARTICLES_SET_VIEW: {
			return {
				...state,
				viewActive: payload.viewActive,
				viewActiveImage: payload.viewActiveImage,
				viewActiveImageOnHover: payload.viewActiveImageOnHover
			};
		}
		case ARTICLES_SORT: {
			return {
				...state,
				from: initialState.from,
				loadPrevious: initialState.loadPrevious,
				nextPageQuery: initialState.nextPageQuery,
				initialPreviousPage: initialState.initialPreviousPage,
				sort
			};
		}
		case PATH_CHANGE: {
			return {
				...state,
				isLoading: state.isLoading
			};
		}
		default: {
			return state;
		}
	}
};
