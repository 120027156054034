const conditionallyAddConfig = (node, key, conditionStr, configArray) => (node && node.props[key] === conditionStr) ? configArray : [];

const selectIconConfig = [{
	name: 'iconClosed',
	inputType: 'icon',
	label: 'Choose closed icon',
	default: false
}, {
	name: 'iconClosedSize',
	inputType: 'text',
	label: 'Set size on closed icon',
	default: '2em'
}, {
	name: 'iconClosedColor',
	inputType: 'color',
	label: 'Set color on closed icon',
	default: '#000'
}, {
	name: 'iconOpen',
	inputType: 'icon',
	label: 'Choose open icon',
	default: false
}, {
	name: 'iconOpenSize',
	inputType: 'text',
	label: 'Set size on open icon',
	default: '2em'
}, {
	name: 'iconOpenColor',
	inputType: 'color',
	label: 'Set color on open icon',
	default: '#000'
}];

const selectConfig = [{
	name: 'maxQty',
	inputType: 'number',
	label: 'Highest number to be visible in quantity select',
	default: '10'
}];

export default node => ({
	type: 'SHOPCART_ROWS_ACTION',
	name: 'Row Action',
	allowedSections: ['main', 'header', 'footer'],
	disallowedChildren: ['*'],
	category: 'Shopcart',
	styleClasses: {
		Icon: '',
		Image: '',
		Text: '',
		Quantity_Input: ''
	},
	props: [{
		name: 'action',
		inputType: 'select',
		label: 'Action',
		default: 'remove',
		values: [{
			label: 'Remove item',
			value: 'remove'
		}, {
			label: 'Increase quantity',
			value: 'increase'
		}, {
			label: 'Decrease quantity',
			value: 'decrease'
		}, {
			label: 'Quantity input',
			value: 'qtyInput'
		}]
	}, node && node.props.action === 'qtyInput' && {
		name: 'qtyElement',
		inputType: 'select',
		label: 'Type of quantity input',
		default: 'textField',
		values: [{
			label: 'Text Field',
			value: 'textField'
		}, {
			label: 'Select',
			value: 'select'
		}]
	},
		...conditionallyAddConfig(node, 'qtyElement', 'select', selectIconConfig),
		...conditionallyAddConfig(node, 'qtyElement', 'select', selectConfig),
	{
		name: 'actionText',
		inputType: 'text',
		label: 'Action text',
		default: ''
	}, node && node.props.qtyElement !== 'select' && {
		name: 'icon',
		inputType: 'icon',
		label: 'Icon',
		default: false
	}, node && node.props.qtyElement !== 'select' && {
		name: 'iconSize',
		inputType: 'text',
		label: 'Icon size',
		default: '2em'
	}, node && node.props.qtyElement !== 'select' && {
		name: 'iconColor',
		inputType: 'color',
		label: 'Icon color',
		default: '#000'
	}, node && node.props.qtyElement !== 'select' && {
		name: 'image',
		inputType: 'media',
		label: 'Image',
		default: ''
	}].filter(Boolean),
	hiddenProps: [{
		label: 'Advanced settings',
		props: [{
			name: 'class',
			inputType: 'text',
			label: 'CSS-class',
			default: ''
		}]
	}]
});
