import {deepEqual} from 'fast-equals';
import Breakpoint from '~/breakpoint';

const shouldUpdate = ({props}, nextProps) => {
	return !deepEqual(props.article, nextProps.article) || !deepEqual(props.styleClasses, nextProps.styleClasses) || props.regexToRemoveFromSize !== nextProps.regexToRemoveFromSize;
};

const getSizes = props => {
	const arr = props.article.articleAttributes.filter(attribute => attribute.attribute2.attr2_id !== 0).map(attribute => attribute.attribute2);
	if (arr.length === 0) {
		return <noscript/>;
	}

	const removeRegex = props.regexToRemoveFromSize && new RegExp(props.regexToRemoveFromSize);
	const firstValue = removeRegex && (arr[0].attr2_code.indexOf('/') !== -1 || removeRegex.test(arr[0].attr2_code)) ? arr[0].attr2_code.replace(removeRegex, '') : arr[0].attr2_code;
	const lastValue = removeRegex && (arr[arr.length - 1].attr2_code.indexOf('/') !== -1 || removeRegex.test(arr[arr.length - 1].attr2_code)) ? arr[arr.length - 1].attr2_code.replace(removeRegex, '') : arr[arr.length - 1].attr2_code;
	const isDoubleValue = arr[0].attr2_code.indexOf('/') !== -1;
	if (firstValue === lastValue) {
		return (
			<div class='ListArticle-size'>
				<span class={['ListArticle-size', props.styleClasses['Size Range Size']]} data-style-attr='Size Range Size'>{firstValue}</span>
			</div>
		);
	}

	return (
		<div class='ListArticle-size'>
			<Breakpoint max='md'>
				<span class={['ListArticle-size-label', props.styleClasses['Size Range Label']]} data-style-attr='Size Range Label'>{props.getTranslation('global.sizes')}:</span>
				<span class={['ListArticle-size', props.styleClasses['Size Range Size']]} data-style-attr='Size Range Size'>{firstValue}</span>
				<span class={['ListArticle-size-delimiter', props.styleClasses['Size Range Delimiter']]} data-style-attr='Size Range Delimiter'>&mdash;</span>
				<span class={['ListArticle-size', props.styleClasses['Size Range Size']]} data-style-attr='Size Range Size'>{lastValue}</span>
			</Breakpoint>
			<Breakpoint min='lg'>
				<span class={['ListArticle-size-label', props.styleClasses['Size Range Label']]} data-style-attr='Size Range Label'>{isDoubleValue ? props.getTranslation('global.double_sizes') : props.getTranslation('global.sizes')}:</span>
				<span class={['ListArticle-size', props.styleClasses['Size Range Size']]}data-style-attr='Size Range Size'>{firstValue}</span>
				<span class={['ListArticle-size-delimiter', props.styleClasses['Size Range Delimiter']]} data-style-attr='Size Range Delimiter'>&mdash;</span>
				<span class={['ListArticle-size', props.styleClasses['Size Range Size']]} data-style-attr='Size Range Size'>{lastValue}</span>
			</Breakpoint>
		</div>
	);
};

const render = ({props}) => {
	return (
		<div class={['SizeRange', props.styleClasses['Size Range']]} data-style-attr='Size Range'>
			{getSizes(props)}
		</div>
	);
};

export default {render, shouldUpdate};

