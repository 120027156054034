import {deepEqual} from 'fast-equals';
import NotFound from '../../not-found';
import {Section} from '../../streamline-cms';

const propTypes = {
	query: {
		type: 'string'
	}
};

const fetch = props => props.categoryFetch(props.query, {lang_id: props.languageId}, {localeMode: props.localeMode, pathPrefix: props.pathPrefix});

const beforeMount = ({props}) => !props.contentManagementActive && !props.categoryAlreadyLoaded && fetch(props);
const shouldUpdate = ({props}, nextProps) => !deepEqual(props, nextProps);
const afterUpdate = ({props}, prevProps) => props.query !== prevProps.query && !props.contentManagementActive && fetch(props);
const beforeUnmount = ({props}) => props.clearMainContent();

const render = ({props}) => {
	const {contentManagementActive, notFound} = props;

	if (notFound && !contentManagementActive) {
		return <NotFound/>;
	}

	if (notFound && contentManagementActive) {
		return (
			<div class='u-textCenter'>
				404 Page not found
			</div>
		);
	}

	return (
		<div>
			<Section sectionId='main'/>
		</div>
	);
};

export default {afterUpdate, beforeMount, beforeUnmount, propTypes, render, shouldUpdate};
