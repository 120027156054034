import {connect} from '@viskan/deku-redux';
import {categoryFetch, clearMainContent} from './actions';
import Component from './components';

export default connect(
	({app, router, site}, props) => ({
		categoryAlreadyLoaded: props.query === app.category.main.linkFriendlyName,
		contentManagementActive: site.cmsTokenSet,
		languageId: router.path === site.localePickerPath ? site.defaultLanguageId : site.lang_id,
		localeMode: site.localeMode,
		notFound: app.category.main.notFound,
		pathPrefix: router.pathPrefix
	}),
	dispatch => ({
		categoryFetch: (query, v12Options, options) => dispatch(categoryFetch(query, 'main', v12Options, options)),
		clearMainContent: () => dispatch(clearMainContent())
	})
)(Component);
