import bus from '@viskan/bus';
import page from 'page';
import submitForm from 'submitform';
import {handleError} from '../utils';
import {PurchaseError} from '..';
import Spinner from './spinner';

const name = 'PurchaseExternal';

const handlePaymentResponse = pathPrefix => payment => {
	const {action, parameters, purchaseNumber, redirectURL, submitURL} = payment.body;

	bus.emit('loading:close');

	switch (action) {
		case 'REDIRECT': {
			window.location.href = redirectURL;
			return;
		}
		case 'SUBMIT': {
			const formData = parameters.reduce((obj, item) => {
				obj[item.key] = item.value;
				return obj;
			}, {});

			submitForm(formData, {action: submitURL, method: 'POST'});
			return;
		}
		default: {
			page(`${pathPrefix}receipt/${purchaseNumber}`);
		}
	}
};

const purchaseWithExternalPayment = (props, setState) => v12.util.http.post('/api/purchase/purchases', {
	body: {
		klarnaCheckoutExternalPayment: true,
		paymentMethodId: props.paymentMethodId,
		shopcartId: props.shopcartId
	}
})
	.then(handlePaymentResponse(props.pathPrefix))
	.catch(error => handleError(error, setState));

const afterMount = ({props}, el, setState) => purchaseWithExternalPayment(props, setState);

const render = ({props, state}) => {
	if (state.errorCode) {
		return <PurchaseError errorCode={state.errorCode} purchaseNumber={props.purchaseNumber}/>;
	}

	return <Spinner/>;
};

export default {afterMount, name, render};
