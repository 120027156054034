const conditionallyAddConfig = (node, key, conditionStr, configArray) => (node && node.props[key] === conditionStr) ? configArray : [];

const iconConfig = prefix => [{
	name: prefix ? `${prefix}IconNext` : 'iconNext',
	inputType: 'icon',
	label: 'Choose next icon',
	default: false
}, {
	name: prefix ? `${prefix}IconNextSize` : 'iconNextSize',
	inputType: 'text',
	label: 'Set size on next icon',
	default: '2em'
}, {
	name: prefix ? `${prefix}IconNextColor` : 'iconNextColor',
	inputType: 'color',
	label: 'Set color on next icon',
	default: '#000'
}, {
	name: prefix ? `${prefix}IconPrev` : 'iconPrev',
	inputType: 'icon',
	label: 'Choose previous icon',
	default: false
}, {
	name: prefix ? `${prefix}IconPrevSize` : 'iconPrevSize',
	inputType: 'text',
	label: 'Set size on previous icon',
	default: '2em'
}, {
	name: prefix ? `${prefix}IconPrevColor` : 'iconPrevColor',
	inputType: 'color',
	label: 'Set color on previous icon',
	default: '#000'
}];

const imageConfig = prefix => [{
	name: prefix ? `${prefix}ImageNext` : 'imageNext',
	inputType: 'media',
	label: 'Choose next image',
	default: ''
}, {
	name: prefix ? `${prefix}ImagePrev` : 'imagePrev',
	inputType: 'media',
	label: 'Choose previous image',
	default: ''
}];

const getCssGridOptions = (device, defaults) => [{
	name: `cssGridRows${device}`,
	label: 'Num. Rows',
	inputType: 'number',
	default: defaults.rows
}, {
	name: `cssGridColumns${device}`,
	label: 'Num. Columns',
	inputType: 'number',
	default: defaults.columns
}, {
	name: `cssGridGutter${device}`,
	label: 'Grid gutter',
	inputType: 'number',
	default: defaults.gutter
}];

export default node => ({
	type: 'INSTAGRAM',
	name: 'Instagram feed',
	allowedSections: ['main'],
	disallowedChildren: ['*'],
	category: 'Social',
	props: [{
		name: 'useWithArticle',
		label: 'Use on article page',
		inputType: 'checkbox',
		default: false
	}, node && node.props.useWithArticle === true && {
		name: 'onlyShowMatchedShares',
		label: 'Only show shares matching current product',
		inputType: 'checkbox',
		default: true
	}, node && node.props.useWithArticle === true && node.props.onlyShowMatchedShares === false && {
		name: 'sortMatchedSharesFirst',
		label: 'Show shares with matching product first',
		inputType: 'checkbox',
		default: true
	}, {
		name: 'imageFit',
		inputType: 'select',
		label: 'Image fit',
		default: 'square',
		values: [{
			label: 'Square',
			value: 'square'
		}, {
			label: 'Fit in',
			value: 'fit-in'
		}]
	}, {
		name: 'useCssGrid',
		label: 'Use Dynamic Grid',
		inputType: 'checkbox',
		default: false
	}, node && node.props.useCssGrid === true && {
		name: 'cssGridSeed',
		label: 'Dynamic Grid Seed',
		inputType: 'text',
		default: ''
	}].filter(Boolean),
	hiddenProps: [node && node.props.useCssGrid === true && {
		label: 'Dynamic Grid Mobile Options',
		props: getCssGridOptions('Mobile', {rows: 6, columns: 3, gutter: 5})
	}, node && node.props.useCssGrid === true && {
		label: 'Dynamic Grid Tablet Options',
		props: getCssGridOptions('Tablet', {rows: 5, columns: 6, gutter: 10})
	}, node && node.props.useCssGrid === true && {
		label: 'Dynamic Grid Desktop Options',
		props: getCssGridOptions('Desktop', {rows: 4, columns: 10, gutter: 20})
	}, {
		label: 'Articles options',
		props: [{
			name: 'image',
			inputType: 'select',
			label: 'Image',
			default: '1010',
			values: [{
				label: 'Front',
				value: '1010'
			}, {
				label: 'Back',
				value: '1011'
			}, {
				label: 'List',
				value: '1020'
			}]
		}, {
			name: 'imageOnHover',
			inputType: 'select',
			label: 'Image on hover',
			default: '-1',
			values: [{
				label: 'Front',
				value: '1010'
			}, {
				label: 'Back',
				value: '1011'
			}, {
				label: 'List',
				value: '1020'
			}, {
				label: 'None',
				value: '-1'
			}]
		}, {
			name: 'showDiscountPercent',
			inputType: 'checkbox',
			label: 'Show price discount percent',
			default: false
		}]
	}, {
		label: 'Badge settings',
		props: [{
			name: 'showSaleOrNew',
			inputType: 'checkbox',
			label: 'Show sale or new icon',
			default: false
		}, {
			name: 'showPropertyIcon',
			inputType: 'checkbox',
			label: 'Show article icon from property key',
			default: false
		}, {
			name: 'showCampaigns',
			inputType: 'checkbox',
			label: 'Show campaign icon',
			default: true
		}, {
			name: 'campaignPopup',
			inputType: 'checkbox',
			label: 'Popup on campaign click',
			default: false
		}, {
			name: 'campaignHeader',
			inputType: 'text',
			label: 'Campaign popup header',
			default: ''
		}]
	}, {
		label: 'Feed options',
		props: [{
			name: 'gutter',
			inputType: 'checkbox',
			label: 'Gutter',
			default: false
		}, {
			name: 'xsm',
			inputType: 'select',
			label: 'Columns mobile (small)',
			default: '2',
			values: [{
				label: '1 column',
				value: '1'
			}, {
				label: '2 columns',
				value: '2'
			}, {
				label: '3 columns',
				value: '3'
			}, {
				label: '4 columns',
				value: '4'
			}, {
				label: '5 columns',
				value: '5'
			}, {
				label: '6 columns',
				value: '6'
			}, {
				label: '8 columns',
				value: '8'
			}, {
				label: '10 columns',
				value: '10'
			}, {
				label: '12 columns',
				value: '12'
			}]
		}, {
			name: 'sm',
			inputType: 'select',
			label: 'Columns mobile',
			default: '2',
			values: [{
				label: '1 column',
				value: '1'
			}, {
				label: '2 columns',
				value: '2'
			}, {
				label: '3 columns',
				value: '3'
			}, {
				label: '4 columns',
				value: '4'
			}, {
				label: '5 columns',
				value: '5'
			}, {
				label: '6 columns',
				value: '6'
			}, {
				label: '8 columns',
				value: '8'
			}, {
				label: '10 columns',
				value: '10'
			}, {
				label: '12 columns',
				value: '12'
			}]
		}, {
			name: 'md',
			inputType: 'select',
			label: 'Columns tablet',
			default: '3',
			values: [{
				label: '1 column',
				value: '1'
			}, {
				label: '2 columns',
				value: '2'
			}, {
				label: '3 columns',
				value: '3'
			}, {
				label: '4 columns',
				value: '4'
			}, {
				label: '5 columns',
				value: '5'
			}, {
				label: '6 columns',
				value: '6'
			}, {
				label: '8 columns',
				value: '8'
			}, {
				label: '10 columns',
				value: '10'
			}, {
				label: '12 columns',
				value: '12'
			}]
		}, {
			name: 'lg',
			inputType: 'select',
			label: 'Columns desktop',
			default: '4',
			values: [{
				label: '1 column',
				value: '1'
			}, {
				label: '2 columns',
				value: '2'
			}, {
				label: '3 columns',
				value: '3'
			}, {
				label: '4 columns',
				value: '4'
			}, {
				label: '5 columns',
				value: '5'
			}, {
				label: '6 columns',
				value: '6'
			}, {
				label: '8 columns',
				value: '8'
			}, {
				label: '10 columns',
				value: '10'
			}, {
				label: '12 columns',
				value: '12'
			}]
		}, {
			name: 'xlg',
			inputType: 'select',
			label: 'Columns desktop (large)',
			default: '5',
			values: [{
				label: '1 column',
				value: '1'
			}, {
				label: '2 columns',
				value: '2'
			}, {
				label: '3 columns',
				value: '3'
			}, {
				label: '4 columns',
				value: '4'
			}, {
				label: '5 columns',
				value: '5'
			}, {
				label: '6 columns',
				value: '6'
			}, {
				label: '8 columns',
				value: '8'
			}, {
				label: '10 columns',
				value: '10'
			}, {
				label: '12 columns',
				value: '12'
			}]
		}]
	}, {
		label: 'Slider navigation',
		props: [{
			name: 'arrows',
			inputType: 'checkbox',
			label: 'Show Arrows',
			default: true
		}, {
			name: 'type',
			inputType: 'select',
			label: 'Choose arrow type',
			default: 'none',
			values: [{
				label: 'Native',
				value: 'none'
			}, {
				label: 'Icon',
				value: 'icon'
			}, {
				label: 'Image',
				value: 'image'
			}]
		},
			...conditionallyAddConfig(node, 'type', 'icon', iconConfig()),
			...conditionallyAddConfig(node, 'type', 'image', imageConfig()),
		{
			name: 'spaceBetweenSlides',
			inputType: 'select',
			label: 'Space between',
			default: '0',
			values: ['0', '10', '20', '30', '40']
		}]
	}, {
		label: 'Slider options desktop',
		props: [{
			name: 'sliderDesktop',
			inputType: 'checkbox',
			label: 'Use slider on desktop',
			default: false
		}, {
			name: 'rowsDesktop',
			inputType: 'slider',
			label: 'Rows desktop',
			default: 2,
			min: 1,
			max: 4,
			step: 1
		}, {
			name: 'columnsDesktop',
			inputType: 'slider',
			label: 'Columns desktop',
			default: 5,
			min: 1,
			max: 8,
			step: 1
		}]
	}, {
		label: 'Slider options tablet',
		props: [{
			name: 'sliderTablet',
			inputType: 'checkbox',
			label: 'Use slider on tablet',
			default: false
		}, {
			name: 'rowsTablet',
			inputType: 'slider',
			label: 'Rows tablet',
			default: 1,
			min: 1,
			max: 4,
			step: 1
		}, {
			name: 'columnsTablet',
			inputType: 'slider',
			label: 'Columns tablet',
			default: 3,
			min: 1,
			max: 8,
			step: 1
		}]
	}, {
		label: 'Slider options mobile',
		props: [{
			name: 'sliderMobile',
			inputType: 'checkbox',
			label: 'Use slider on mobile',
			default: false
		}, {
			name: 'rowsMobile',
			inputType: 'slider',
			label: 'Rows mobile',
			default: 1,
			min: 1,
			max: 4,
			step: 1
		}, {
			name: 'columnsMobile',
			inputType: 'slider',
			label: 'Columns mobile',
			default: 1,
			min: 1,
			max: 8,
			step: 1
		}]
	}, {
		label: 'Instagram post',
		props: [{
			name: 'header',
			inputType: 'text',
			label: 'Header',
			default: ''
		}, {
			name: 'openPostsInMobile',
			inputType: 'select',
			label: 'How to display open post in mobile',
			default: 'modal',
			values: [{
				label: 'Modal',
				value: 'modal'
			}, {
				label: 'Slideout',
				value: 'slideout'
			}]
		}, {
			name: 'openPostsInTablet',
			inputType: 'select',
			label: 'How to display open post in tablet',
			default: 'modal',
			values: [{
				label: 'Modal',
				value: 'modal'
			}, {
				label: 'Slideout',
				value: 'slideout'
			}]
		}, {
			name: 'openPostsInDesktop',
			inputType: 'select',
			label: 'How to display open post in desktop',
			default: 'modal',
			values: [{
				label: 'Modal',
				value: 'modal'
			}, {
				label: 'Slideout',
				value: 'slideout'
			}]
		}, {
			name: 'postArrowType',
			inputType: 'select',
			label: 'Choose arrow type',
			description: 'Arrow to display when navigating between posts after opening a post',
			default: 'icon',
			values: [{
				label: 'Icon',
				value: 'icon'
			}, {
				label: 'Image',
				value: 'image'
			}]
		},
			...conditionallyAddConfig(node, 'postArrowType', 'icon', iconConfig('post')),
			...conditionallyAddConfig(node, 'postArrowType', 'image', imageConfig('post'))
		]
	}, node && (node.props.openPostsInMobile === 'slideout' || node.props.openPostsInTablet === 'slideout' || node.props.openPostsInDesktop === 'slideout') && {
		label: 'Slideout',
		props: [{
			name: 'slideoutSide',
			inputType: 'select',
			label: 'Side to slide out from',
			default: 'Right',
			values: ['Left', 'Right']
		}]
	}].filter(Boolean)
});
