const handleError = error => {
	console.error('Session check failed: ', error);

	window.location.reload();
};

const getSessionId = () => v12.util.http.get('/api/session-check', {json: false})
	.then(({body}) => body)
	.catch(handleError);

export default async () => {
	try {
		const currentSessionId = await getSessionId();
		let lastTimeChecked = new Date();

		setInterval(async () => {
			const currentTime = new Date();

			if (currentTime - lastTimeChecked > 1200000) {
				const newSessionId = await getSessionId();

				if (!window._streamline.holdNewSessionReload && currentSessionId && newSessionId !== currentSessionId) {
					window.location.reload();
				}

				window._streamline.holdNewSessionReload = false;
				lastTimeChecked = currentTime;
			}
		}, 5000);
	} catch (error) {
		handleError(error);
	}
};
