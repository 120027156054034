const botPatterns = [
	'(PTST\\/(1|2))',
	'^Mozilla/5.0libwww',
	'Awesomium',
	'bingp',
	'bot',
	'burp',
	'Chrome-Lighthouse',
	'cloud',
	'crawl',
	'DareBoost',
	'DejaClick',
	'DMBrowser',
	'evaliant$',
	'GomezAgent',
	'Google (F|P)',
	'HeadlessChrome',
	'HTTrack',
	'ips-agent',
	'Jeeves\\/Teoma',
	'Mediapartners',
	'outbrain',
	'PhysicalWeb',
	'PingdomTMS',
	'Preview',
	'RuxitSynthetic',
	'scan',
	'scrap',
	'Site',
	'spider',
	'splash',
	'viskan monitoring',
	'zgrab'
];

const regex = new RegExp('(' + botPatterns.join('|') + ')', 'i');
const isBot = () => regex.test(window.navigator.userAgent);

export default isBot;
