export default node => ({
	type: 'ARTICLE_PRICE',
	name: 'Price',
	allowedSections: ['article'],
	disallowedChildren: ['*'],
	category: 'Article',
	props: [{
		name: 'showDiscountPercent',
		inputType: 'checkbox',
		label: 'Show price discount percent',
		default: false
	}, {
		name: 'showRecPrice',
		inputType: 'checkbox',
		label: 'Show recommended price',
		default: false
	}, node && node.props.showRecPrice && {
		name: 'recPricePrefixText',
		inputType: 'text',
		label: 'Recommended price label',
		default: 'Rec price'
	}, {
		name: 'includeAddonsPrice',
		inputType: 'checkbox',
		label: 'Include active addons price',
		default: false
	}, {
		name: 'showSubscriptionPrice',
		inputType: 'checkbox',
		label: 'Show price for selected subscription',
		default: false
	}].filter(Boolean),
	hiddenProps: [{
		label: 'Advanced settings',
		props: [{
			name: 'class',
			inputType: 'text',
			label: 'CSS-class',
			default: ''
		}]
	}]
});
