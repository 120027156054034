import {connect} from '@viskan/deku-redux';
import {getTranslator} from '~/sources/translator';
import Component from './components';
import PurchaseCancelComponent from './components/cancel';
import PurchaseErrorComponent from './components/error';
import PurchaseExternalComponent from './components/external';

export default connect(
	state => ({
		getTranslation: getTranslator(state),
		pathPrefix: state.router.pathPrefix,
		checkoutUrl: state.site.settings.checkoutUrl
	})
)(Component);

export const PurchaseCancel = connect(
	state => ({
		pathPrefix: state.router.pathPrefix,
		checkoutUrl: state.site.settings.checkoutUrl
	})
)(PurchaseCancelComponent);

export const PurchaseError = connect(
	state => ({
		getTranslation: getTranslator(state),
		pathPrefix: state.router.pathPrefix,
		checkoutUrl: state.site.settings.checkoutUrl
	})
)(PurchaseErrorComponent);

export const PurchaseExternal = connect(
	state => ({
		pathPrefix: state.router.pathPrefix
	})
)(PurchaseExternalComponent);
