export default node => ({
	type: 'FILTER',
	name: 'Filter',
	allowedSections: ['main'],
	disallowedChildren: ['*'],
	category: 'Articles',
	props: [{
		name: 'filterSource',
		inputType: 'filter-property',
		label: 'Source'
	}, node && node.props.filterSource === 'selectedFilters' && {
		name: 'hideSelectedFiltersIfEmpty',
		inputType: 'checkbox',
		label: 'Hide selected filters if there are none selected.',
		default: false
	}, node && node.props.filterSource === 'category' && {
		name: 'hideHiddenCategories',
		inputType: 'checkbox',
		label: 'Hide categories that are hidden',
		default: false
	}, {
		name: 'filterStyle',
		inputType: 'select',
		label: 'Style',
		values: [{
			label: 'Accordion',
			value: 'accordion'
		}, {
			label: 'Dropdown',
			value: 'dropdownBox'
		}],
		default: 'dropdownBox'
	}, node && node.props.filterStyle === 'accordion' && {
		name: 'startOpen',
		inputType: 'checkbox',
		label: 'Start accordion open',
		default: false
	}, {
		name: 'filterType',
		inputType: 'select',
		label: 'Type',
		values: [{
			label: 'Button',
			value: 'button'
		}, {
			label: 'Checkbox',
			value: 'checkbox'
		}],
		default: 'button'
	}, {
		name: 'filterHeader',
		inputType: 'text',
		label: 'Filter header',
		default: 'Filter header'
	}, node && node.props.filterSource !== 'attribute1' && node.props.filterSource !== 'attribute2' && {
		name: 'groupFilters',
		inputType: 'checkbox',
		label: 'Group filter options',
		description: 'This setting does not affect color or sizes.',
		default: false
	}, {
		name: 'hideIdZero',
		inputType: 'checkbox',
		label: 'Hide filter options where key has ID 0',
		default: false
	}, {
		name: 'showActiveFilterCount',
		inputType: 'checkbox',
		label: 'Show how many filters are selected for each filter type',
		default: false
	}, node && node.props.filterStyle === 'accordion' && {
		name: 'iconOpen',
		inputType: 'icon',
		label: 'Choose open icon',
		default: false
	}, node && node.props.filterStyle === 'accordion' && {
		name: 'iconOpenSize',
		inputType: 'text',
		label: 'Set size on open icon',
		default: '2em'
	}, node && node.props.filterStyle === 'accordion' && {
		name: 'iconOpenColor',
		inputType: 'color',
		label: 'Set color on open icon',
		default: '#000'
	}, node && node.props.filterStyle === 'accordion' && {
		name: 'iconClosed',
		inputType: 'icon',
		label: 'Choose closed icon',
		default: false
	}, node && node.props.filterStyle === 'accordion' && {
		name: 'iconClosedSize',
		inputType: 'text',
		label: 'Set size on closed icon',
		default: '2em'
	}, node && node.props.filterStyle === 'accordion' && {
		name: 'iconClosedColor',
		inputType: 'color',
		label: 'Set color on closed icon',
		default: '#000'
	}, node && node.props.filterStyle === 'dropdownBox' && {
		name: 'iconDropdown',
		inputType: 'icon',
		label: 'Choose dropdown icon',
		default: false
	}, node && node.props.filterStyle === 'dropdownBox' && {
		name: 'iconDropdownSize',
		inputType: 'text',
		label: 'Set size on dropdown icon',
		default: '2em'
	}, node && node.props.filterStyle === 'dropdownBox' && {
		name: 'iconDropdownColor',
		inputType: 'color',
		label: 'Set color on dropdown icon',
		default: '#000'
	}].filter(Boolean)
});
