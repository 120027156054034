import bus from '@viskan/bus';
import page from 'page';
import Spinner from './spinner';
import {handleError} from '../utils';
import {PurchaseError} from '..';

const name = 'PurchaseCancel';

const cancelPurchaseAndRestoreCart = (purchaseNumber, pathPrefix, checkoutUrl, setState) => v12.util.http.post('/api/purchase/cancellations', {body: {purchaseNumber}})
	.then(({body}) => v12.util.http.post('/api/shopcart/restore', {body, json: false, query: v12.options.defaults}))
	.then(() => v12.shopcart.get())
	.then(data => {
		bus.emit('shopcart:update', data);
		bus.emit('shopcart:get', data);

		page(`${pathPrefix}${checkoutUrl}`);
	})
	.catch(error => handleError(error, setState));

const afterMount = ({props}, el, setState) => cancelPurchaseAndRestoreCart(props.purchaseNumber, props.pathPrefix, props.checkoutUrl, setState);

const render = ({props, state}) => {
	if (state.errorCode) {
		return <PurchaseError errorCode={state.errorCode} purchaseNumber={props.purchaseNumber}/>;
	}

	return <Spinner/>;
};

export default {afterMount, name, render};
