import cmsComponentArticlePackage from '~/../../node_modules/@viskan/cms-component-article-package/dist/reducer';
import cmsComponentBreadcrumbs from '~/../../node_modules/@viskan/cms-component-breadcrumbs/dist/reducer';
import cmsComponentCategoryImport from '~/../../node_modules/@viskan/cms-component-category-import/dist/reducer';
import cmsComponentDropdown from '~/../../node_modules/@viskan/cms-component-dropdown/dist/reducer';
import cmsComponentMyPagesSubscriptions from '~/../../node_modules/@viskan/cms-component-my-pages-subscriptions/dist/reducer';
import cmsComponentPopup from '~/../../node_modules/@viskan/cms-component-popup/dist/reducer';
import cmsComponentShopTheLook from '~/../../node_modules/@viskan/cms-component-shop-the-look/dist/reducer';
import cmsComponentSlideout from '~/../../node_modules/@viskan/cms-component-slideout/dist/reducer';
import cmsComponentSubMenu from '~/../../node_modules/@viskan/cms-component-sub-menu/dist/reducer';
import cmsComponentSubMenuDropdown from '~/../../node_modules/@viskan/cms-component-sub-menu-dropdown/dist/reducer';
import cmsComponentCheckout from '~/../../node_modules/@viskan/cms-component-checkout/dist/reducer';
import cmsComponentCheckoutWithAuthentication from '~/../../node_modules/@viskan/cms-component-checkout-with-authentication/dist/reducer';
import cmsComponentCheckoutShipping from '~/../../node_modules/@viskan/cms-component-checkout-shipping/dist/reducer';
import cmsComponentQuickCart from '~/../../node_modules/@viskan/cms-component-quick-cart/dist/reducer';

export default {
	articlePackage: cmsComponentArticlePackage,
	breadcrumbs: cmsComponentBreadcrumbs,
	categoryImport: cmsComponentCategoryImport,
	dropdown: cmsComponentDropdown,
	subscriptions: cmsComponentMyPagesSubscriptions,
	popup: cmsComponentPopup,
	articleShopTheLook: cmsComponentShopTheLook,
	slideout: cmsComponentSlideout,
	subMenu: cmsComponentSubMenu,
	subMenuDropdown: cmsComponentSubMenuDropdown,
	checkout: cmsComponentCheckout,
	checkout: cmsComponentCheckoutWithAuthentication,
	shipping: cmsComponentCheckoutShipping,
	quickCart: cmsComponentQuickCart,
};