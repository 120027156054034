import {deepEqual} from 'fast-equals';

const initialState = props => {
	const timeTicked = Date.now();

	return {
		intervalId: undefined,
		timeStarted: timeTicked,
		timeRemaining: props.pollTimeout,
		timeTicked
	};
};

const afterMount = ({props, state}, el, setState) => {
	setState({
		intervalId: setInterval(() => {
			const timeTicked = Date.now();

			setState({
				timeRemaining: Math.max(0, (state.timeStarted + props.pollTimeout) - timeTicked),
				timeTicked
			});
		}, 1000)
	});
};

const shouldUpdate = ({props, state}, nextProps, nextState) => !deepEqual(props, nextProps) || !deepEqual(state, nextState);
const beforeUnmount = ({state}) => state.intervalId && clearInterval(state.intervalId);

const afterUpdate = ({props, state}) => {
	if (state.timeRemaining <= 0) {
		clearInterval(state.intervalId);
		props.onTimeOut();
	}
};

const render = ({props, state}) => {
	return (
		<div class={['PurchaseCountdown', props.class]}>
			{props.getTranslation(`purchase.polling.countdown`, {count: Math.ceil(state.timeRemaining / 1000)})}
		</div>
	);
};

export default {afterMount, afterUpdate, beforeUnmount, initialState, render, shouldUpdate};
