import bus from '@viskan/bus';
import store from 'store2';

const get = (app, logout = false) => {
	if (logout) {
		store.remove(`vs-email-${app.sources.site.locale.toLowerCase()}`);
		store.session.remove('vs-person_flg');
	}

	v12.customer.get()
		.then(data => app.set('customer', data))
		.catch(error => bus.emit('popup:open', error.message));
};

export default app => {
	bus.on('customer:update', data => app.set('customer', data));
	bus.on('logout', () => get(app, true));
	app.set('customer', window._streamline.customerOnLoadStatus);
};
