import {connect} from '@viskan/deku-redux';
import {categoryFetch} from '../category/actions';
import {fetchArticle, resetArticle} from './actions';
import Component from './components';

export default connect(
	({app, article, router, site, cms, customer}) => ({
		active: article.active,
		article: article.article,
		contentManagementActive: site.cmsTokenSet,
		customerReducedPricePercentage: (customer.dynamic && customer.dynamic.customerReducedPricePercentage) || 0,
		languageId: site.lang_id,
		loadCategoryOnArticle: site.loadCategoryOnArticle,
		notFound: app.category.article.notFound || article.notFound,
		pathname: router.pathname,
		activeCmsSection: cms && cms.section.active,
		useArticleQuickBuyLayout: site.settings.useArticleQuickBuyLayout
	}),
	dispatch => ({
		fetchArticle: (query, sku, opts) => dispatch(fetchArticle(query, sku, opts)),
		categoryFetch: (query, opts, articleCategory) => dispatch(categoryFetch(query, 'main', opts, {}, articleCategory)),
		resetArticle: () => dispatch(resetArticle())
	})
)(Component);
