import queryString from 'query-string';

const getUrl = item => {
	const query = queryString.stringify({
		attr1_id: item.attr1_id !== 0 ? item.attr1_id : undefined,
		attr2_id: item.attr2_id !== 0 ? item.attr2_id : undefined,
		attr3_id: item.attr3_id !== 0 ? item.attr3_id : undefined
	});

	return `${window.location.origin}${window.location.pathname}${query ? '?' : ''}${query}`;
};

export const getProductMarkup = (article, site) => {
	if (!article) {
		return [];
	}

	const {currency_code} = site;
	const {articleAttributes} = article;
	let productString;

	const json = articleAttributes.map(item => {
		const media = v12.article.medias(article, {
			sku: {
				attr1_id: item.attr1_id,
				attr2_id: item.attr2_id,
				attr3_id: item.attr3_id
			},
			size: 'normal'
		}).map(m => `${window.location.origin}${m.path}`);

		return {
			'@type': 'Product',
			'@id': `${article.art_id}-${item.attr1_id}-${item.attr2_id}`,
			'productID': article.art_id,
			'name': article.artname,
			'description': article.art_desc || article.art_desc2,
			'brand': article.brandName,
			'image': media,
			'mpn': article.art_no,
			'color': item.attribute1.attr1_id !== 0 ? item.attribute1.attr1_code : '',
			'sku': item.pluno || `${article.art_no}-${item.attr1_id}-${item.attr2_id}`,
			'offers': {
				'@type': 'Offer',
				'availability': item.inStock ? 'http://schema.org/InStock' : 'http://schema.org/OutOfStock',
				'itemCondition': 'http://schema.org/NewCondition',
				'url': getUrl(item),
				'priceCurrency': currency_code,
				'priceSpecification': {
					'@type': 'PriceSpecification',
					'priceCurrency': currency_code,
					'price': item.articlePrice.price_sales,
					'valueAddedTaxIncluded': true
				}
			}
		};
	});

	try {
		productString = JSON.stringify(json);
	} catch (error) {
		productString = '';
	}

	return productString;
};
