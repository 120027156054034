export default {
	type: 'ARTICLE_THUMBNAILS',
	name: 'Thumbnails',
	allowedSections: ['article'],
	disallowedChildren: ['*'],
	category: 'Article',
	icon: 'fas fa-camera-retro',
	styleClasses: {
		PlayIcon: 'Preset-Thumbnail-Video-Icon',
		Thumbnail: 'Preset-Thumbnail',
		Thumbnails: '',
		'Thumbnail Image': '',
		'Thumbnail Video': '',
		Wrapper: ''
	},
	props: [{
		name: 'orientation',
		inputType: 'select',
		label: 'Orientation',
		default: 'horizontal',
		values: ['horizontal', 'vertical']
	}, {
		name: 'thresHoldToShowThumbnails',
		inputType: 'number',
		label: 'Minimum images required to show thumbnails',
		default: '2'
	}],
	hiddenProps: [{
		label: 'Advanced settings',
		props: [{
			name: 'isSizeMatchRequired',
			inputType: 'checkbox',
			label: 'Show images for selected size only',
			default: ''
		}, {
			name: 'isAttr3MatchRequired',
			inputType: 'checkbox',
			label: 'Show images for selected attribute 3 only',
			default: ''
		}, {
			name: 'useWith360Image',
			inputType: 'checkbox',
			label: 'Use with 360 image (shows every sixth thumbnail)',
			default: false
		}, {
			name: 'excludeImages',
			inputType: 'text',
			label: 'Exclude images (eg. 1011, 1040)',
			default: ''
		}, {
			name: 'imagesOrderList',
			inputType: 'text',
			label: 'Order images by media row id',
			description: 'Comma separated list of media row ids to order images by (eg. 1011, 1040)',
			default: ''
		}, {
			name: 'videoThumbnailPath',
			inputType: 'media',
			label: 'Thumbnail to use for videos',
			default: ''
		}, {
			name: 'showFirstFrameVideoThumbnail',
			inputType: 'checkbox',
			label: 'Show the first frame as image for video thumbnail',
			default: false
		}, {
			name: 'class',
			inputType: 'text',
			label: 'CSS-class',
			default: ''
		}]
	}]
};
