import estoreAnalytics from '@viskan/analytics';
import Timeout from 'await-timeout';
import queryString from 'query-string';
import {setGoogleAnalyticsId} from '../site/actions';

const analytics = estoreAnalytics({
	enhanced: true,
	params: {
		id: window._streamline.siteContext.thirdParty.googleTagManagerId,
		gaId: window._streamline.siteContext.thirdParty.googleAnalyticsId
	}
});

const noop = () => {};

const getCampaign = (data, attr1_id) => {
	let ret = '';

	data.forEach(x => {
		if (x.attr1_id === attr1_id) {
			ret = x.camp_name || x.camp_desc;
		}
	});

	return ret;
};

/**
 * Generates a "variant string" containing `color name/size name`.
 * Will return only one of them if only one of them has attribute identifier > 0.
 *
 * @param {number} attribute1Id the attribute 1 identifier
 * @param {string} attribute1Code the attribute 1 code
 * @param {number} attribute2Id the attribute 2 identifier
 * @param {string} attribute2Code the attribute 2 code
 * @returns {string} a "variant string" containing `color/size`
 */
const getVariant = (attribute1Id, attribute1Code, attribute2Id, attribute2Code) => {
	const attributes = [];

	if (attribute1Id && attribute1Code) {
		attributes.push(attribute1Code);
	}

	if (attribute2Id && attribute2Code) {
		attributes.push(attribute2Code);
	}

	if (attributes.length > 0) {
		return attributes.join('/');
	}

	return attribute1Code;
};

/**
 * Finds a article category name from article categories and returns it.
 *
 * @param {array} articleCategories the article categories to search in
 * @returns {string} the article category name
 */
const getArticleCategoryName = articleCategories => {
	if (!articleCategories || articleCategories.length === 0) {
		return undefined;
	}

	const defaultCategory = articleCategories.find(category => category.default_flg === true);

	if (defaultCategory && defaultCategory.category) {
		return defaultCategory.category.catname;
	}

	return articleCategories[0] && articleCategories[0].category ? articleCategories[0].category.catname : undefined;
};

window.dataLayer = window.dataLayer || [];
window.dataLayer.push({
	ecommerce: {
		currencyCode: window._streamline.siteContext.currency_code
	}
});

export const setOrigin = () => window.performance.navigation.type !== 1 && analytics.track({originalLocation: `${window.location.origin}${window.location.pathname}${window.location.search}`});

export const getClientId = async app => window.ga && Promise.race([
	analytics.getClientId(),
	new Timeout().set(2000, 'Timeout while fetching Google analytics client ID')
]).then(id => app.sources.store.dispatch(setGoogleAnalyticsId(id))).catch(error => console.warn(error.message));

export const trackAddToCart = obj => {
	analytics.enhanced.addToCart({
		name: obj.body.artname,
		category: getArticleCategoryName(obj.body.articleCategories),
		brand: obj.body.brand && obj.body.brand.name,
		id: obj.body.art_no,
		identifier: obj.sku.attr1_id && `${obj.body.art_no}-${obj.sku.attr1_id}`,
		image: obj.body.articleAttributeMedias && obj.body.articleAttributeMedias[0].media.filename,
		price: obj.sku.articlePrice.price_sales,
		quantity: obj.qty,
		url: obj.body.link_friendly_name,
		variant: obj.sku && obj.sku.attribute1 ? getVariant(obj.sku.attribute1.attr1_id, obj.sku.attribute1.attr1_code, obj.sku.attribute2.attr2_id, obj.sku.attribute2.attr2_code) : undefined
	});
};

export const trackArticle = obj => {
	analytics.enhanced.detail({
		name: obj.body.artname,
		category: getArticleCategoryName(obj.body.articleCategories),
		brand: obj.body.brand && obj.body.brand.name,
		id: obj.body.art_no,
		identifier: `${obj.body.art_no}-${obj.sku.attr1_id}`,
		image: 'https://media.viskan.com/v2/goodformeprod/normal/' + obj.body.articleAttributeMedias[0].media.filename,
		price: obj.sku.articlePrice.price_sales,
		priceOrg: obj.sku.articlePrice.price_org > 0 ? obj.sku.articlePrice.price_org : obj.sku.articlePrice.price_sales,
		variant: obj.sku && obj.sku.attribute1 ? getVariant(obj.sku.attribute1.attr1_id, obj.sku.attribute1.attr1_code, obj.sku.attribute2.attr2_id, obj.sku.attribute2.attr2_code) : undefined
	});
};

export const trackImpression = (articles, {from} = {from: 0}) => {
	let index = from;

	analytics.enhanced.impression(articles.map(x => {
		const sku = x.articleAttributes[0];
		index++;

		return {
			name: x.artname,
			brand: x.brand && x.brand.name,
			id: x.art_no,
			identifier: `${x.art_no}-${sku.attr1_id}`,
			price: sku && sku.articlePrice.price_sales,
			list: window.location.pathname,
			campaign: sku && getCampaign(x.articleCampaigns, sku.attr1_id),
			position: index,
			variant: sku && sku.attribute1 ? getVariant(sku.attribute1.attr1_id, sku.attribute1.attr1_code, sku.attribute2.attr2_id, sku.attribute2.attr2_code) : undefined
		};
	}));
};

let sendReferrer = true;

const getOriginalReferrer = () => {
	const query = queryString.parse(location.search);
	const source = document.referrer && query.referrer !== undefined ? query : document;

	return source.referrer;
};

export const trackPageView = url => {
	const referrer = sendReferrer ? getOriginalReferrer() : null;

	analytics.enhanced.pageView(url, {
		referrer
	});
	sendReferrer = false;
};

export const trackProductClick = obj => () => {
	const sku = obj.articleAttributes[0];

	analytics.enhanced.productClick({
		callback: noop,
		name: obj.artname,
		brand: obj.brand && obj.brand.name,
		id: obj.art_no,
		identifier: `${obj.art_no}-${sku.attr1_id}`,
		price: sku.articlePrice.price_sales,
		variant: sku && sku.attribute1 ? getVariant(sku.attribute1.attr1_id, sku.attribute1.attr1_code, sku.attribute2.attr2_id, sku.attribute2.attr2_code) : undefined
	}, {
		list: window.location.pathname
	});
};

export const trackPurchase = receipt => {
	const rows = receipt.rows.concat(receipt.subscriptions).map(row => ({
		coupon: row.offerDescription,
		category: row.meta.defaultCategory ? row.meta.defaultCategory : '',
		id: row.meta.articleNumber,
		identifier: `${row.meta.articleNumber}-${row.meta.attribute1Id}`,
		name: row.meta.articleName,
		price: row.value.total / row.quantity,
		quantity: row.quantity,
		variant: getVariant(row.meta.attribute1Id, row.meta.attribute1Name, row.meta.attribute2Id, row.meta.attribute2Id)
	}));

	analytics.enhanced.purchase(rows, {
		affiliation: window.location.host,
		coupon: receipt.offerCodes.join(', '),
		id: receipt.orderNumber || receipt.identifier,
		revenue: receipt.value.total,
		shipping: receipt.deliveryMethod && receipt.deliveryMethod.methodName
	});
};

export const trackRemoveFromCart = obj => {
	analytics.enhanced.removeFromCart({
		name: obj.body.artname,
		id: obj.body.art_no,
		price: obj.sku.articlePrice.price_sales,
		quantity: obj.qty,
		variant: getVariant(obj.body.attr1_id, obj.body.attr1_code, obj.body.attr2_id, obj.body.attr2_code)
	});
};

export const trackShopcart = (shopcart, site) => {
	if (!shopcart.head.shopcart_id_str) {
		// Don't bother to track an non-existing shopcart
		return;
	}

	window.dataLayer = window.dataLayer || [];
	window.dataLayer.push({
		event: 'shopcartChange',
		shopcart: {
			id: shopcart.head.shopcart_id_str,
			locale: site.locale,
			total: shopcart.head.tot_rowsum_to_pay,
			items: shopcart.rows.map(item => ({
				itemId: item.art_no,
				image: item.media_filename,
				name: item.artname,
				price: item.price_incvat,
				quantity: item.qty,
				linkFriendlyName: item.link_friendly_name,
				attr1Id: item.attr1_id,
				variant: getVariant(item.attr1_id, item.attr1_code, item.attr2_id, item.attr2_code)
			})),
			klaviyoItems: shopcart.rows.map(item => ({
				ProductID: item.art_no,
				ImageURL: `https://media.viskan.com/v2/goodformeprod/large/${item.media_filename}`,
				ProductName: item.artname,
				ItemPrice: item.price_incvat,
				Quantity: item.qty,
				RowTotal: item.qty * item.price_incvat,
				ProductURL: `https://goodforme.com/${site.country_code.toLowerCase()}/${item.link_friendly_name}`,
				SKU: getVariant(item.attr1_id, item.attr1_code, item.attr2_id, item.attr2_code),
				ProductCategories: ['eStore']
			}))
		}
	});
};

export const trackCheckout = obj => {
	const arr = [];

	if (obj.rows && obj.rows.length !== 0) {
		obj.rows.forEach(x => {
			arr.push({
				coupon: obj.head.camp_code || '',
				id: x.art_no,
				identifier: `${x.art_no}-${x.attr1_id}`,
				name: x.artname,
				price: x.price_incvat,
				variant: getVariant(x.attr1_id, x.attr1_code, x.attr2_id, x.attr2_code),
				quantity: x.qty
			});
		});
	}

	analytics.enhanced.checkout(arr, {
		callback: noop
	});
};

export default async app => {
	analytics.start().then(() => getClientId(app));

	app.set('trackAddToCart', trackAddToCart);
	app.set('trackPageView', trackPageView);
	app.set('trackProductClick', trackProductClick);
	app.set('trackPurchase', trackPurchase);
	app.set('trackRemoveFromCart', trackRemoveFromCart);
	app.set('trackCheckout', trackCheckout);
	app.set('trackShopcart', trackShopcart);
};
