export default node => ({
	type: 'LIPSCORE_WIDGET',
	name: 'Lipscore WIDGET',
	allowedSections: ['main', 'article'],
	disallowedChildren: ['*'],
	category: 'Plugins',
	props: [{
		name: 'show',
		inputType: 'checkbox',
		label: 'Show widget',
		default: 'true'
	}, {
		name: 'type',
		inputType: 'select',
		label: 'Rating widget type',
		values: [{
			label: 'Testimonial widget',
			value: 'lipscore-service-review-testimonial'
		}, {
			label: 'Review list widget',
			value: 'lipscore-review-list'
		}, {
			label: 'Review post widget',
			value: 'lipscore-review-post'
		}, {
			label: 'Standard rating widget',
			value: 'lipscore-rating'
		}, {
			label: 'Small rating widget',
			value: 'lipscore-rating-small'
		}, {
			label: 'Rating slider widget',
			value: 'lipscore-rating-slider'
		}],
		default: 'lipscore-rating'
	}, node && node.props.type === 'lipscore-service-review-testimonial' && {
		name: 'height',
		inputType: 'text',
		label: 'Testimonial widget height',
		default: ''
	}, node && node.props.type === 'lipscore-service-review-testimonial' && {
		name: 'width',
		inputType: 'text',
		label: 'Testimonial widget width',
		default: ''
	}].filter(Boolean)
});
