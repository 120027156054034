import {RECIEVE_SITE, SITE_FETCH_FULFILLED} from '../action-types';

const initialState = window._streamline.siteContext;

export default (state = initialState, action) => {
	switch (action.type) {
		case RECIEVE_SITE: {
			return {
				...state,
				...action.data
			};
		}
		case SITE_FETCH_FULFILLED: {
			return {
				...state,
				...action.payload
			};
		}
		default: {
			return state;
		}
	}
};
