import {
	ARTICLES_SLIDER_FETCH_FULFILLED
} from '../../action-types';

export const initialState = {
	components: {}
};

export default (state = initialState, action) => {
	switch (action.type) {
		case ARTICLES_SLIDER_FETCH_FULFILLED: {
			return {
				...state,
				components: {
					...state.components,
					[action.payload.id]: {
						articles: action.payload.articles
					}
				}
			};
		}
		default: {
			return state;
		}
	}
};

