import {type} from '@viskan/streamline-utilities';

export const getIcon = ({class: className, icon, iconColor, iconSize, iconClass, label, onClick}) => {
	if (!icon) {
		return label ? label : <noscript/>;
	}

	try {
		const parsedIcon = JSON.parse(icon);
		const fontSize = (iconSize && iconSize.replace(',', '.')) || '';
		const styles = {
			fontSize: `${fontSize}`,
			color: `${iconColor}`
		};

		return (
			<div class={className} style={styles} onClick={onClick}>
				<i key={`${parsedIcon.x.name}${parsedIcon.prefix}`} class={`${parsedIcon.prefix} fa-${parsedIcon.x.name} ${iconClass}`}/>
			</div>
		);
	} catch (error) {
		return <noscript/>;
	}
};

export const getMediaRowIds = (image, imageOnHover) => {
	const imageIsValid = !type.isUndefined(image);
	const hoverImageIsValid = !type.isUndefined(imageOnHover) && imageOnHover !== '-1' && imageOnHover !== 'none';

	if (hoverImageIsValid && imageIsValid && imageOnHover !== image) {
		return `[${image},${imageOnHover}]`;
	}

	return imageIsValid ? `[${image}]` : '';
};
