import breakpoints from '../../breakpoints';

export const BREAKPOINT_ELEMENT_SELECTOR = '.Breakpoint-value';

export const getStylePropertyValue = (el, prop = 'font-family') => window.getComputedStyle(el).getPropertyValue(prop);

const afterMount = ({props}) => {
	const {setBreakpoint} = props;
	const breakpointValueEl = document.querySelector(BREAKPOINT_ELEMENT_SELECTOR);
	let ticking = false;

	setBreakpoint(getStylePropertyValue(breakpointValueEl));

	window.addEventListener('resize', () => {
		if (!ticking) {
			window.requestAnimationFrame(() => {
				setBreakpoint(getStylePropertyValue(breakpointValueEl, 'font-family'));
				ticking = false;
			});
		}

		ticking = true;
	});
};

const render = ({props}) => {
	const {breakpoint, breakpointOverride, getIntValue, progressive} = props;
	const breakpointValue = getIntValue(breakpointOverride ? breakpointOverride : breakpoint, breakpoints);
	const breakpointClasses = progressive ? breakpoints.map(x => {
		if (x.width <= breakpointValue) {
			return `Breakpoint--${x.id}`;
		}

		return '';
	}) : [`Breakpoint--${breakpoint}`];

	return (
		<div class={['Breakpoint', breakpointClasses, props.class]}>
			<div class='Breakpoint-children'>{props.children}</div>
		</div>
	);
};

export default {afterMount, render};
