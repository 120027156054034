import {deepEqual} from 'fast-equals';
import {handleCancel} from '../utils';
import Spinner from './spinner';

const shouldUpdate = ({props, state}, nextProps, nextState) => !deepEqual(props, nextProps) || !deepEqual(state, nextState);

const render = ({props}) => {
	if (props.errorCode.endsWith('canceled-externally')) {
		handleCancel(props.purchaseNumber, props.pathPrefix, props.checkoutUrl)();

		return <Spinner/>;
	}

	const error = props.getTranslation(`purchase.error.${props.errorCode}`, {fallback: props.getTranslation('purchase.error.unknown')});

	return (
		<div class={['PurchaseError', props.class]}>
			<div class='PurchaseError-error'>
				{error}
			</div>
			<button class='Purchase-cancelButton Preset-Primary-Button' type='button' onClick={handleCancel(props.purchaseNumber, props.pathPrefix)}>
				{props.getTranslation('purchase.polling.go_to_checkout')}
			</button>
		</div>
	);
};

export default {render, shouldUpdate};
