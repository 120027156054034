import {connectWrap as connect} from '@viskan/deku-redux';
import {fetchArticle} from './actions';
import BaseComponent from './components';

export default connect(
	({articleStore, site, shopcart}) => ({
		articleStore,
		club_id: site.club_id,
		shopcart
	}),
	() => ({
		fetchArticle
	})
)(BaseComponent);
