import {
	STORE_BALANCE_FETCH_FULFILLED,
	STORE_BALANCE_SEARCH,
	STORE_BALANCE_STORE_SET,
	STORES_FETCH_FULFILLED
} from '../../../action-types';

export const initialState = {
	storeBalance: [],
	searchResult: [],
	stores: []
};

export default (state = initialState, action) => {
	switch (action.type) {
		case STORE_BALANCE_FETCH_FULFILLED: {
			return {
				...state,
				storeBalance: action.payload
			};
		}
		case STORE_BALANCE_SEARCH: {
			return {
				...state,
				searchResult: action.payload
			};
		}
		case STORE_BALANCE_STORE_SET: {
			return {
				...state,
				selectedStore: action.payload
			};
		}
		case STORES_FETCH_FULFILLED: {
			return {
				...state,
				stores: action.payload
			};
		}
		default: {
			return state;
		}
	}
};
