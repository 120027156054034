export default {
	type: 'ARTICLE_BUY_BUTTON',
	name: 'Buy button',
	allowedSections: ['article'],
	disallowedChildren: ['*'],
	category: 'Article',
	props: [{
		name: 'buttonText',
		inputType: 'text',
		label: 'Text',
		default: 'Add to cart'
	}, {
		name: 'goToCheckout',
		inputType: 'checkbox',
		label: 'Go to checkout after add',
		default: false
	}, {
		name: 'addSubscription',
		inputType: 'checkbox',
		label: 'Add selected subscription',
		description: 'Requires that the purchase layout setting in the Subscription component is set to "Use buy button component"',
		default: false
	}, {
		name: 'disableBuyButton',
		inputType: 'checkbox',
		label: 'Disable buy button after subscription has been added to cart',
		default: false
	}],
	hiddenProps: [{
		label: 'Out of stock settings',
		props: [{
			name: 'outOfStockButtonText',
			inputType: 'text',
			label: 'Text',
			default: 'Out of stock'
		}, {
			name: 'outOfStockBackgroundColor',
			inputType: 'color',
			label: 'Button background color',
			default: '#777'
		}, {
			name: 'outOfStockColor',
			inputType: 'color',
			label: 'Text color',
			default: '#fff'
		}, {
			name: 'hideOutOfStockButton',
			inputType: 'checkbox',
			label: 'Hide button',
			default: false
		}, {
			name: 'outOfStockMessage',
			inputType: 'text',
			label: 'Out of stock message',
			default: ''
		}, {
			name: 'enableIncomingInStock',
			inputType: 'checkbox',
			label: 'Enable incoming in stock',
			description: 'When article have attribute incoming the buy button will be disabled and display the text selected in next option',
			default: false
		}, {
			name: 'incomingInStockText',
			inputType: 'text',
			label: 'Text for incoming in stock',
			default: 'Coming soon'
		}]
	}, {
		label: 'Advanced settings',
		props: [{
			name: 'class',
			inputType: 'text',
			label: 'CSS-class',
			default: ''
		}]
	}]
};
