export default node => ({
	type: 'ARTICLE_STOCK_STATUS',
	name: 'Stock status',
	allowedSections: ['article'],
	disallowedChildren: ['*'],
	category: 'Article',
	icon: 'fal fa-container-storage',
	styleClasses: {
		Wrapper: ''
	},
	props: [{
		name: 'showStockBasedOn',
		inputType: 'select',
		label: 'Choose parameter to base stock on',
		default: 'stockStatus',
		values: [{
			label: 'Stock status',
			value: 'stockStatus'
		}, {
			label: 'Stock balance',
			value: 'stockBalance'
		}]
	}, node && node.props.showStockBasedOn === 'stockBalance' && {
		name: 'stockBalanceSuffix',
		inputType: 'text',
		label: 'Stock balance suffix',
		default: ''
	}, {
		name: 'inStockSuffix',
		inputType: 'text',
		label: 'In stock label',
		default: 'In stock'
	}, {
		name: 'inStockIcon',
		inputType: 'icon',
		label: 'Choose icon when in stock',
		default: false
	}, {
		name: 'inStockIconSize',
		inputType: 'text',
		label: 'Icon size',
		default: '1em'
	}, {
		name: 'inStockIconColor',
		inputType: 'color',
		label: 'Icon color',
		default: '#009ABF'
	}, {
		name: 'outOfStockSuffix',
		inputType: 'text',
		label: 'Out of stock label',
		default: 'Out of stock'
	}, {
		name: 'outOfStockIcon',
		inputType: 'icon',
		label: 'Choose icon when out of stock',
		default: false
	}, {
		name: 'outOfStockIconSize',
		inputType: 'text',
		label: 'Icon size',
		default: '1em'
	}, {
		name: 'outOfStockIconColor',
		inputType: 'color',
		label: 'Icon color',
		default: '#009ABF'
	}, {
		name: 'incomingInStockSuffix',
		inputType: 'text',
		label: 'Incoming in stock label',
		default: 'Coming soon'
	}, {
		name: 'incomingInStockIcon',
		inputType: 'icon',
		label: 'Choose icon when incoming in stock',
		default: false
	}, {
		name: 'incomingInStockIconSize',
		inputType: 'text',
		label: 'Icon size',
		default: '1em'
	}, {
		name: 'incomingInStockIconColor',
		inputType: 'color',
		label: 'Icon color',
		default: '#009ABF'
	}].filter(Boolean),
	hiddenProps: []
});
