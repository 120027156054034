export default [{
	label: 'Store',
	settings: [{
		description: 'Presented as a page title suffix',
		label: 'Company Name*',
		settingKey: 'companyName',
		type: 'text',
		defaultValue: '',
		languageSpecific: true
	}, {
		description: 'Select which media type you use as favicon',
		label: 'Favicon type*',
		settingKey: 'favIconMediaType',
		type: 'select',
		defaultValue: 'png',
		languageSpecific: false,
		options: [
			{
				label: 'PNG',
				value: 'png'
			}, {
				label: 'SVG',
				value: 'svg'
			}
		]
	}, {
		description: 'Remember to change path of the search page in "Pages"',
		label: 'Search Url Prefix',
		settingKey: 'searchUrlPrefix',
		type: 'text',
		defaultValue: 'search',
		languageSpecific: true
	}, {
		description: 'Insert your custom favicon name. If you have selected favicon type PNG you have to upload three favicon images that end with the file name `-16x16`, `-24x24` and `-32x32`.',
		label: 'Favicon name*',
		removeOnEmpty: true,
		settingKey: 'favIconName',
		type: 'text',
		defaultValue: 'favicon',
		languageSpecific: false
	}, {
		label: 'Allow persons to shop*',
		settingKey: 'isPersonAllowed',
		type: 'checkbox',
		defaultValue: true
	}, {
		label: 'Allow businesses to shop*',
		settingKey: 'isBusinessAllowed',
		type: 'checkbox',
		defaultValue: false
	}, {
		label: 'Enable remarketing*',
		settingKey: 'remarketing',
		type: 'checkbox',
		defaultValue: false
	}, {
		label: 'Empty shopping cart when logging in on my pages*',
		settingKey: 'emptyCartOnAuth',
		type: 'checkbox',
		defaultValue: false
	}, {
		label: 'Empty shopping cart when logging out of my pages*',
		settingKey: 'emptyCartOnLogout',
		type: 'checkbox',
		defaultValue: ''
	}]
}, {
	label: 'Third party',
	settings: [{
		description: 'Used for Google Tag Manager tracking',
		label: 'Google Tag Manager ID*',
		settingKey: 'googleTagManagerId',
		type: 'text',
		defaultValue: '',
		languageSpecific: true
	}, {
		description: 'Used for Google Analytics backend tracking',
		label: 'Google Analytics ID',
		settingKey: 'googleAnalyticsId',
		type: 'text',
		defaultValue: '',
		languageSpecific: true
	}, {
		description: 'Insert your meta tag verification string (id only) to verify your site',
		label: 'Google Site Verification*',
		removeOnEmpty: true,
		settingKey: 'googleSiteVerification',
		type: 'text',
		defaultValue: ''
	},
	{
		description: 'Insert your meta tag verification string (id only) to verify your site',
		label: 'Facebook Site Verification*',
		removeOnEmpty: true,
		settingKey: 'facebookSiteVerification',
		type: 'text',
		defaultValue: ''
	}, {
		description: 'Used for Apsis newsletter API authentication',
		label: 'Apsis API key',
		settingKey: 'hidden-apsisApiKey',
		type: 'text',
		defaultValue: ''
	}, {
		description: 'Used for Mailchimp newsletter API authentication',
		label: 'Mailchimp API key',
		settingKey: 'hidden-mailchimpApiKey',
		type: 'text',
		defaultValue: ''
	}, {
		description: 'Used for Voyado newsletter API authentication',
		label: 'Voyado API key',
		settingKey: 'hidden-voyadoApiKey',
		type: 'text',
		defaultValue: ''
	}]
}, {
	label: 'Articles',
	settings: [{
		description: 'Sets the prefix for article detail page',
		label: 'Article URL Prefix',
		settingKey: 'articleUrlPrefix',
		type: 'text',
		languageSpecific: true,
		defaultValue: 'article'
	}, {
		description: 'Will enable to show a unique category section on article',
		label: 'Load category on article page',
		settingKey: 'loadCategoryOnArticle',
		type: 'checkbox',
		defaultValue: false
	}, {
		description: 'Color filter on product listings will be grouped by similar colors',
		label: 'Load product filter colors as a group',
		settingKey: 'aggregateAttribute1Group',
		type: 'checkbox',
		defaultValue: false
	}, {
		description: 'Size filter on product listings will be grouped by similar sizes',
		label: 'Load product filter sizes as a group',
		settingKey: 'aggregateAttribute2Group',
		type: 'checkbox',
		defaultValue: false
	}, {
		description: `The number of days that an article is considered new since it's activation date`,
		label: 'Days as brand new',
		settingKey: 'daysAsBrandNew',
		type: 'number',
		min: '0',
		removeOnEmpty: true,
		defaultValue: 30
	}, {
		description: 'Use different article layout for Quickbuy popup',
		label: 'Seperate Quickbuy layout',
		settingKey: 'useArticleQuickBuyLayout',
		type: 'checkbox',
		languageSpecific: false,
		defaultValue: false
	}, {
		description: 'Exclude out of stock articles from listings and sliders',
		label: 'Hide articles out of stock',
		settingKey: 'excludeOutOfStockListArticles',
		type: 'checkbox',
		defaultValue: true
	}, {
		description: 'Adds current picked filter to URL for easy sharing',
		label: 'Add filter to URL*',
		settingKey: 'addArticleFilterToUrl',
		type: 'checkbox',
		defaultValue: false
	}, {
		description: 'Article has to have media to be visible on site',
		label: 'Enable checkMedia*',
		settingKey: 'checkMediaEnabled',
		type: 'checkbox',
		defaultValue: true
	}]
}, {
	label: 'Checkout',
	settings: [{
		description: 'Sets the URL path for checkout page',
		label: 'Checkout URL Path',
		settingKey: 'checkoutUrl',
		type: 'text',
		languageSpecific: true,
		defaultValue: 'checkout'
	}, {
		description: 'Remember to change path of the receipt page in "Pages"',
		label: 'Receipt Url path',
		settingKey: 'receiptUrlPath',
		type: 'text',
		defaultValue: 'receipt',
		languageSpecific: true
	}, {
		description: 'Remember to change path of the terms page in "Pages"',
		label: 'Terms Url path',
		settingKey: 'termsUrlPath',
		type: 'text',
		defaultValue: 'terms-and-conditions',
		languageSpecific: true
	}, {
		description: 'Blocks ZIP codes in Klarna matching the regex (for example "200.*")',
		label: 'Forbidden ZIP code pattern for Klarna Checkout*',
		settingKey: 'klarnaForbiddenZipCodePattern',
		type: 'text',
		languageSpecific: true,
		defaultValue: ''
	}, {
		description: 'Will enable "Klarna Shipping Assistant" and disable Viskan\'s delivery method selection',
		label: 'Enable Klarna Shipping Assistant*',
		settingKey: 'klarnaShippingAssistantEnabled',
		type: 'checkbox',
		defaultValue: false
	}]
}, {
	label: 'Advanced',
	settings: [{
		label: 'Enable ArticleStore*',
		settingKey: 'articleStoreEnabled',
		type: 'checkbox',
		defaultValue: false
	}, {
		description: 'Will fetch shopcart from last time customer was logged in',
		label: 'Use persistent shopcarts',
		settingKey: 'persistentShopcart',
		type: 'checkbox',
		defaultValue: false
	}]
}];
