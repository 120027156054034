import editorLayout from './editor-layout';

export default node => ({
	type: 'SEARCH_FORM',
	name: 'Search',
	allowedSections: ['header'],
	disallowedChildren: ['*'],
	category: 'Header',
	icon: 'fal fa-search',
	editorLayout,
	styleClasses: {
		SearchForm: '',
		SearchInput: '',
		Icon: '',
		Image: '',
		Dropdown: '',
		Menu: '',
		Article: 'Preset-SearchForm-Article',
		ImageWrapper: 'Preset-SearchForm-ImageWrapper',
		InfoWrapper: 'Preset-SearchForm-InfoWrapper',
		Title: '',
		Price: '',
		ResultLink: ''
	},
	props: [{
		name: 'searchEngine',
		inputType: 'select',
		label: 'Search engine',
		default: 'viskanEtm',
		values: [{
			label: 'Viskan ETM',
			value: 'viskanEtm'
		}, {
			label: 'Hello Retail Search',
			value: 'helloRetail'
		}]
	}, node && node.props.searchEngine === 'helloRetail' && {
		name: 'helloRetailSearchKey',
		inputType: 'text',
		label: 'Hello Retail Search Key',
		default: ''
	}, {
		name: 'placeholder',
		inputType: 'text',
		label: 'Search placeholder',
		default: 'Search...'
	}, {
		name: 'maxNumberOfArticles',
		inputType: 'number',
		label: 'Maximum number of articles in result',
		default: 5
	}, {
		name: 'scale',
		inputType: 'select',
		label: 'Image scale on search result',
		description: 'Select the smallest scale possible to increase page speed',
		default: 'thumbnail',
		values: [{
			label: 'Original',
			value: 'original'
		}, {
			label: 'Large',
			value: 'large'
		}, {
			label: 'Normal',
			value: 'normal'
		}, {
			label: 'List',
			value: 'list'
		}, {
			label: 'Thumbnail',
			value: 'thumbnail'
		}]
	}, {
		name: 'icon',
		inputType: 'icon',
		label: 'Choose an icon',
		required: true,
		default: false
	}, {
		name: 'iconSize',
		inputType: 'text',
		label: 'Size on icon',
		default: '1em'
	}, {
		name: 'iconColor',
		inputType: 'color',
		label: 'Icon color',
		default: '#009ABF'
	}, {
		name: 'image',
		inputType: 'media-v2',
		label: 'Choose an image',
		default: ''
	}, {
		name: 'splitByAttribute1',
		inputType: 'checkbox',
		label: 'Split articles by color',
		default: true
	}, {
		name: 'autofocus',
		inputType: 'checkbox',
		label: 'Autofocus search input',
		default: false
	}, {
		name: 'showAllResultsLink',
		inputType: 'checkbox',
		label: 'Show all results link',
		default: false
	}].filter(Boolean)
});
