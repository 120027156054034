export default node => ({
	type: 'ARTICLE_BACK_IN_STOCK',
	name: 'Back in stock',
	allowedSections: ['article'],
	disallowedChildren: ['*'],
	category: 'Remarketing',
	styleClasses: {
		Wrapper: '',
		Text: 'Preset-Primary-Font-Family Preset-Primary-Text Preset-Primary-Color',
		Description: 'Preset-Primary-Font-Family Preset-Primary-Text Preset-Primary-Color',
		Button: 'Preset-Primary-Button'
	},
	props: [{
		name: 'text',
		inputType: 'text',
		label: 'Text',
		default: 'Out of stock'
	}, {
		name: 'showDelDat',
		inputType: 'checkbox',
		label: 'Show back in stock date',
		default: false
	}, node && node.props.showDelDat && {
		name: 'delDatPrefix',
		inputType: 'text',
		label: 'Back in stock date prefix',
		default: 'Will be back in stock'
	}, {
		name: 'description',
		inputType: 'textarea',
		label: 'Description',
		default: ''
	}, {
		name: 'feedbackText',
		inputType: 'text',
		label: 'Feedback text',
		default: 'You\'ll be notified'
	}, {
		name: 'displayAs',
		inputType: 'select',
		label: 'Display as',
		default: 'popup',
		values: [{
			label: 'Popup button',
			value: 'popup'
		}, {
			label: 'Input field',
			value: 'input'
		}]
	}].filter(Boolean),
	hiddenProps: [{
		label: 'Popup settings',
		props: [{
			name: 'openPopupText',
			inputType: 'text',
			label: 'Button text to open popup',
			default: 'Yes please'
		}, {
			name: 'popupText',
			inputType: 'text',
			label: 'Popup text',
			default: 'Lorem ipsum..'
		}, {
			name: 'buttonText',
			inputType: 'text',
			label: 'Button text',
			default: 'OK'
		}]
	}, {
		label: 'Advanced settings',
		props: [{
			name: 'class',
			inputType: 'text',
			label: 'CSS-class',
			default: ''
		}]
	}]
});
