import traverse from 'traverse';
import {url as urlUtils} from '@viskan/streamline-utilities';
import breadcrumbsFilter from './breadcrumbs-filter';

const getUrl = (linkFriendlyName, site) => {
	const prefix = urlUtils.getLocaleFriendlyPathPrefix(site.lang_code_web, site.country_code, site.localeMode);

	return `${window.location.origin}${prefix}${linkFriendlyName}`;
};

const getListItems = (tree, activeCategoryId, site) => {
	let returnValue = [];

	const extractData = traverseObj => {
		if (traverseObj && traverseObj.node && 'categoryId' in traverseObj.node && !breadcrumbsFilter.includes(traverseObj.node.linkFriendlyName) && !traverseObj.node.hidden) {
			returnValue = [{
				'@type': 'ListItem',
				'name': traverseObj.node.categoryName,
				'item': getUrl(traverseObj.node.linkFriendlyName, site)
			}, ...returnValue];
		}

		if (traverseObj && traverseObj.parent) {
			extractData(traverseObj.parent);
		}
	};

	traverse(tree).forEach(function (node) {
		if (node && node.categoryId === activeCategoryId) {
			extractData(this);

			this.stop();
		}
	});

	return returnValue.map((item, index) => ({
		...item,
		position: index + 1
	}));
};

export const getBreadcrumbsMarkup = (tree, activeCategoryId, site) => {
	if (!activeCategoryId) {
		return '';
	}

	let breadcrumbsString;
	const json = [{
		'@type': 'BreadcrumbList',
		'itemListElement': getListItems(tree, activeCategoryId, site)
	}];

	try {
		breadcrumbsString = JSON.stringify(json);
	} catch (error) {
		breadcrumbsString = '';
	}

	return breadcrumbsString;
};
