import {deepEqual} from 'fast-equals';
import components from '../cms-components';

const beforeMount = ({props}) => {
	const {cmsTokenSet} = props;

	if (!cmsTokenSet) {
		return;
	}

	props.ensureCmsIsLoaded();
};

const shouldUpdate = ({props}, nextProps) => !deepEqual(props, nextProps);

const render = ({props}) => {
	const {loaded} = props;

	if (loaded) {
		const StreamlineCms = window._streamlineCms;
		return <StreamlineCms components={components}/>;
	}

	return <noscript/>;
};

export default {beforeMount, render, shouldUpdate};
