import {ARTICLE_BACK_IN_STOCK_REQUEST_FULFILLED, ARTICLE_SKU_SET} from '../../../action-types';

export const initialState = {
	requested: false
};

export default (state = initialState, action) => {
	switch (action.type) {
		case ARTICLE_BACK_IN_STOCK_REQUEST_FULFILLED: {
			return {
				...state,
				requested: true
			};
		}
		case ARTICLE_SKU_SET: {
			return initialState;
		}
		default: {
			return state;
		}
	}
};
