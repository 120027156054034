import mergeOptions from 'merge-options';
import {
	CATEGORY_FETCH_FULFILLED,
	CATEGORY_FETCH_PENDING,
	CATEGORY_FETCH_REJECTED,
	CMS_DRAFT_CATEGORY_FETCH_FULFILLED,
	CMS_DRAFT_CATEGORY_POST_FULFILLED,
	CMS_NODE_DELETE,
	CMS_NODE_INSERT,
	CMS_NODE_MOVE,
	CONTENT_RESET,
	CONTENT_CLEAR,
	CONTENT_SET,
	CONTENT_SET_FULFILLED,
	PATH_CHANGE,
	SET_COMPONENT_PROPERTY
} from '../action-types';
import * as contentApi from './content';

const defaultCategoryState = {content: [], notFound: false};

const initialState = {
	category: {
		article: defaultCategoryState,
		articleQuickBuy: defaultCategoryState,
		header: defaultCategoryState,
		footer: defaultCategoryState,
		main: defaultCategoryState
	},
	cssNamespace: {
		content: 'App-content'
	},
	initialCategory: {
		article: defaultCategoryState,
		articleQuickBuy: defaultCategoryState,
		header: defaultCategoryState,
		footer: defaultCategoryState,
		main: defaultCategoryState
	},
	categoryIsPending: true,
	isCrawler: /bot|crawler|crawling|googlebot|robot|spider/i.test(navigator.userAgent.toLowerCase()),
	latestCategoryId: -1,
	pathname: undefined
};

const getPreloadedStateObject = (key, category) => {
	if (!category[key] || typeof category[key].content !== 'string' || category[key].content === '') {
		return defaultCategoryState;
	}

	return {
		...category[key],
		content: JSON.parse(category[key].content)
	};
};

export default (state, action) => {
	if (!state) {
		return initialState;
	}

	if (!state.hydrated) {
		try {
			const articleObject = getPreloadedStateObject('article', state.category);
			const articleQuickBuyObject = getPreloadedStateObject('articleQuickBuy', state.category);
			const headerObject = getPreloadedStateObject('header', state.category);
			const mainObject = getPreloadedStateObject('main', state.category);
			const footerObject = getPreloadedStateObject('footer', state.category);

			state = mergeOptions({}, initialState, {
				category: {
					article: articleObject,
					articleQuickBuy: articleQuickBuyObject,
					header: headerObject,
					main: mainObject,
					footer: footerObject
				},
				initialCategory: {
					article: articleObject,
					articleQuickBuy: articleQuickBuyObject,
					header: headerObject,
					main: mainObject,
					footer: footerObject
				},
				hydrated: true
			});
		} catch (error) {
			state.hydrated = true;
			v12.util.error(error);
		}
	}

	switch (action.type) {
		case CONTENT_SET: {
			return {
				...state,
				category: {
					...state.category,
					[action.payload.section]: {
						...state.category[action.payload.section],
						content: action.payload.content,
						componentPaths: contentApi.getPaths(action.payload.content)
					}
				}
			};
		}
		case CONTENT_CLEAR: {
			return {
				...state,
				category: {
					...state.category,
					[action.payload.section]: defaultCategoryState
				},
				initialCategory: {
					...state.initialCategory,
					[action.payload.section]: defaultCategoryState
				}
			};
		}
		case CONTENT_RESET: {
			return {
				...state,
				category: {
					...state.initialCategory
				}
			};
		}
		case CONTENT_SET_FULFILLED: {
			return {
				...state,
				category: {
					...state.category,
					[action.payload.section]: {
						...state.category[action.payload.section],
						content: action.payload.content,
						componentPaths: contentApi.getPaths(action.payload.content)
					}
				}
			};
		}
		case CMS_NODE_DELETE: {
			const {node, sectionId} = action.payload;
			const {content} = state.category[sectionId];
			const newContent = contentApi.deleteNode(content, node.id);

			return {
				...state,
				category: {
					...state.category,
					[sectionId]: {
						...state.category[sectionId],
						content: newContent,
						componentPaths: contentApi.getPaths(newContent)
					}
				}
			};
		}
		case CMS_NODE_INSERT: {
			const {node, sectionId, toIndex = 0, toId} = action.payload;
			const {content} = state.category[sectionId];
			const newContent = contentApi.insertNode(content, toId, node, {childIndex: toIndex});

			return {
				...state,
				category: {
					...state.category,
					[sectionId]: {
						...state.category[sectionId],
						content: newContent,
						componentPaths: contentApi.getPaths(newContent)
					}
				}
			};
		}
		case CMS_NODE_MOVE: {
			const {node, sectionId, toIndex = 0, toId} = action.payload;
			const {content, componentPaths: paths} = state.category[sectionId];
			const parent = contentApi.getParentByPath(content, paths[node.id]);
			const parentId = parent ? parent.id : 0;
			const targetId = typeof toId === 'undefined' ? parentId : toId;
			const newContent = contentApi.moveNode(content, node.id, targetId, {childIndex: toIndex});

			return {
				...state,
				category: {
					...state.category,
					[sectionId]: {
						...state.category[sectionId],
						content: newContent,
						componentPaths: contentApi.getPaths(newContent)
					}
				}
			};
		}
		case SET_COMPONENT_PROPERTY: {
			return {
				...state,
				category: {
					...state.category,
					[action.payload.section]: {
						...state.category[action.payload.section],
						content: contentApi.update(action.payload.content, action.payload.activeNode.id, action.payload.key, action.payload.value),
						componentPaths: contentApi.getPaths(action.payload.content)
					}
				}
			};
		}
		case CATEGORY_FETCH_PENDING: {
			const obj = {};

			obj[action.meta.section] = {
				...state.category[action.meta.section],
				componentPaths: [],
				notFound: state.category[action.meta.section].notFound
			};

			return {
				...state,
				category: {
					...state.category,
					...obj
				},
				categoryIsPending: true
			};
		}

		case CATEGORY_FETCH_FULFILLED: {
			const obj = {};
			const content = JSON.parse(action.payload.data.content || '[]');

			obj[action.payload.section] = {
				...action.payload.data,
				content,
				componentPaths: contentApi.getPaths(content),
				emptySectionComponent: action.meta.emptySectionComponent,
				languageId: action.meta.languageId,
				notFound: false
			};

			return {
				...state,
				category: {
					...state.category,
					...obj
				},
				initialCategory: {
					...state.initialCategory,
					...obj
				},
				latestCategoryId: action.payload.articleCategory ? state.latestCategoryId : action.payload.section === 'main' ? action.payload.data.categoryId : state.latestCategoryId,
				categoryIsPending: false
			};
		}
		case CMS_DRAFT_CATEGORY_FETCH_FULFILLED: {
			return {
				...state,
				initialCategory: {
					...state.category
				}
			};
		}
		case CMS_DRAFT_CATEGORY_POST_FULFILLED: {
			if (!action.meta.section) {
				return state;
			}

			const periodData = action.meta.periodId !== -1 && action.payload.periods.find(period => period.periodId === action.meta.periodId);
			const periodContent = periodData ? JSON.parse(periodData.content || '[]') : [];
			const periodReplaceContent = periodData ? {
				...periodData,
				content: periodContent,
				componentPaths: contentApi.getPaths(periodContent)
			} : {};

			const content = action.payload.categoryDeleted || action.payload.deleted ? [] : state.category[action.meta.section].content;
			const nextCategory = {
				...state.category,
				[action.meta.section]: {
					...state.category[action.meta.section],
					...action.payload,
					content,
					componentPaths: contentApi.getPaths(content),
					...periodReplaceContent
				}
			};

			return {
				...state,
				category: nextCategory,
				initialCategory: nextCategory
			};
		}
		case CATEGORY_FETCH_REJECTED: {
			const obj = {};

			obj[action.meta.section] = {
				content: [],
				componentPaths: [],
				notFound: true
			};

			return {
				...state,
				category: {
					...state.category,
					...obj
				},
				initialCategory: {
					...state.initialCategory,
					...obj
				},
				categoryIsPending: false
			};
		}
		case PATH_CHANGE: {
			if (state.pathname === action.payload.pathname) {
				return state;
			}

			return {
				...state,
				category: {
					...state.category
				},
				initialCategory: {
					...state.initialCategory
				},
				pathname: action.payload.pathname
			};
		}
		default: {
			return state;
		}
	}
};
