import {getLocaleFriendlyPathPrefix, resolveLocalePath} from '@viskan/streamline-utilities/lib/url';
import {createAction} from 'redux-actions';
import {PATH_CHANGE} from '~/action-types';

const replaceValue = obj => Object.entries(obj).reduce((acc, [key, value]) => {
	if (value !== null && typeof value === 'object') {
		acc[key] = replaceValue(value);
		return acc;
	}

	if (typeof value === 'string' && value.includes(window.location.origin)) {
		acc[key] = value.replace(window.location.origin, '');
		return acc;
	}

	acc[key] = value;
	return acc;
}, {});

const removeOrigin = context => {
	if (context && context.pathname.includes(window.location.origin) && typeof window !== 'undefined') {
		return replaceValue(context);
	}

	return context;
};

const stripQueryAndHash = url => url.split(/[?#]/)[0];
const joinPathAndQuerystring = (path = '', querystring) => {
	const separator = path.includes('?') ? '&' : '?';

	if (!querystring) {
		return path;
	}

	return `${path}${separator}${querystring}`;
};

export const pathChange = createAction(PATH_CHANGE, ({context, site}) => {
	const newContext = removeOrigin(context);
	const canonicalPathname = stripQueryAndHash(newContext.pathname);
	const localePath = resolveLocalePath(stripQueryAndHash(newContext.path), site.localeMode);
	const localePathname = resolveLocalePath(canonicalPathname, site.localeMode);
	const pathPrefix = getLocaleFriendlyPathPrefix(site.lang_code_web, site.country_code, site.localeMode);

	if (newContext.pathname === '/' && !localePath.isValid) {
		return {
			...newContext,
			canonicalPathname,
			isSearch: false,
			pathPrefix,
			localePathPrefixInUse: false,
			path: joinPathAndQuerystring(site.localePickerPath, newContext.querystring),
			pathname: site.localePickerPath,
			searchQuery: ''
		};
	}
	const isSearch = localePathname.path && localePathname.path.startsWith(site.searchUrlPrefix);
	const usedPathname = isSearch ? site.searchUrlPrefix : localePathname.path;
	const searchQuery = isSearch ? newContext.query.q : '';

	return {
		...newContext,
		canonicalPathname,
		isSearch,
		pathPrefix,
		localePathPrefixInUse: localePath.prefix,
		path: localePath.path && joinPathAndQuerystring(localePath.path, newContext.querystring),
		pathname: usedPathname,
		searchQuery
	};
});
