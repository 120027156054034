import {createAction} from 'redux-actions';
import {ARTICLES_FAVORITES_ADD, ARTICLES_FAVORITES_REMOVE} from '../../../action-types';

export const articleIsFavorite = (favoritesArtNosAndAttr1Ids, artNo, attr1Id) => {
	return favoritesArtNosAndAttr1Ids && favoritesArtNosAndAttr1Ids.some(favorite => {
		return favorite.artNo + String(favorite.attr1Id) === artNo + String(attr1Id);
	});
};

export const add = createAction(ARTICLES_FAVORITES_ADD, async (artNo, attr1Id) => ({artNo, attr1Id}));
export const remove = createAction(ARTICLES_FAVORITES_REMOVE, async (artNo, attr1Id) => ({artNo, attr1Id}));
