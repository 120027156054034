import {connectWrap as connect} from '@viskan/deku-redux';
import BaseComponent from './components';

export default connect(
	({app, article, site, tree}) => ({
		article: article.article,
		activeCategoryId: app.category.main.categoryId,
		site,
		tree
	})
)(BaseComponent);
