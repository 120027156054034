import {RECEIPT_FETCH_FULFILLED, RECEIPT_FETCH_REJECTED, RECEIPT_RESET} from '~/action-types';
import {getErrorCode} from '~/purchase/utils';

const initialState = {
	data: undefined,
	isPolling: false
};

export default (state = initialState, action) => {
	switch (action.type) {
		case RECEIPT_FETCH_FULFILLED: {
			return {
				...state,
				data: action.payload.body,
				errorCode: false,
				isPolling: action.payload.statusCode === 204,
				serviceProviderCode: action.payload.serviceProviderCode
			};
		}
		case RECEIPT_FETCH_REJECTED: {
			const body = action.payload && action.payload.response && action.payload.response.body;
			const errorCode = getErrorCode(body);

			return {
				...initialState,
				errorCode
			};
		}
		case RECEIPT_RESET: {
			return initialState;
		}
		default: {
			return state;
		}
	}
};
