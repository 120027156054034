import {deepEqual} from 'fast-equals';
import uniqueBy from 'unique-by';
import {getPriceDiscountInPercent} from '~/utils';
import Badge from '.';

const propTypes = {
	getPrice: {
		source: 'getPrice'
	},
	getTranslation: {
		source: 'getTranslation'
	}
};

const defaultProps = {
	showCampaigns: true,
	showClubBadge: false,
	showPropertyIcon: false,
	showSaleOrNew: true,
	propertyIconName: 'article_icon'
};

const getIconFilename = campaign => {
	const firstProperty = v12.article.properties('camp.icon', campaign.propertyKeys)[0];
	const firstValue = firstProperty && firstProperty.propertyValues[0];

	return (firstValue ? firstValue.propertyvalue : 'default_campaign_icon.png').replace(/^\/media\//, '');
};

const filterCampaigns = (isPerson, skuId) => campaign => {
	if ((isPerson === true && (campaign.customerType === -1 || campaign.customerType === 1)) && (typeof skuId === 'undefined' || campaign.skuIds.includes(skuId))) {
		return true;
	}

	if ((isPerson === false && (campaign.customerType === -1 || campaign.customerType === 0)) && (typeof skuId === 'undefined' || campaign.skuIds.includes(skuId))) {
		return true;
	}

	return false;
};

const renderCampaigns = ({article, getTranslation, showCampaigns, sku = {}, campaignPopup, isPerson}) => {
	const header = showCampaigns || getTranslation('article.campaign_title');
	const campaigns = uniqueBy(article.articleCampaigns.map(campaign => ({...campaign,
		image: getIconFilename(campaign)
	})), 'image');

	return campaigns.filter(filterCampaigns(isPerson, sku.sku_id)).map(({camp_desc, camp_id, camp_name, image}) => (
		<Badge key={camp_id} class='BadgeList-item BadgeList-item--campaign' isClickable={campaignPopup} heading={header} subHeading={camp_name} description={camp_desc} image={image}/>
	));
};

const renderPropertyIcon = props => {
	const iconName = (props.propertyIconName && props.propertyIconName.length !== 0 && props.propertyIconName) || defaultProps.propertyIconName;

	if (props.article.propertyKeys.length > 0) {
		const matchingProperties = iconName.split(',').map(icon => v12.article.properties(icon.trim(), props.article.propertyKeys)).filter(arr => arr.length > 0);
		const images = matchingProperties.map(property => property[0].propertyValues.map(prop => prop.propertyvalue)[0]);

		if (images && images.length > 0) {
			return images.map((image, index) => <Badge key={`articleProperty-${image}-${index}`} class={`BadgeList-item BadgeList-item--articleProperty BadgeList-item--articleProperty-${image.toLowerCase().replace(/\s+|\.[^/.]+$/g, '')}`} image={image}/>);
		}
	}

	return undefined;
};

const getOnSalePrice = ({article, sku}) => {
	const price = v12.article.price(article, sku);

	return price && price.price_sales < price.price_org ? price : false;
};

const renderSaleOrNew = props => {
	const salePrice = getOnSalePrice(props);

	if (!salePrice && props.article.brandnew) {
		return <Badge key='brand-new-badge' class='BadgeList-item BadgeList-item--brand-new' heading='Brand new' image='brandnew.png'/>;
	}

	if (salePrice && props.onSaleImage) {
		return <Badge key='on-sale-badge' class='BadgeList-item BadgeList-item--on-sale' heading='On sale' image='onsale.png'/>;
	}

	if (salePrice) {
		const salesString = props.getPrice(salePrice.price_sales, salePrice.price_sales_exvat);

		return <Badge key='on-sale' class='BadgeList-item BadgeList-item--onSale' heading={props.getTranslation('article.sale')} subHeading={salesString}/>;
	}
};

const renderDiscount = props => {
	const price = v12.article.price(props.article, props.sku);
	const sale = price.price_sales < price.price_org;
	const discountPercent = sale && getPriceDiscountInPercent({
		sales: price.price_sales,
		salesExvat: price.price_sales_exvat,
		org: price.price_org,
		orgExvat: price.price_org_exvat
	});

	if (!discountPercent) {
		return <noscript/>;
	}

	return <Badge key='discount-percent' class='BadgeList-item BadgeList-item--discount' heading={`${discountPercent}%`} subHeading={props.getTranslation('article.discountPercent')}/>;
};

const shouldUpdate = ({props}, nextProps) => !deepEqual(props, nextProps);

const render = ({props}) => (
	<div class={['BadgeList', props.class]}>
		{props.showClubBadge && <Badge key='club-badge' class='BadgeList-item BadgeList-item--club' image='icon_club_price.png' heading='Club Price'/>}
		{props.showCampaigns && renderCampaigns(props)}
		{props.showPropertyIcon && renderPropertyIcon(props)}
		{props.showSaleOrNew && renderSaleOrNew(props)}
		{props.showDiscountPercent && renderDiscount(props)}
	</div>
);

export default {defaultProps, propTypes, render, shouldUpdate};
