export default {
	type: 'ARTICLE_STORE_BALANCE',
	name: 'Store Balance',
	allowedSections: ['article'],
	disallowedChildren: ['*'],
	category: 'Article',
	icon: 'fal fa-warehouse-alt',
	props: [{
		name: 'pinIcon',
		inputType: 'icon',
		label: 'Icon for map pin',
		default: false
	}, {
		name: 'pinIconSize',
		inputType: 'text',
		label: 'Set size on icon',
		default: '1em'
	}, {
		name: 'pinIconColor',
		inputType: 'color',
		label: 'Set color on icon',
		default: '#000'
	}, {
		name: 'inStockIcon',
		inputType: 'icon',
		label: 'Icon products instock',
		default: false
	}, {
		name: 'inStockIconSize',
		inputType: 'text',
		label: 'Set size on icon',
		default: '1em'
	}, {
		name: 'inStockIconColor',
		inputType: 'color',
		label: 'Set color on icon',
		default: '#000'
	}, {
		name: 'outOfStockIcon',
		inputType: 'icon',
		label: 'Icon products out of stock',
		default: false
	}, {
		name: 'outOfStockIconSize',
		inputType: 'text',
		label: 'Set size on icon',
		default: '1em'
	}, {
		name: 'outOfStockIconColor',
		inputType: 'color',
		label: 'Set color on icon',
		default: '#000'
	}, {
		name: 'infoText',
		inputType: 'text',
		label: 'Info text',
		default: ''
	}],
	hiddenProps: [{
		label: 'Search settings',
		props: [{
			name: 'searchPlaceholder',
			inputType: 'text',
			label: 'Placeholder',
			default: 'Search for a store'
		}, {
			name: 'searchButtonText',
			inputType: 'text',
			label: 'Button text',
			default: 'Search'
		}]
	}, {
		label: 'Advanced settings',
		props: [{
			name: 'class',
			inputType: 'text',
			label: 'CSS-class',
			default: ''
		}]
	}]
};
