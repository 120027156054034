import {connect} from '@viskan/deku-redux';
import {ensureCmsIsLoaded} from './actions';
import StreamlineCMS from './components';
import SectionComponent from './components/section';

export default connect(
	({site, streamlineCms}) => ({
		cmsTokenSet: site.cmsTokenSet,
		loaded: streamlineCms && streamlineCms.loaded
	}), {
		ensureCmsIsLoaded
	}
)(StreamlineCMS);

export const Section = connect(
	({app}, {sectionId}) => ({
		content: app.category[sectionId].content,
		emptySectionComponent: app.category[sectionId].emptySectionComponent
	})
)(SectionComponent);
