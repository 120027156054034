import bus from '@viskan/bus';
import concatObject from 'concat-object';
import {createAction} from 'redux-actions';
import {openPopup} from '../actions';
import {SHOPCART_ADD, SHOPCART_EDIT, SHOPCART_FETCH, SHOPCART_REMOVE, SHOPCART_UPDATE, SHOPCART_SET} from '../action-types';
import {trackAddToCart, trackRemoveFromCart} from '../analytics';

const getErrorMessage = errorCode => {
	switch (errorCode) {
		case 'shopcart.add.variant_not_in_stock': {
			return 'shopcart.error.not_in_stock';
		}
		case 'shopcart.add-voucher.voucher-already-added': {
			return 'shopcart.error.voucher_already_added';
		}
		default: {
			return 'shopcart.error.default';
		}
	}
};

const addToCart = (data = {}, getTranslation) => v12.shopcart.add(data)
		.then(data => {
			bus.emit('shopcart:update', data);
			bus.emit('loading:close');

			return {
				data
			};
		})
		.catch(error => {
			const message = getTranslation ? getTranslation(getErrorMessage(error.message)) : 'Something went wrong, please try again or contact customer service';
			openPopup(message);
			bus.emit('loading:close');
			throw error;
		});

export const addItem = createAction(SHOPCART_ADD, (data, sku, addons = [], qty = 1, getTranslation) => {
	const {art_id} = data;
	const {attr1_id, attr2_id, attr3_id} = sku;

	bus.emit('loading:open');

	const body = {
		art_id,
		attr1_id: attr1_id || 0,
		attr2_id: attr2_id || 0,
		attr3_id: attr3_id || 0,
		personalization_text: '',
		qty
	};

	trackAddToCart({
		body: data,
		qty: body.qty,
		sku
	});

	return addToCart(concatObject(body, ...addons), getTranslation);
}, data => ({data}));

export const addSubscription = createAction(SHOPCART_ADD, (subscription, getTranslation) => {
	bus.emit('loading:open');

	trackAddToCart({
		body: {
			art_no: subscription.prentype_id,
			artname: subscription.prentype_desc
		},
		qty: 1,
		sku: {
			articlePrice: {
				price_sales: subscription.prognoses[0].price
			}
		}
	});

	return addToCart({prentype_id: subscription.prentype_id}, getTranslation);
}, subscription => ({subscription}));

export const addAddons = createAction(SHOPCART_ADD, (addons = [], subscription = {}, getTranslation) => {
	bus.emit('loading:open');

	if (Object.keys(addons).length === 0) {
		return addToCart({prentype_id: subscription.prentype_id}, getTranslation);
	}

	return addToCart(concatObject({prentype_id: subscription.prentype_id}, ...addons), getTranslation);
}, subscription => ({subscription}));

export const edit = createAction(SHOPCART_EDIT, (id, qty, dontOpen, getTranslation) => {
	bus.emit('loading:open');

	return v12.shopcart.edit({
		qty,
		row_id: id
	})
		.then(data => {
			bus.emit('shopcart:update', data, dontOpen);
			bus.emit('loading:close');

			return {
				data,
				dontOpen
			};
		})
		.catch(error => {
			const message = getTranslation ? getTranslation(getErrorMessage(error.message)) : 'Something went wrong, please try again or contact customer service';
			openPopup(message);
			bus.emit('loading:close');
			throw error;
		});
});

export const fetch = createAction(SHOPCART_FETCH, dontOpen => v12.shopcart.get().then(data => {
	bus.emit('shopcart:update', data, dontOpen);

	return {
		data,
		dontOpen
	};
}).catch(error => {
	openPopup(error.message);
	throw error;
}));

export const remove = createAction(SHOPCART_REMOVE, () => v12.util.http.get('/api/shopcart/clear', {json: false}));

export const removeItem = createAction(SHOPCART_REMOVE, (data, qty, dontOpen, getTranslation) => {
	const {art_no, artname, attr1_id, attr1_code, attr2_id, attr2_code, price_incvat, price_exvat, row_id} = data;

	bus.emit('loading:open');

	trackRemoveFromCart({
		sku: {articlePrice: {price_sales: price_incvat, price_sales_exvat: price_exvat}},
		body: {
			artname,
			art_no,
			attr1_id,
			attr1_code,
			attr2_id,
			attr2_code
		},
		qty
	});

	/* When removing an addonrow, parameter `addon_row_id` must also be provided.
	*  So having this row structure:
	*  "rows": [
	*    {
	*      "row_id": 1,
	*      ......
	*      "addons": [
	*        {
	*          "row_id": 1,
	*          ....
	*        },
	*        {
	*          "row_id": 2,
	*          ......
	*        }
	*      ]
	*    }
	*  ]
	*
	*  ... and removal of the second addon is wanted then
	*  parameters should be:
	*    row_id: 1,
	*    addon_row_id: 2
	*/
	return v12.shopcart.remove({row_id})
		.then(data => {
			bus.emit('shopcart:update', data, dontOpen);
			bus.emit('loading:close');

			return {
				data,
				dontOpen
			};
		})
		.catch(error => {
			const message = getTranslation ? getTranslation(getErrorMessage(error.message)) : 'Something went wrong, please try again or contact customer service';
			openPopup(message);
			v12.util.error(error);
			bus.emit('loading:close');
			throw error;
		});
});

export const set = createAction(SHOPCART_SET, (data, dontOpen) => {
	bus.emit('shopcart:update', data, dontOpen);

	return {
		data,
		dontOpen
	};
});

export const update = createAction(SHOPCART_UPDATE, (data, dontOpen) => v12.shopcart.update(data)
	.then(() => fetch(dontOpen))
	.then(({payload}) => payload)
);
