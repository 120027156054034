export default {
	type: 'ARTICLE_PROPERTIES',
	name: 'Properties',
	allowedSections: ['article'],
	disallowedChildren: ['*'],
	category: 'Article',
	props: [{
		name: 'keyGroup',
		inputType: 'text',
		label: 'Keygroup to show multiple values,',
		description: 'Leave empty to show all',
		default: ''
	}],
	hiddenProps: [{
		label: 'Advanced settings',
		props: [{
			name: 'class',
			inputType: 'text',
			label: 'CSS-class',
			default: ''
		}]
	}]
};
