import {CHECKOUT_FETCH_REJECTED, POPUP_CLOSE, POPUP_OPEN} from '../action-types';

const initialState = {
	content: null,
	open: false,
	options: {
		auto: true,
		margin: 20
	}
};

export default (state = initialState, action) => {
	switch (action.type) {
		case POPUP_CLOSE: {
			return initialState;
		}
		case POPUP_OPEN: {
			return {
				...state,
				content: action.payload.content,
				open: true,
				options: action.payload.options
			};
		}
		case CHECKOUT_FETCH_REJECTED: {
			return {
				...initialState,
				content: action.payload.message,
				open: true
			};
		}
		default: {
			return state;
		}
	}
};
