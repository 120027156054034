import {CMS_IS_LOADED_FULFILLED} from '../action-types';
import editableSettings from './editable-settings';
import editableTranslations from './editable-translations';

const initialState = {
	editableSettings,
	editableTranslations,
	loaded: false
};

export default (state = initialState, action) => {
	switch (action.type) {
		case CMS_IS_LOADED_FULFILLED: {
			return {
				...state,
				loaded: true
			};
		}
		default: {
			return state;
		}
	}
};
