import editorLayout from './editor-layout';

// Customer specific file to get rowsum_price_org, hideWhenNoLowerPrice and hideWhenLowerPrice. Remove this file when bumping.

export default node => ({
	type: 'SHOPCART_ROWS_FIELD',
	name: 'Rows field',
	allowedSections: ['main', 'header', 'footer'],
	disallowedChildren: ['*'],
	category: 'Shopcart',
	editorLayout,
	styleClasses: {
		Field: '',
		Prefix: '',
		Value: '',
		Suffix: '',
		Link: '',
		Addons: '',
		Addon: '',
		Addon_Head: '',
		Addon_ArticleName: '',
		Addon_Price: '',
		Addon_Body: '',
		Addon_Attribute1: '',
		Addon_Attribute2: '',
		Addon_Attribute3: '',
		Addon_personalizationText: '',
		Addon_Missing_Balance_Skus_Text: ''
	},
	props: [{
		name: 'field',
		inputType: 'select',
		label: 'Select a field to show',
		description: node && node.props.field === 'article_type' ? 'Translation for article_type are found in section shopcart of translations' : '',
		default: 'artname',
		values: [{
			label: 'Addons',
			value: 'addons'
		}, {
			label: 'Article Image',
			value: 'media_filename'
		}, {
			label: 'Article Name',
			value: 'artname'
		}, {
			label: 'Article Number',
			value: 'art_no'
		}, {
			label: 'Article Price',
			value: 'price_incvat'
		}, {
			label: 'Article type (subscription or single purchase)',
			value: 'article_type'
		}, {
			label: 'Attribute 1',
			value: 'attr1_code'
		}, {
			label: 'Attribute 1 Description',
			value: 'attr1_desc'
		}, {
			label: 'Attribute 2',
			value: 'attr2_code'
		}, {
			label: 'Attribute 2 Description',
			value: 'attr2_desc'
		}, {
			label: 'Attribute 3',
			value: 'attr3_code'
		}, {
			label: 'Attribute 3 Description',
			value: 'attr3_desc'
		}, {
			label: 'Discount',
			value: 'discount'
		}, {
			label: 'Extra',
			value: 'extra_2'
		}, {
			label: 'Missing Balance',
			value: 'missing_balance'
		}, {
			label: 'Offer Description',
			value: 'offer_desc'
		}, {
			label: 'Personalization Text',
			value: 'personalization_text'
		}, {
			label: 'Pluno',
			value: 'pluno'
		}, {
			label: 'Quantity',
			value: 'qty'
		}, {
			label: 'Row Summary Price',
			value: 'rowsum_to_pay'
		}, {
			label: 'Row Summary Price (without discount)',
			value: 'rowsum_to_pay_no_discount'
		}, {
			label: 'Row Summary Original Price',
			value: 'rowsum_price_org'
		}]
	}, node && node.props.field === 'media_filename' && {
		name: 'scale',
		inputType: 'select',
		label: 'Article image scale',
		description: 'Select the smallest scale possible to increase page speed',
		default: 'thumbnail',
		values: [{
			label: 'Original',
			value: 'original'
		}, {
			label: 'Large',
			value: 'large'
		}, {
			label: 'Normal',
			value: 'normal'
		}, {
			label: 'List',
			value: 'list'
		}, {
			label: 'Thumbnail',
			value: 'thumbnail'
		}]
	}, node && node.props.field !== 'media_filename' && node.props.field !== 'addons' && {
		name: 'elementType',
		inputType: 'select',
		label: 'Select element type',
		default: 'div',
		values: [{
			label: 'Div',
			value: 'div'
		}, {
			label: 'Paragraph',
			value: 'p'
		}, {
			label: 'Span',
			value: 'span'
		}]
	}, node && node.props.field !== 'media_filename' && node.props.field !== 'addons' && {
		name: 'prefixText',
		inputType: 'text',
		label: 'Text before',
		default: ''
	}, node && node.props.field !== 'media_filename' && node.props.field !== 'addons' && {
		name: 'suffixText',
		inputType: 'text',
		label: 'Text after',
		default: ''
	}, node && node.props.field !== 'addons' && {
		name: 'linkWrap',
		inputType: 'checkbox',
		label: 'Wrap element in a link to the product',
		default: false
	}, node && (node.props.field === 'attr1_code' || node.props.field === 'attr2_code' || node.props.field === 'attr3_code') && {
		name: 'hideAttrValueZero',
		inputType: 'checkbox',
		label: 'Hide if attribute value is 0',
		description: 'To hide both label and value, set option for show field when it has a value to true',
		default: false
	}, node && node.props.field !== 'media_filename' && node.props.field !== 'addons' && {
		name: 'hideWhenNoValue',
		inputType: 'checkbox',
		label: 'Only show field when it has a value',
		default: false
	}, node && node.props.field !== 'media_filename' && node.props.field !== 'addons' && {
		name: 'hideWhenNoDiscount',
		inputType: 'checkbox',
		label: 'Only show field when row has discount',
		default: false
	}, node && node.props.field !== 'media_filename' && node.props.field !== 'addons' && {
		name: 'hideWhenNoLowerPrice',
		inputType: 'checkbox',
		label: 'Only show field when row has a lower price',
		default: false
	}, node && node.props.field !== 'media_filename' && node.props.field !== 'addons' && {
		name: 'hideWhenLowerPrice',
		inputType: 'checkbox',
		label: 'Only show field when row does not have a lower price',
		default: false
	}, node && node.props.field === 'missing_balance' && {
		name: 'missingBalanceSkusText',
		inputType: 'text',
		label: 'Text warn about product missing balance',
		default: 'Out of stock'
	}, node && node.props.field === 'addons' && {
		name: 'showAddonPrice',
		inputType: 'checkbox',
		label: 'Show price',
		default: true
	}, node && node.props.field === 'addons' && {
		name: 'showAddonAttr1',
		inputType: 'checkbox',
		label: 'Show attribute 1',
		default: true
	}, node && node.props.field === 'addons' && {
		name: 'showAddonAttr2',
		inputType: 'checkbox',
		label: 'Show attribute 2',
		default: true
	}, node && node.props.field === 'addons' && {
		name: 'showAddonAttr3',
		inputType: 'checkbox',
		label: 'Show attribute 3',
		default: false
	}, node && node.props.field === 'addons' && {
		name: 'showAddonPersonalizationText',
		inputType: 'checkbox',
		label: 'Show personalization text',
		default: false
	}, node && node.props.field === 'addons' && {
		name: 'hideAddonPriceWhenZero',
		inputType: 'checkbox',
		label: 'Only show price above zero',
		default: true
	}, node && node.props.field === 'addons' && {
		name: 'attribute1Prefix',
		inputType: 'text',
		label: 'Attribute 1 prefix',
		default: 'Color'
	}, node && node.props.field === 'addons' && {
		name: 'attribute2Prefix',
		inputType: 'text',
		label: 'Attribute 2 prefix',
		default: 'Size'
	}, node && node.props.field === 'addons' && {
		name: 'attribute3Prefix',
		inputType: 'text',
		label: 'Attribute 3 prefix',
		default: 'Other'
	}, node && node.props.field === 'addons' && {
		name: 'personalizationTextPrefix',
		inputType: 'text',
		label: 'Personalization text prefix',
		default: 'Your text'
	}, node && node.props.field === 'addons' && {
		name: 'showMissingBalanceSkusAddons',
		inputType: 'checkbox',
		label: 'Show missing sku text',
		default: false
	}, node && node.props.field === 'addons' && {
		name: 'missingBalanceSkusAddonsText',
		inputType: 'text',
		label: 'Missing sku text',
		default: 'Out of stock'
	}, {
		name: 'class',
		inputType: 'text',
		label: 'CSS-class',
		default: ''
	}].filter(Boolean)
});
