import {Image} from '~/media';

const propTypes = {
	getTranslation: {
		source: 'getTranslation'
	},
	openPopup: {
		source: 'openPopup'
	}
};

const renderDetails = (props, isPopup) => {
	const HeadingElement = isPopup ? 'h1' : 'div';
	const SubHeadingElement = isPopup ? 'h1' : 'div';

	return (
		<div class='Badge-details'>
			{props.heading && <HeadingElement class='Badge-heading'>{props.heading}</HeadingElement>}
			{props.subHeading && <SubHeadingElement class='Badge-subHeading'>{props.subHeading}</SubHeadingElement>}
			{props.description && <div class='Badge-description'>{props.description}</div>}
		</div>
	);
};

const render = ({props}) => {
	const onClick = props.isClickable && (() => props.openPopup(<div class='Badge-popup' height='500px' width='360px'>{renderDetails(props, true)}</div>));
	const summary = props.description || props.subHeading || props.heading;
	const classes = [
		'Badge',
		props.class,
		props.isClickable && 'Badge--clickable',
		props.image && 'Badge--image'
	];

	return (
		<div class={classes} onClick={onClick}>
			{!props.image && (props.children.length > 0 ? props.children : renderDetails(props))}
			{props.image && <Image class='Badge-image' alt={summary} title={summary} source={props.image}/>}
		</div>
	);
};

export default {propTypes, render};
